import React from 'react';
import _ from 'lodash';
import {
  Checkbox as MatCheckbox,
  FormControlLabel,
  Rating,
} from '@mui/material';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import createFilter from '../../functions/createFilter';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import {
  setSelectedFilters,
  setActivePage,
} from '../../models/otmmModel/functions/updateState';
import {
  CRWS_CONTENT_MATURITY,
  USER_RATING,
} from '../../constants/assetFields';
import getContentMaturityBadge from '../../functions/getContentMaturityBadge';

const getCurrentValues = (filterId, selectedFilters) =>
  _.chain(selectedFilters)
    .find({ filter_id: filterId })
    .defaultTo(createFilter(filterId))
    .value().value;

const isChecked = (filterId, value, selectedFilters) => {
  const currentValues = getCurrentValues(filterId, selectedFilters);
  if (currentValues.length > 0) {
    const labels = currentValues.map((v) => v.text);
    return labels.includes(value);
  }
  return false;
};

const CheckboxFilter = ({
  filterValue,
  selectedFilters,
  setSelectedFilters,
  filter,
  setActivePage,
  setPendingUpdate,
  pendingUpdate,
}) => {
  const onChange = (changedFilterId) => (e, data) => {
    const currentFilters = [...selectedFilters];
    const currentFilter = _.chain(currentFilters)
      .remove(({ filter_id: id }) => id === changedFilterId)
      .first()
      .defaultTo(createFilter(changedFilterId, filter.type, [], 'label'))
      .value();

    if (e.target.checked === true || data.checked) {
      currentFilter.value.push(filterValue.value);
    } else {
      _.remove(
        currentFilter.value,
        (value) => value.text === e.target.name || data.value
      );
    }

    if (currentFilter.value.length) {
      currentFilters.push(currentFilter);
    }
    setSelectedFilters(currentFilters);
    setActivePage(1);
    setPendingUpdate(true);
  };

  // configureLabel may return label values containing icons (based on the filter.id being passed in)
  const configureLabel = (id, filterValue) => {
    if (id === USER_RATING) {
      return (
        <div style={{ display: 'flex' }}>
          <Rating
            name="read-only"
            readOnly
            value={parseFloat(filterValue.value.text)}
          />
          ({filterValue.count})
        </div>
      );
    }

    if (id === CRWS_CONTENT_MATURITY) {
      return getContentMaturityBadge(filterValue.value.text, filterValue.count);
    }

    return `${filterValue.value.text} (${filterValue.count})`;
  };

  return (
    <>
      <FormControlLabel
        control={
          <MatCheckbox
            checked={isChecked(
              filter.id,
              filterValue.value.text,
              selectedFilters
            )}
            onChange={onChange(filter.id)}
            name={filterValue.value.text}
            disabled={pendingUpdate}
            color="secondary"
          />
        }
        label={configureLabel(filter.id, filterValue)}
      />
    </>
  );
};

CheckboxFilter.propTypes = {
  filter: PropTypes.any.isRequired,
  filterValue: PropTypes.any.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  isSearching: state.otmmState.loading,
  filters: state.otmmState.filters.allFilters,
  selectedFilters: state.otmmState.filters.selected,
  pendingUpdate: state.otmmState.pendingUpdate,
});

export default connect(mapStateToProps, {
  setSelectedFilters,
  setPendingUpdate,
  setActivePage,
})(CheckboxFilter);
