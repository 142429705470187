import { Typography } from '@mui/material';
import React from 'react';

const PrivacyAgreement = () => (
  <>
    <Typography paragraph>
      We respect your privacy. If you create a user account on this website, we
      collect personal information that is voluntarily provided to improve the
      user experience and offer full capabilities of the site. <br />
    </Typography>
    <Typography paragraph>This information may include:</Typography>
    <ul>
      <li>
        <Typography>Name</Typography>
      </li>
      <li>
        <Typography>Email address</Typography>
      </li>
      <li>
        <Typography>
          Details about your saved searches, favorites, and requested updates.
        </Typography>
      </li>
    </ul>
    <Typography paragraph>
      We do not share this information with anyone else. We may also email you
      with messages about your account activity to confirm certain site
      activities, such as:
    </Typography>
    <ul>
      <li>
        <Typography>You’ve created a User Account</Typography>
      </li>
      <li>
        <Typography>
          You’ve saved a search or requested an update alert
        </Typography>
      </li>
      <li>
        <Typography>
          If you have signed up for saved searches or resource updates.
        </Typography>
      </li>
    </ul>
    <Typography paragraph>
      It is not possible to unsubscribe from these system confirmation messages,
      with the exception of the saved searches/resource updates that you have
      requested and subscribed to. The option to change or cancel those
      subscriptions will be included on the notification emails for saved
      searches or resource updates.
    </Typography>
  </>
);

export default PrivacyAgreement;
