import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';

const createRendition = async ({ jobId, file }) => {
  const data = new FormData();
  data.append('import_job_id', jobId);
  data.append('file', file);
  data.append('file_name', file.name);

  const response = await otmm.post('/renditions', data);
  return response;
};

export default createRendition;
