import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const PageHeader = ({ centered, title, action }) => {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'Quantico',
            fontSize: '2.5rem',
            fontWeight: 700,
            textAlign: centered ? 'center' : 'left',
          }}
        >
          {title}
        </div>
        {action && <div>{action}</div>}
      </div>
      <div
        style={{
          height: '50px',
          width: '100%',

          background: theme.palette.primary.main,
          margin: '15px 0',
        }}
      />
    </>
  );
};

PageHeader.propTypes = {
  centered: PropTypes.bool,
  title: PropTypes.string.isRequired,
  action: PropTypes.any,
};
PageHeader.defaultProps = {
  action: undefined,
  centered: false,
};

export default PageHeader;
