import numeral from 'numeral';

const CURRENCY_FORMAT = '$0,0[.]00 a';

const formatCurrency = (value = null) => {
  numeral.nullFormat('');
  return numeral(value)
    .format(CURRENCY_FORMAT)
    .toUpperCase();
};

export default formatCurrency;
