import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
  IconButton,
  MenuItem,
  Link as MatLink,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import Pop from '../pop/Pop';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';

function CustomDialog({
  title,
  children,
  button,
  label,
  confirmation,
  handler,
  img,
  iconButton,
  iconSvg,
  handlers,
  maxWidth,
  icon,
  menuItem,
  link,
  tooltip,
  ...otherProps
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const Element = () => {
    if (button)
      return (
        <Button
          variant="outlined"
          {...otherProps}
          // color="primary"
          onClick={handleClickOpen}
        >
          {label}
        </Button>
      );

    if (menuItem) return <MenuItem onClick={handleClickOpen}>{label}</MenuItem>;

    if (img)
      return (
        <input
          type="image"
          src={label}
          alt="external link"
          onClick={handleClickOpen}
          onKeyDown={(e) => {
            // fix-later
            if (e.key === 13) handleClickOpen();
          }}
          tabIndex={0}
        />
      );
    if (iconButton)
      return (
        <Pop content={label}>
          <IconButton
            color="inherit"
            onClick={handleClickOpen}
            {...otherProps}
            size="large"
          >
            {iconSvg}
          </IconButton>
        </Pop>
      );

    if (link)
      return tooltip ? (
        <Pop content={tooltip}>
          <MatLink
            style={{ cursor: 'pointer' }}
            onClick={handleClickOpen}
            {...otherProps}
          >
            {label}
          </MatLink>
        </Pop>
      ) : (
        <MatLink
          style={{ cursor: 'pointer' }}
          onClick={handleClickOpen}
          {...otherProps}
        >
          {label}
        </MatLink>
      );

    return (
      <Typography
        tabIndex={0}
        // color="inherit"
        display="inline"
        style={theme.typography.link}
        onClick={handleClickOpen}
        onKeyDown={(e) => {
          if (e.key === 13) handleClickOpen();
        }}
      >
        {label}
      </Typography>
    );
  };

  return (
    <>
      <Element />
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        onClose={handleClose}
        aria-labelledby="dialog"
        disableEnforceFocus
        open={open}
      >
        {title && (
          <DialogTitleWithClose onClose={handleClose}>
            {title}
          </DialogTitleWithClose>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {confirmation ? (
            <>
              <Button
                onClick={() => {
                  setOpen(false);
                  if (handlers)
                    handlers.forEach((func) => {
                      func();
                    });

                  if (handler) handler();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleClose}>No</Button>
            </>
          ) : (
            <Button
              onClick={() => setOpen(false)}
              sx={{ color: 'text.secondary' }}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

CustomDialog.defaultProps = {
  button: false,
  title: undefined,
  confirmation: false,
  handler: CustomDialog.handleClose,
  img: false,
  iconButton: false,
  iconSvg: null,
  label: '',
  handlers: undefined,
  maxWidth: 'sm',
  icon: <Info />,
  menuItem: false,
  link: false,
  tooltip: undefined,
};

CustomDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  label: PropTypes.any,
  button: PropTypes.bool,
  confirmation: PropTypes.bool,
  handler: PropTypes.func,
  img: PropTypes.bool,
  iconButton: PropTypes.bool,
  iconSvg: PropTypes.object,
  handlers: PropTypes.array,
  maxWidth: PropTypes.string,
  icon: PropTypes.any,
  menuItem: PropTypes.bool,
  link: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default CustomDialog;
