import {
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { DefaultLogo } from '../../../../constants/images';
import { SITE_NAME, SITE_NAME_ABBR } from '../../constants/constants';
import Pop from '../pop/Pop';
import HeaderTabs from './HeaderTabs';
import SearchBar from '../../../search/components/SearchBar';

const useStyles = makeStyles((theme) => ({
  app: {
    backgroundColor:
      theme.type === 'dark'
        ? theme.palette.secondary.light
        : theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  toolbar: {
    justifyContent: 'space-between',
    padding: '12px',
  },

  bar: {
    backgroundColor: theme.palette.primary.main,
    height: '10px',
    width: '100%',
  },

  logo: {
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  sectionDesktop: {
    display: 'none',
    gap: '24px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({
    title: '',
    open: false,
  });
  return (
    <>
      <AppBar position="static" className={classes.app}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <div className={classes.logo}>
            <Pop type="tooltip" content="Find out about DE BoK">
              <Button
                onClick={() =>
                  setDialog({ title: 'What is DE BoK?', open: true })
                }
                onKeyPress={(event) => {
                  if (event.key === 'Enter')
                    setDialog({ title: 'What is DE BoK?', open: true });
                }}
                role="link"
                tabIndex={0}
              >
                <img
                  src={DefaultLogo}
                  alt="Default logo"
                  style={{ height: '60px' }}
                />
              </Button>
            </Pop>
          </div>

          <div className={classes.sectionDesktop}>
            <SearchBar />
            <HeaderTabs className={classes.tabsText} />
          </div>
          <div
            className={classes.sectionMobile}
            style={{ justifyContent: 'flex-end', width: '100%' }}
          >
            <HeaderTabs />
          </div>
        </Toolbar>
        <div className={classes.bar} />
      </AppBar>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ ...dialog, open: false })}
        fullWidth
      >
        <DialogTitle sx={{ color: 'primary.light' }}>
          {dialog.title}
        </DialogTitle>
        <DialogContent sx={{ mt: '24px' }}>
          <Typography h2>Vision</Typography>
          <Typography paragraph>
            The {SITE_NAME} ({SITE_NAME_ABBR}) is intended to provide
            referential resources for the DoD engineering community for
            implementing Digital Engineering (DE) starting with Systems
            Engineering and expanding expanding to specific discipline
            engineering domains and specific discipline engineering domains and
            specialty areas. Data, Information, Knowledge, and Wisdom on Digital
            Engineering is stored and created in a controlled manner. Everyone
            working in this space contributes to the community`&apos;`s
            collective experiences and is able to quickly build the digital
            engineering solutions based on a common knowledge base in{' '}
            {SITE_NAME_ABBR}.
          </Typography>
          <Typography h2>Purpose</Typography>
          <Typography paragraph>
            The purpose of {SITE_NAME_ABBR} is to capture knowledge through
            documentation to assist with the transition from traditional
            acquisition processes to a digital environment. The {SITE_NAME_ABBR}{' '}
            will allow professional to engage with one another starting with DoD
            Services and Agencies to include acquisition professionals,
            analysts, modelers, engineers, and Warfighters. The {SITE_NAME_ABBR}{' '}
            will allow these professionals to bring together all the captured
            knowledge to support Digital Engineering Transformation.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
