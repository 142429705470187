import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Alert } from '@mui/material';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import { EmailField } from '../CustomFields/CustomFields';
import { otmm, SES_MAILER_BASE_URL } from '../../../otmm/apis/otmm';

function ContactForm({ subject: pSub, body: pBody, ...otherProps }) {
  const [subject, setSubject] = useState(pSub);
  const [body, setBody] = useState(pBody);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState({ value: '', valid: false });
  const [message, setMessage] = useState({
    severity: 'success',
    message: 'message',
    show: false,
  });
  const { getUserInfo, isUserGuest } = useUserInfo();

  const toAddresses = process.env.REACT_APP_CURATOR_LIST.split(',');
  const fromAddress = process.env.REACT_APP_FROM_EMAIL;
  const bodyMinCharacters = 10;
  const template = 'Generic';
  const handleSend = async (e) => {
    e.preventDefault();

    // Append email to body if it exists
    const prependEmail =
      email !== ''
        ? `A guest user has requested a response for this email. Please send response to ${email.value}. `
        : '';

    const values = {
      username: getUserInfo().login_name,
      body: isUserGuest() ? `${prependEmail} >>> ${body}` : body,
    };
    const formData = {
      fromAddress,
      type: 'ses_mailer',
      templateLocation: template,
      templateValue: template,
      toAddresses,
      subject,
      templateValuesJson: JSON.stringify(values),
    };
    otmm
      .post(`${SES_MAILER_BASE_URL}/sendEmail`, JSON.stringify(formData))
      .then(() => {
        setSubject('');
        setBody('');
        setMessage({
          show: true,
          severity: 'success',
          message: 'Your email has been sent!',
        });
      })
      .catch(() => {
        setMessage({
          show: true,
          severity: 'error',
          message:
            'An error has occurred while trying to send this message. Please try again later.',
        });
      });
  };
  return (
    <>
      {isUserGuest() && (
        <>
          <Alert severity="info">
            You are logged in as a Guest. If you wish to be contacted, please
            provide an optional email address below.
          </Alert>
          <EmailField state={email} setState={setEmail} />
        </>
      )}
      {message.show && (
        <Alert
          onClose={() => setMessage({ ...message, show: false })}
          severity={message.severity}
        >
          {message.message}
        </Alert>
      )}
      <TextField
        margin="normal"
        id="Subject"
        label="Email Subject"
        aria-label="Subject of Email"
        placeholder="Enter a description of the email..."
        type="text"
        value={subject}
        {...otherProps}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        margin="normal"
        id="Body"
        label="Email Body"
        aria-label="Body of Email"
        placeholder="Tell us what is going on..."
        type="text"
        error={error}
        value={body}
        helperText={
          error ? `Body must be at least ${bodyMinCharacters} characters.` : ''
        }
        multiline
        rows={10}
        {...otherProps}
        onChange={(e) => {
          if (error) setError(e.target.value.length < bodyMinCharacters);
          setBody(e.target.value);
        }}
        onBlur={(e) => {
          if (e.target.value !== '')
            setError(e.target.value.length < bodyMinCharacters);
        }}
      />
      <Button
        variant="contained"
        fullWidth
        color="primary"
        disabled={subject.length < 1 || body.length < bodyMinCharacters}
        onClick={handleSend}
      >
        Send
      </Button>
    </>
  );
}

ContactForm.propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string,
};
ContactForm.defaultProps = {
  subject: '',
  body: '',
};

export default ContactForm;
