import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import UpdateIcon from '@mui/icons-material/Update';
import { Typography } from '@mui/material';
import moment from 'moment';
import classNames from 'classnames';
import Pop from '../pop/Pop';
import './AssetStatus.css';

const AssetStatus = ({ dateImported, dateLastUpdated, cardDetails }) => {
  const { color } = cardDetails;
  const defaultColor = typeof color === 'object' ? color[500] : color;

  const { text, tooltip, icon } = useMemo(() => {
    const result = {};
    const imported = moment.utc(dateImported);
    const lastUpdated = moment.utc(dateLastUpdated);
    const hasRecentActivity =
      moment.duration(moment().diff(lastUpdated)).asHours() < 24;
    const isNew =
      hasRecentActivity &&
      moment.duration(lastUpdated.diff(imported)).asSeconds() < 30;
    if (hasRecentActivity) {
      if (isNew) {
        result.icon = <NewReleasesIcon style={{ paddingLeft: '5px' }} />;
        result.text = <Typography>New</Typography>;
        result.tooltip = `Created ${imported.fromNow()}`;
      } else {
        result.icon = <UpdateIcon style={{ paddingLeft: '5px' }} />;
        result.text = <Typography>Updated</Typography>;
        result.tooltip = `Updated ${lastUpdated.fromNow()}`;
      }
    }

    return result;
  }, [dateImported, dateLastUpdated]);

  return (
    <>
      {text && (
        <Pop content={tooltip}>
          <div
            className={classNames('asset-status')}
            style={{
              color: 'white',
              backgroundColor: defaultColor,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {text}
            {icon}
          </div>
        </Pop>
      )}
    </>
  );
};

AssetStatus.propTypes = {
  dateImported: PropTypes.string.isRequired,
  dateLastUpdated: PropTypes.string.isRequired,
  cardDetails: PropTypes.object,
};

AssetStatus.defaultProps = {
  cardDetails: { color: '#D05B3F' },
};
export default AssetStatus;
