import { Divider, Typography } from '@mui/material';
import React from 'react';
import { SITE_NAME, SITE_NAME_ABBR } from '../../constants/constants';

function PrivacyPolicy() {
  return (
    <>
      <>
        <Typography paragraph>
          This World Wide Web (WWW) site is provided as a public service by the{' '}
          {SITE_NAME} ({SITE_NAME_ABBR}) and its government sponsor.
        </Typography>
        <Typography paragraph>
          Information presented on this WWW site is considered public
          information and may be distributed or copied, per the terms of use.
          Use of appropriate byline/photo/image credits is requested.
        </Typography>
        <Typography paragraph>
          For site management, information is collected for statistical
          purposes. This site uses software programs to create summary
          statistics, which are used for such purposes as assessing what
          information is of most and least interest, determining technical
          design specifications, and identifying system performance or problem
          areas.
        </Typography>

        <Typography paragraph>
          For site security purposes and to ensure that this service remains
          available to all users, this site employs software programs to monitor
          network traffic to identify unauthorized attempts to upload or change
          information, or otherwise cause damage.
        </Typography>
        <Typography paragraph>
          Except for authorized law enforcement investigations, no other
          attempts are made to identify individual users or their usage habits.
          Raw data logs are used for no other purposes and are scheduled for
          regular destruction in accordance with the National Archives and
          Records Administration’s <b>General Records Schedule 20</b>{' '}
          <i>(Electronic Records)</i>.
        </Typography>

        <Typography paragraph>
          Unauthorized attempts to upload information or change information on
          this service are strictly prohibited and may be punishable under the
          Computer Fraud and Abuse Act of 1986 and the National Information
          Infrastructure Protection Act.
        </Typography>

        <Typography variant="h6">
          <b>DISCLAIMER</b>
        </Typography>
        <Divider />
        <br />

        <Typography paragraph>
          <b>Disclaimer of Liability:</b> With respect to documents available
          from this server, neither the United States Government nor any of its
          employees makes any warranty, express or implied, including the
          warranties of merchantability and fitness for a particular purpose;
          nor assumes any legal liability or responsibility for the accuracy,
          completeness, or usefulness of any information, apparatus, product, or
          process disclosed; nor represents that its use would not infringe
          privately owned rights.
        </Typography>

        <Typography paragraph>
          <b>Disclaimer of Endorsement:</b> Reference herein to any specific
          commercial products, process, or service by trade name, trademark,
          manufacturer, or otherwise, does not necessarily constitute or imply
          its endorsement, recommendation, or favoring by the United States
          Government. The views and opinions of authors expressed herein do not
          necessarily state or reflect those of the United States Government,
          and shall not be used for advertising or product endorsement purposes.
        </Typography>

        <Typography paragraph>
          <b>Disclaimer for External Links:</b> The appearance of hyperlinks on
          this page does not constitute endorsement (by the Federal Government,
          Department of Defense (DoD), or the Office of the Under Secretary of
          Defense for Research and Engineering (OUSD(R&E))) of linked websites
          or the information, products or services contained therein. For other
          than authorized activities, the OUSD(R&E) does not exercise any
          editorial control over the information you may find at linked
          locations. External links are provided consistent with the stated
          purpose of this DoD website.
        </Typography>
      </>
    </>
  );
}

PrivacyPolicy.ypes = {};

export default PrivacyPolicy;
