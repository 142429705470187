export default function transformLink(edge, graph) {
  return {
    id: edge['@rid'],
    type: edge['@class'],
    source: edge.in,
    sourceType: edge['in@class'],
    target: edge.out,
    targetType: edge['out@class'],
    color: graph.edges[edge['in@class']],
  };
}
