import login from '../actions/login';
import stopAuthentication from '../actions/stopAuthentication';

const handleAuthResponse = ({ dispatch, authResponse }) => {
  if (authResponse) {
    dispatch(login(authResponse));
  }
  dispatch(stopAuthentication());
};

export default handleAuthResponse;
