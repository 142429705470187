import { darken, lighten } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useCardColor = (color) => {
  const defaultColor = typeof color === 'object' ? color[500] : color;
  const backgroundColor = 'black';
  const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.mode === 'dark';

    const getColorShade = (decimal) => {
      if (isDark) return decimal / 2;
      return decimal;
    };

    return {
      card: {
        width: 345,
        '&:hover': {
          boxShadow: `0px 5px 5px -3px ${lighten(
            defaultColor,
            getColorShade(0.8)
          )},0px 8px 10px 1px ${lighten(
            defaultColor,
            getColorShade(0.4)
          )},0px 3px 14px 2px ${lighten(defaultColor, getColorShade(0.1))}`,
        },
        '&:focus': {
          boxShadow: `0px 5px 5px -3px ${lighten(
            defaultColor,
            getColorShade(0.8)
          )},0px 8px 10px 1px ${lighten(
            defaultColor,
            getColorShade(0.4)
          )},0px 3px 14px 2px ${lighten(defaultColor, getColorShade(0.1))}`,
        },
      },
      row: {
        display: 'flex',
        alignItems: 'center',
      },
      abstract: {
        paddingTop: '15px',
        paddingBottom: '15px',
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      cardMedia: {
        textAlign: 'center',
        borderTop: `2pt solid ${backgroundColor}`,
        borderBottom: `2pt solid ${backgroundColor}`,
        backgroundColor,
        cursor: 'pointer',
        height: '200px',
        '&:focus': {
          boxShadow: `0px 5px 5px -3px ${lighten(
            defaultColor,
            getColorShade(0.8)
          )},0px 8px 10px 1px ${lighten(
            defaultColor,
            getColorShade(0.4)
          )},0px 3px 14px 2px ${lighten(defaultColor, getColorShade(0.1))}`,
        },
      },
      cardHeader: {
        backgroundColor: lighten(defaultColor, getColorShade(0.8)),
        color: 'black',
        // height: '130px',
        cursor: 'pointer',
      },

      cardHeaderDialog: {
        backgroundColor: lighten(defaultColor, getColorShade(0.8)),
      },
      gridHeader: {
        backgroundColor: lighten(defaultColor, getColorShade(0.8)),
        color: 'black',
      },
      avatarColor: {
        color: 'white',
        borderColor: darken(defaultColor, getColorShade(0.1)),
        borderStyle: 'solid',
        borderWidth: '1pt',
        backgroundColor: defaultColor,
      },

      button: {
        color: darken(defaultColor, getColorShade(0.3)),
        fontWeight: 600,
      },
      contrastColor: {
        color: lighten(defaultColor, getColorShade(0.5)),
      },
      contrastColor2: {
        color: defaultColor,
      },

      content: {
        backgroundColor: lighten(defaultColor, getColorShade(0.9)),
        color: 'black',
      },

      version: {
        fontVariant: 'small-caps',
      },
    };
  });
  const classes = useStyles();
  return classes;
};

export default useCardColor;
