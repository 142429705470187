import PropTypes from 'prop-types';
import useUserInfo from '../../features/auth/hooks/useUserInfo';

function Restricted({ accessRoles, userGroups, children }) {
  const { getUserRole, isUserGuest, isUserAdmin, getUserGroups } =
    useUserInfo();
  const { userGroupList } = getUserGroups();

  const userRole = getUserRole();
  const isAdmin = isUserAdmin();
  const isGuest = isUserGuest();

  const hasAccess =
    (isAdmin ||
      accessRoles.includes(userRole) ||
      userGroups.some((group) => userGroupList.includes(group))) &&
    !isGuest;
  return hasAccess ? children : null;
}

Restricted.propTypes = {
  children: PropTypes.any.isRequired,
  accessRoles: PropTypes.array,
  userGroups: PropTypes.array,
};
Restricted.defaultProps = {
  accessRoles: [],
  userGroups: [],
};

export default Restricted;
