import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Rating,
} from '@mui/material';
import Pop from '../pop/Pop';
import useUserRating from '../../hooks/useUserRating';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';

const AssetRating = ({ asset, classes, isDetails }) => {
  const { title, userRating, totalUserRating, assetId } = asset;

  const { isUserGuest } = useUserInfo();
  const isReadOnly = isUserGuest();
  const { Average, toggleUserRating, isDisabled, Count } = useUserRating(
    userRating,
    totalUserRating
  );
  const [userRatingValue, setUserRatingValue] = React.useState({
    value: 0,
    open: false,
  });
  return (
    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
      <Pop
        content={
          <Typography>
            Please rate this resource in terms of overall value and usefulness
            for completing your task. (with 5 stars being the most value)
          </Typography>
        }
      >
        <Rating
          classes={{ iconEmpty: classes.contrastColor }}
          name={assetId}
          value={parseFloat(Average)}
          onChange={(event) => {
            setUserRatingValue({
              open: true,
              value: event.target.value,
            });
          }}
          readOnly={isDetails || isReadOnly || isDisabled}
          precision={0.5}
        />
      </Pop>
      {userRating && parseInt(Count, 10) > 0 ? (
        <Box ml={2}>
          <Typography>({Count})</Typography>
        </Box>
      ) : (
        <Box ml={2}>
          <Typography>(0)</Typography>
        </Box>
      )}
      <Dialog
        open={userRatingValue.open}
        onClose={() => {
          setUserRatingValue({ ...userRatingValue, open: false });
        }}
      >
        <DialogTitleWithClose
          onClose={() => {
            setUserRatingValue({
              ...userRatingValue,
              open: false,
            });
          }}
        >
          Are you sure?
        </DialogTitleWithClose>
        <DialogContent>
          Are you sure you want to rate <b>{title.value}</b>
          {` with ${userRatingValue.value} stars? `}
        </DialogContent>
        <DialogActions>
          <Button
            value="true"
            onClick={() => {
              setUserRatingValue({ open: false });
              toggleUserRating(asset, userRatingValue.value);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setUserRatingValue({
                ...userRatingValue,
                open: false,
              });
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AssetRating.propTypes = {
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isDetails: PropTypes.bool,
};

AssetRating.defaultProps = {
  isDetails: false,
};

export default AssetRating;
