/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import { otmm } from '../../otmm/apis/otmm';
import { DATA_TYPE_LABEL } from '../constants/assetFields';
import getFacetText from './getFacetText';

const facetLabels = {
  'ARTESIA.FIELD.MODEL': DATA_TYPE_LABEL,
};

const createFilters = async (facets = []) => {
  await Promise.all(
    facets.map(async (facet) => {
      const id = facet._facet_field_request.field_id;
      if (!facetLabels[id]) {
        const metadatamodel = await otmm.get(`/metadatafields/${id}`);

        facetLabels[id] =
          metadatamodel.data.metadata_field_resource.metadata_field.name;
      }
    })
  );
  return facets.reduce((acc, curr) => {
    const id = curr._facet_field_request.field_id;
    const facetValueList = curr._facet_value_list;
    let count = 0;
    const facet = {
      id,
      label: _.defaultTo(facetLabels[id], id),
      type: curr.type,
      values: _.orderBy(facetValueList, 'asset_count', 'desc').reduce(
        (values, value) => {
          const text = getFacetText(
            value.numeric_range ||
              value.date_interval ||
              value.value ||
              value.numeric_interval,
            id
          );
          values.push({
            value: {
              text,
              meta: {
                ...(value.numeric_range && {
                  numericRange: value.numeric_range,
                }),
                ...(value.date_interval && {
                  dateInterval: value.date_interval,
                }),
              },
            },
            count: value.asset_count,
          });
          count += value.asset_count;
          return values;
        },
        []
      ),
    };
    facet.count = count;
    acc.push(facet);
    return acc;
  }, []);
};
export default createFilters;
