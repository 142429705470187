import { Button, Typography } from '@mui/material';
import React from 'react';
import { setDialog } from '../../../../actions/dialogActions';
import { defaultDialogState } from '../../../../reducers/dialogReducer';
import { SITE_NAME_ABBR } from '../../constants/constants';

// Uses the redux dialog to warn users before leaving the site for external links.
const HandleExternalLink = ({ href, dispatch }) => {
  const handler = () => {
    window.open(href, '_blank');
    dispatch(setDialog(defaultDialogState));
  };

  const handleNo = () => {
    dispatch(setDialog(defaultDialogState));
  };

  const Content = () => (
    <div style={{ padding: 15 }}>
      <Typography gutterBottom>
        Access to this resource ({href && href}) or link is located outside of
        {SITE_NAME_ABBR}.
      </Typography>

      <Typography>
        <b>Are you sure you wish to leave {SITE_NAME_ABBR}?</b>
      </Typography>
    </div>
  );

  dispatch(
    setDialog({
      title: 'External Link',
      open: true,
      hasCloseButton: false,
      dialogProps: { maxWidth: 'sm', fullWidth: true },
      component: <Content />,
      actions: [
        <Button onClick={handler}>Yes</Button>,
        <Button onClick={handleNo}>No</Button>,
      ],
    })
  );
};

export default HandleExternalLink;
