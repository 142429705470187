import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import Pop from '../../pop/Pop';

const DownloadButton = ({ asset, component, ...otherProps }) => {
  const isControlledAccess = asset.controlledAccess.value === 'Yes';
  const { classes } = otherProps;

  if (isControlledAccess || !asset.assetMasterContent.url) return null;

  switch (component) {
    case IconButton:
      return (
        <Pop content="Download">
          <IconButton
            classes={classes ? { root: classes.contrastColor } : null}
            aria-label="download"
            href={asset.assetMasterContent.url}
            download
            size="large"
          >
            <GetApp />
          </IconButton>
        </Pop>
      );
    case Button:
      return (
        <Button
          startIcon={<GetApp />}
          {...otherProps}
          classes={classes ? { root: classes.contrastColor } : null}
          aria-label="download"
          href={asset.assetMasterContent.url}
          download
        >
          Download
        </Button>
      );

    default:
      return null;
  }
};

DownloadButton.defaultProps = {
  component: IconButton,
  classes: undefined,
};

DownloadButton.propTypes = {
  component: PropTypes.any,
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default DownloadButton;
