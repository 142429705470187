/* eslint-disable */
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SEARCH } from '../../../../constants/paths';
import {
  CURRENT_VERSION,
  SITE_NAME_ABBR,
  SUPPORT_EMAIL,
} from '../../constants/constants';
import PageHeader from '../PageHeader/PageHeader';

const tips = [
  {
    title: `Need help on a page? Look for a “?” button!`,
    content: `Most of the pages on ${SITE_NAME_ABBR} offer help on how to optimize your experience. If you need a little assistance, this can be a great place to start. `,
  },
  {
    title: `Register an account with ${SITE_NAME_ABBR} today!`,
    content: `Registered user accounts are free and offer additional benefits, from saved searches to email notifications! Check the FAQ for a full list of benefits.`,
  },
  {
    title: `Look at resources from a different perspective with the Relationships view!`,
    content: `Check out how resources in the repository are related, whether through topic or publishing organization!`,
  },
  {
    title: `Need a quick link to a reference? ${SITE_NAME_ABBR} has you covered!`,
    content: `As of the ${CURRENT_VERSION} update, you can check out what documents are referenced directly through the resource view! This will also show you what documents in CWS-BoK mention the resource you’re currently looking at.`,
  },
  {
    title: `${SITE_NAME_ABBR} has multiple ways to search!`,
    content: `The guided search will always be available through ${SITE_NAME_ABBR}’s main view, but did you know you can perform keyword searches as well? The search bar at the top will search ${SITE_NAME_ABBR} for all instances of what you’re interested in!`,
  },
  {
    title: `Can't find the resource you're looking for?`,
    content: `${SITE_NAME_ABBR} is always looking to grow! If you're aware of a resource the community may be interested in, be sure to submit a resource nomination. Register an account, and fill out the nomination form today!`,
  },
  {
    title: `Have a question? Looking to provide feedback?`,
    content: `Email us at ${SUPPORT_EMAIL} or check out the "Contact Us" section in the footer.`,
  },
];

const news = [
  `${SITE_NAME_ABBR} has recently been updated to version ${CURRENT_VERSION}.`,
];

const LandingPage = () => {
  const tipsTimeout = useRef(null);
  const history = useHistory();
  const [currentTip, setCurrentTip] = useState();
  const [currentTipTitle, setCurrentTipTitle] = useState();
  const [selectedPathways, setSelectedPathways] = useState([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState([]);

  const handleEnterTips = (title) => {
    setCurrentTipTitle(title);
    // On enter start 5 second timer, then call handleExitTips to change tip to random
    const { length } = tips;

    tipsTimeout.current = setTimeout(() => {
      setCurrentTip(Math.floor(Math.random() * length));
    }, 10000);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && !currentTip) {
      const randomNumber = Math.floor(Math.random() * tips.length);
      setCurrentTipTitle(tips[randomNumber].title);
      setCurrentTip(randomNumber);
    }
    if (!mounted) {
      clearTimeout(tipsTimeout);
    }

    return () => {
      mounted = false;
    };
  }, [currentTip]);

  const pathways = [
    'MBSE Modeler',
    'Systems Engineering',
    'Data Architect',
    'Program Manager',
    'Contract Specialist',
  ];

  const focusAreas = [
    'DE',
    'Digital Engineering',
    'Contracting',
    'Contract Specialist',
    'Ecosystems and Tools',
    'Lessons Learned',
    'Metrics',
    'Strategy',
    'Policy and Guidance',
    'Training',
  ];

  const handleChange = (change, state, setState) => {
    const tempArray = [...state];

    if (!state.includes(change)) {
      tempArray.push(change);
    } else {
      tempArray.splice(
        tempArray.findIndex((item) => item === change),
        1
      );
    }
    setState(tempArray);
  };

  // {"facet_restriction_list":{"facet_field_restriction":[{"type":"com.artesia.search.facet.FacetSimpleFieldRestriction","facet_generation_behavior":"EXCLUDE","field_id":"MSIFT.FIELD.BOK_TOPIC_NAME","value_list":["MBSE+Modeler","Data+Architect"]},{"type":"com.artesia.search.facet.FacetSimpleFieldRestriction","facet_generation_behavior":"EXCLUDE","field_id":"MSIFT.FIELD.BOK_TOPIC_NAME","value_list":["Contracting","Lessons+Learned"]}]}}

  const createFilter = (filterId, values) => ({
    filter_id: filterId,
    value: values.map((value) => ({
      text: value,
      meta: {},
    })),
    type: 'com.artesia.search.facet.FacetSimpleFieldResponse',
    include: true,
    label: 'label',
  });

  const handleSubmit = () => {
    const selectedFilters = [
      createFilter('MSIFT.FIELD.BOK_TOPIC_NAME', [
        ...selectedPathways,
        ...selectedFocusAreas,
      ]),
    ];

    history.push(SEARCH);
    const encodedFilters = window.btoa(JSON.stringify(selectedFilters));

    history.push({
      pathname: SEARCH,
      search: `term=*&filters=${encodedFilters}`,
    });
  };

  return (
    <>
      <PageHeader title="Guided Search" />
      <Typography paragraph>Select one or more of the following</Typography>
      <Grid container spacing={3}>
        <Grid item sm>
          <Card>
            <CardHeader
              title="Choose Pathway(s)"
              sx={{
                backgroundColor: (theme) => theme.accent.blue,
                color: 'text.primary',
              }}
            />
            <CardContent>
              {pathways.map((pathway) => (
                <MenuItem
                  onClick={() =>
                    handleChange(pathway, selectedPathways, setSelectedPathways)
                  }
                >
                  <Checkbox checked={selectedPathways.includes(pathway)} />
                  <ListItemText>{pathway}</ListItemText>
                </MenuItem>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm>
          <Card>
            <CardHeader
              title="Choose Focus Area(s)"
              sx={{
                backgroundColor: (theme) => theme.accent.blue,
                color: 'text.primary',
              }}
            />
            {focusAreas.map((focusArea) => (
              <MenuItem
                onClick={() =>
                  handleChange(
                    focusArea,
                    selectedFocusAreas,
                    setSelectedFocusAreas
                  )
                }
              >
                <Checkbox checked={selectedFocusAreas.includes(focusArea)} />
                <ListItemText>{focusArea}</ListItemText>
              </MenuItem>
            ))}
          </Card>
        </Grid>
      </Grid>

      <Button
        fullWidth
        variant="contained"
        sx={{ marginTop: '24px', color: 'primary.light' }}
        onClick={handleSubmit}
        disabled={
          selectedFocusAreas.length === 0 || selectedPathways.length === 0
        }
      >
        Search
      </Button>
    </>
  );
};
LandingPage.propTypes = {};

export default LandingPage;
