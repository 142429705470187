import _ from 'lodash';

const getSearchId = (searchConfigurations, name) => {
  const searchId = _.find(
    searchConfigurations.data.search_configurations_resource
      .search_configuration_list,
    ['name', name]
  ).id;

  return searchId;
};

export default getSearchId;
