import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, DialogTitle, DialogContent, Dialog } from '@mui/material';
import { Help, Close } from '@mui/icons-material';

function DialogTitleWithClose({ help, onClose, children }) {
  const [show, setShow] = React.useState(false);
  return (
    <div>
      <DialogTitle sx={{ color: 'primary.contrastText' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {children}
          <div>
            {help && (
              <IconButton
                onClick={() => setShow(!show)}
                style={{ color: 'inherit' }}
                size="large"
              >
                <Help />
              </IconButton>
            )}
            <IconButton
              onClick={onClose}
              style={{ color: 'inherit' }}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <Dialog open={show} fullWidth onClose={() => setShow(false)}>
        <DialogTitleWithClose onClose={() => setShow(false)}>
          Help
        </DialogTitleWithClose>
        <DialogContent>
          {/* <Alert severity="info" onClose={() => setShow(false)}> */}
          {help}
          {/* </Alert> */}
        </DialogContent>
      </Dialog>

      {/* {show && (
        <DialogContent style={{ maxHeight: 200, overflowY: 'scroll' }}>


        </DialogContent>
      )} */}
    </div>
  );
}

DialogTitleWithClose.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  help: PropTypes.any,
};

DialogTitleWithClose.defaultProps = {
  help: undefined,
};

export default DialogTitleWithClose;
