const DEFAULT_VIEW_KEY = 'defaultView';
export const LIST_VIEW = 'List-View';
export const CARD_VIEW = 'Card-View';

export const defaultView = {
  get: () => {
    if (!localStorage.getItem(DEFAULT_VIEW_KEY))
      localStorage.setItem(DEFAULT_VIEW_KEY, CARD_VIEW);
    return localStorage.getItem(DEFAULT_VIEW_KEY);
  },
  set: (view) => {
    localStorage.setItem(DEFAULT_VIEW_KEY, view);
  },
};
