import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { matchPath } from 'react-router-dom';
import { ASSET } from '../../../../../constants/paths';
import getDateIndex from '../../../hooks/getDateIndex';
import useCardColor from '../../../hooks/useCardColor';
import GetDomainTheme from '../../CRWS/functions/GetDomainTheme';
import ShareList from '../../QuickSelectIcons/ShareList/ShareList';

function CrwsDetailSubheader({ asset, versions}) {

  // Need to get the path of the window to see if we are in the Asset Details view
  // If we are, the subtitles need to be white and we do not need to display the share button
  // This is reflected in the JSX using the following boolean
  const isAssetDetailsView = matchPath(window.location.pathname, ASSET);

  const {
    DateEffective: dateEffective,
    DescriptionofVersion: version,
  } = versions.values;

  const dateIndex = getDateIndex(dateEffective);

  const { domains } = asset;
  const { Domain: domain } = domains.values;

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};

  const classes = useCardColor(cardDetails.color);
  
  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{ color: isAssetDetailsView ? '#FFFFFF' : 'black' }}
        display="inline"
      >
        {dateEffective.values[0] ? dateEffective.values[dateIndex] : 'NA'}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ color: isAssetDetailsView ? '#FFFFFF' : 'black' }}
        display="inline"
      >
        {' '}
        -{' '}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ color: isAssetDetailsView ? '#FFFFFF' : 'black' }}
        display="inline"
      >
        {version.values[0] ? version.values[dateIndex] : 'NA'}
      </Typography>

      { isAssetDetailsView ? <> </> : <ShareList asset={asset} classes={classes} /> }
      
    </div>
  );
}

CrwsDetailSubheader.propTypes = {
  versions: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
};

export default CrwsDetailSubheader;
