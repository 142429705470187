import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';

const getDeleteLinksParam = (assetId, destinationIds) => {
  const deleteLinksParam = JSON.stringify({
    delete_links_param: {
      delete_links: {
        asset_id_list: [assetId],
        delete_links_mode: {
          type: 'com.artesia.asset.links.DeleteByLinkIds',
          link_ids: destinationIds.map((id) => ({
            source_id: assetId,
            destination_id: id,
            link_type_id: '*',
          })),
        },
      },
    },
  });

  return deleteLinksParam;
};

const getLinksData = (assetId, destinationIds) => {
  const data = new URLSearchParams();
  data.append('asset_ids', assetId);
  data.append(
    'delete_links_param',
    getDeleteLinksParam(assetId, destinationIds)
  );
  return data;
};

const unlinkAssets = async ({ assetId, destinationIds }) => {
  const response = await otmm.delete(`/assets/links`, {
    params: getLinksData(assetId, destinationIds),
  });
  return response;
};

export default unlinkAssets;
