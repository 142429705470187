import _ from 'lodash';
import { otmm, SEARCH_FREQUENCIES_BASE_URL } from '../../otmm/apis/otmm';

const getSavedSearches = async () => {
  const response = await otmm
    .get(`/savedsearches`, {
      params: {
        save_type: 'private',
      },
    })
    .catch((err) => ({ error: err }));
  if (response.error) return response;

  const savedSearchList =
    (response &&
      response.data &&
      response.data.saved_searches_resource.saved_search_list) ||
    [];

  const notificationFrequencies = await otmm.get(
    `${SEARCH_FREQUENCIES_BASE_URL}`
  );

  const savedSearchListWithNotificationFrequencies = savedSearchList.map(
    (savedSearch) => {
      // Allow existing saved searches to just default to NEVER
      const foundFrequencyData = _.find(notificationFrequencies.data, {
        searchId: `${savedSearch.id}`,
      });
      const notificationFrequency = foundFrequencyData
        ? foundFrequencyData.frequency
        : 'NEVER';
      return {
        ...savedSearch,
        notificationFrequency,
      };
    }
  );
  return savedSearchListWithNotificationFrequencies;
};

export default getSavedSearches;
