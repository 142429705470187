import CreateComboBox from '../CreateCombobox/CreateComboBox';
import CreateTextField from '../CreateTextField/CreateTextField';

const COMBO = 'COMBO';
const COMBONOTNULL = 'COMBO_NOTNULL';
const TEXTAREA = 'TEXTAREA';
const SIMPLE = 'SIMPLE';
const DATE = 'DATE';

const getRows = (dataLength) => {
  if (dataLength <= 1000) return 1;
  if (dataLength <= 2000) return 3;
  if (dataLength <= 3000 || dataLength > 3000) return 5;
  return 1;
};

const CreateField = ({ field, values, updateValues }) => {
  const {
    edit_type: editType,
    data_length: dataLength,
    required,
    name,
  } = field;

  const isURL = name ? name.includes('URL') : false;

  switch (editType) {
    case COMBO:
      return CreateComboBox({
        field,
        values,
        updateValues,
        required,
      });
    case COMBONOTNULL:
      return CreateComboBox({ field, values, updateValues, required });
    case TEXTAREA:
      return CreateTextField({
        field,
        values,
        updateValues,
        required,
        type: isURL ? 'url' : 'text',
        multiline: true,
        rows: getRows(dataLength),
      });

    case SIMPLE:
      return CreateTextField({
        field,
        values,
        updateValues,
        type: isURL ? 'url' : 'text',
        required,
      });
    case DATE:
      return CreateTextField({
        field,
        values,
        updateValues,
        required,
        type: 'date',
        InputLabelProps: {
          shrink: true,
        },
      });
    default: {
      // Allow console.log for console warning if a field fails to generate.
      // eslint-disable-next-line
      console.warn(
        'An error occurred... a field was not generated properly. ',
        field
      );
      return null;
    }
  }
};

export default CreateField;
