import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Step,
  StepLabel,
  Stepper
} from '@mui/material';

import getSteps from './functions/getSteps'; // Steps array - for the stepper
import RegistrationPageStepsContent from './RegistrationPageStepsContent';

const RegistrationPage = ({ onComplete, setIsRegistering }) => {

  // For Stepper 
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // This function is passed to step content and will trigger when the user advances a step
  const incrementStep = () => {
    if(activeStep === 2){
      // By setting this to false, we can tell parent component to take the user to the login screen
      setIsRegistering(false);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} style={{marginTop: '15px', marginBottom: '10px'}}>
        {steps.map((step) => (
            <Step key={step}>
              <StepLabel>
                {step}
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
      <RegistrationPageStepsContent 
        activeStep={activeStep} 
        incrementStep={incrementStep} 
        onComplete={onComplete} 
        />
    </>
  );
};

RegistrationPage.propTypes = {
  onComplete: PropTypes.func,
  setIsRegistering: PropTypes.func,
};

RegistrationPage.defaultProps = { 
  onComplete: undefined,
  setIsRegistering: undefined,
};

export default RegistrationPage;
