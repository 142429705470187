import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { Button, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ABOUT, HOME } from '../../../../constants/paths';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import DialogButton from '../DialogButton/DialogButton';
import MenuIconButton from '../MenuButtons/MenuIconButton';
import UserMenuItems from '../MenuButtons/MenuItems/UserMenuItems';
import NominateResource from '../NominateResource/NominateResource';

const MenuStripDivider = () => (
  <div style={{ height: '42px' }}>
    <Divider
      orientation="vertical"
      style={{
        marginLeft: '5px',
        marginRight: '5px',
      }}
      sx={{ backgroundColor: '#808080' }}
    />
  </div>
);

export default function NavTabs() {
  const history = useHistory();
  const { getUserLogin } = useUserInfo();
  const getLogin = getUserLogin();
  const UserMenu = UserMenuItems();

  const { pathname } = window.location;

  const links = [
    {
      href: HOME,
      label: 'Home',
    },
    {
      dialogContent: <NominateResource />,
      label: 'Contribute',
    },
    {
      href: '/glossary',
      label: 'Glossary',
    },
    {
      href: ABOUT,
      label: 'About',
    },
  ];

  const index = Number(links.findIndex((link) => link.href === pathname));

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={index}
        aria-label="navigation tabs"
        TabIndicatorProps={{ style: { background: '#A5E6BA' } }}
      >
        {links.map((link, index) => {
          const { href, dialogContent, label } = link;

          if (dialogContent) {
            return (
              <DialogButton
                key={href}
                content={dialogContent}
                component={Tab}
                title={label}
                label={label}
                sx={{ fontFamily: ['Martel'] }}
                aria-label={`${link.label}Page`}
                aria-haspopup="true"
                value={index}
              />
            );
          }
          return (
            <Tab
              key={href}
              label={label}
              sx={{ fontFamily: ['Martel'] }}
              aria-label={`${link.label}Page`}
              aria-haspopup="true"
              value={index}
              onClick={() => history.push(link.href)}
            />
          );
        })}
        <MenuStripDivider />
        <MenuIconButton
          component={Button}
          content={`Signed in as ${getLogin}  `}
          menuItems={UserMenu}
          sx={{
            p: 2,
            fontFamily: 'Martel',
            '.MuiButton-startIcon': {
              paddingBottom: '3px',
            },
          }}
          startIcon={<AccountBoxOutlinedIcon />}
        >
          {getLogin}
        </MenuIconButton>
      </Tabs>
    </Box>
  );
}
