import {
  SET_ERROR,
  SET_LOADING,
  SET_PENDING_UPDATE,
  UPDATE_OTMM,
} from './otmmActionTypes';

export const updateOtmmState = (model) => ({
    type: UPDATE_OTMM,
    payload: model,
  });

export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
  });

export const setPendingUpdate = (isPendingUpdate) => ({
    type: SET_PENDING_UPDATE,
    payload: isPendingUpdate,
  });

export const setOtmmError = ({ error, message }) => ({
    type: SET_ERROR,
    payload: { error, message },
  });

export const clearOtmmError = () => ({
    type: SET_ERROR,
    payload: undefined,
  });
