import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PdfViewer from '../PdfViewer/PdfViewer';
import AssetDetailsPane from '../assets/AssetDetailsPane';
import {
  CRWS_JUSTIFICATION,
  CRWS_MODEL_ID,
  CRWS_REVIEW_DATE,
  CRWS_REVIEW_USER_ID,
  CRWS_REVIEW_COMMENTS,
  CRWS_REVIEW_APPROVAL,
} from '../../constants/assetFields';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import CreateField from '../CreateFields/CreateField';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import fetchMetadataFields from '../../functions/fetchMetadataFields';
import transformFields from '../../functions/transformFields';
import createOtmmAsset from '../../functions/createOtmmAsset';
import updateAsset from '../../functions/updateAsset';
import getAsset from '../../functions/getAsset';
import { SITE_NAME_ABBR } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Review Resource', 'Review Metadata', 'Approve or Deny'];
}

function StepContent({ activeStep, asset, values, updateValues, fields }) {
  // if the asset metadata exists for comments and approval for this user, then put in default values
  switch (activeStep) {
    case 0:
      return (
        <>
          <PdfViewer asset={asset} />
        </>
      );
    case 1:
      return (
        <AssetDetailsPane
          disableAccordion
          include={[CRWS_JUSTIFICATION]}
          asset={asset}
        />
      );

    case 2:
      return (
        <>
          <Typography variant="body1" paragraph>
            Indicate if this resource should be included in {SITE_NAME_ABBR}. To deny,
            select &#34;NO&#34; and note the criteria that were not met in the
            comments section. To approve, select &#34;YES&#34; and briefly
            explain why the resource should be included in {SITE_NAME_ABBR} in the
            comments section. Then press submit to complete. Be sure to include
            a time estimate for how long the review process took.
          </Typography>
          {fields &&
            values &&
            Object.keys(fields).map((key) => {
              const field = fields[key];
              const { id } = field;
              const hidden = [CRWS_REVIEW_USER_ID, CRWS_REVIEW_DATE];
              if (hidden.includes(id)) return null;
              return (
                <CreateField
                  key={id}
                  field={field}
                  values={values}
                  updateValues={updateValues}
                />
              );
            })}
        </>
      );
    default:
      return 'Unknown step';
  }
}

StepContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  asset: PropTypes.object.isRequired,
  values: PropTypes.object,
  updateValues: PropTypes.func.isRequired,
  fields: PropTypes.object,
};

StepContent.defaultProps = {
  values: undefined,
  fields: undefined,
};

function ReviewBoardPage({ asset, open, onClose, ...otherProps }) {
  const { getUserLogin } = useUserInfo();

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const userId = getUserLogin();

  const formattedDate = (date) => date.toISOString().slice(0, 10);
  const [fields, setFields] = useState();
  const [values, setVal] = useState();
  const defaultMetadata = {
    metadata_model_id: CRWS_MODEL_ID,
    name: 'Resources',
  };

  const { reviewBoard } = asset;
  const { User, Approval, Comments } = reviewBoard.values;

  const index = User.values.indexOf(getUserLogin());

  const options = {
    defaultValues: {
      [CRWS_REVIEW_USER_ID]: userId,
      [CRWS_REVIEW_DATE]: formattedDate(new Date()),
      [CRWS_REVIEW_APPROVAL]: index !== -1 ? Approval.values[index] : '',
      [CRWS_REVIEW_COMMENTS]: index !== -1 ? Comments.values[index] : '',
    },
  };

  useState(async () => {
    const result = await fetchMetadataFields(
      `${CRWS_REVIEW_DATE}, ${CRWS_REVIEW_USER_ID}, ${CRWS_REVIEW_APPROVAL}, ${CRWS_REVIEW_COMMENTS}`
    );
    const { defaultFields, defaultValues } = await transformFields(
      result,
      options
    );

    setFields(defaultFields);
    setVal({ ...defaultMetadata, ...defaultValues });
  });
  const updateValues = (e, field) => {
    if (field) {
      const { id } = field;
      setVal({
        ...values,
        [id]: { ...values[id], ...field, value: e.target.value },
      });
    }

    return null;
  };

  const handleSubmit = async () => {
    setLoading(true);
    // TODO Must fetch asset prior to updating just in case another user has updated before this form was filled out. For demo, use asset passed instead of pulling it before making changes.

    const updatedAsset = await getAsset(asset.assetId);

    // Combine with current values that come back from ${asset}.
    // Metadata should include both type, and value in an array of "value" before being consumed by metasift.
    // const index = asset.reviewBoard.values.User.values.indexOf(getUserLogin());
    // Replace the splice the original values (index is already stored here.. $index) for User, Date, Comment, and Approval
    if (index !== -1) {
      // Include the value from the values array into the old values, and append to include 'type' === string, dateTime, etc... this is determined by the data_type in the field object.
      updatedAsset.reviewBoard.values.Approval.values.splice(
        index,
        1,
        values['CRWS.TAB.REVIEW.APPROVAL'].value
      );
      updatedAsset.reviewBoard.values.Comments.values.splice(
        index,
        1,
        values['CRWS.TAB.REVIEW.COMMENTS'].value
      );
      updatedAsset.reviewBoard.values.Date.values.splice(
        index,
        1,
        values['CRWS.TAB.REVIEW.DATE'].value
      );

      // The values before going to metasift should look like this...
    } else {
      updatedAsset.reviewBoard.values.Date.values.push(
        values['CRWS.TAB.REVIEW.DATE'].value
      );
      updatedAsset.reviewBoard.values.Comments.values.push(
        values['CRWS.TAB.REVIEW.COMMENTS'].value
      );
      updatedAsset.reviewBoard.values.Approval.values.push(
        values['CRWS.TAB.REVIEW.APPROVAL'].value
      );
      updatedAsset.reviewBoard.values.User.values.push(
        values['CRWS.TAB.REVIEW.USER_ID'].value
      );
    }
    // Temporary values for YES and NO
    const YES = '0';
    const NO = '1';

    const commentValues = updatedAsset.reviewBoard.values.Comments.values.map(
      (value) => ({
        type: 'string',
        value,
      })
    );

    const userValues = updatedAsset.reviewBoard.values.User.values.map(
      (value) => ({ type: 'string', value })
    );
    const approvalValues = updatedAsset.reviewBoard.values.Approval.values.map(
      (value) => ({
        type: 'string',
        value: value.toLowerCase() === 'yes' || value === YES ? YES : NO,
      })
    );
    const dateValues = updatedAsset.reviewBoard.values.Date.values.map(
      (value) => ({ type: 'dateTime', value })
    );
    const tempValues = JSON.parse(JSON.stringify(values));

    tempValues[CRWS_REVIEW_APPROVAL].value = approvalValues;
    tempValues[CRWS_REVIEW_COMMENTS].value = commentValues;
    tempValues[CRWS_REVIEW_DATE].value = dateValues;
    tempValues[CRWS_REVIEW_USER_ID].value = userValues;

    const otmmAsset = JSON.parse(await createOtmmAsset({ values: tempValues }));
    const metadata =
      otmmAsset.asset_resource.asset.metadata.metadata_element_list;

    const editedAsset = {
      edited_asset: {
        data: {
          metadata,
        },
      },
    };
    updateAsset(editedAsset, asset);
    onClose();
    setLoading(false);
  };

  const isStepOptional = () => false;
  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // This stupid thing won't compile!
  return (
    <>
      <Dialog open={open} onClose={onClose} {...otherProps}>
        <DialogTitleWithClose onClose={onClose}>
          {asset.title.value || 'Unnamed Resource'}
        </DialogTitleWithClose>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption" key={label}>
                    Optional
                  </Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    style={{ cursor: 'pointer' }}
                    onClick={() => setActiveStep(index)}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography variant="body1">
                  Your vote and comments has been submitted.
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Back to Review Board
                </Button>
              </div>
            ) : (
              <div>
                {/* <Typography className={classes.instructions}> */}
                <StepContent
                  activeStep={activeStep}
                  asset={asset}
                  fields={fields}
                  values={values}
                  updateValues={updateValues}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          {isStepOptional(activeStep) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSkip}
              className={classes.button}
            >
              Skip
            </Button>
          )}

          {loading ? (
            <>
              {' '}
              <Typography color="primary"> Updating...</Typography>
              <CircularProgress fontSize="small" color="primary" />
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          )}
        </DialogActions>

        {/* </Paper> */}
      </Dialog>
    </>
    // </div>
  );
}

ReviewBoardPage.propTypes = {
  asset: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReviewBoardPage;
