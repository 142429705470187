import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Alert, Button, Collapse, TextField, Typography } from '@mui/material';

import { registrationApi } from '../../../../otmm/apis/otmm';

const VerificationForm = ({ incrementStep, username, vCodeMessage}) => {
  
  const encodedUsername = window.btoa(`${username}`);

  const [verificationCode, setVerificationCode] = useState(' ');

  const [loading, setLoading] = useState(false);
  
  const [message, setMessage] = useState({
    severity: 'success',
    message: '',
    open: false,
  });

  const error = (err) => {
    setMessage({
      severity: 'error',
      open: true,
      message: err.response.status === 403
				? `Invalid code. Please check the email to make sure you are entering the correct code.`
				: `${err.message} - no response from the server. If problem persists contact administrator.`,
    });
  };

  // Send verification code to API w/ post request
  const handleOnClick = async (e) => {

    e.preventDefault();
    setLoading(true);

    let codeWasCorrect = false;

    await registrationApi
      .post(`user/activate/${encodedUsername}?code=${verificationCode}`)
      .then(() => {
        codeWasCorrect = true;
      })
      .catch((err) => {
        error(err);
      });
    
    setLoading(false);

    if(codeWasCorrect){
      incrementStep(); // Callback function for stepper in RegistrationPages.js 
    }
  };

  function validateForm() {
    return !(verificationCode <= 999999 && verificationCode >= 100000);  
  }

  const formatVCode = () => {
    const prefix = vCodeMessage.split('sent to '); // prefix[0] = before user's email, prefix[1] = user's email and after

    const emailSplit = prefix[1].split('. '); // emailSplit[0] = just the user's email, emailSplit[1] = everything after

    const suffixSplit = emailSplit[1].split('in the ');

    return (
      <>
        <Typography align="center" style={{marginTop: '10px'}}>
          {prefix[0]} sent to <b>{emailSplit[0]}</b>.
        </Typography>
        <Typography align="center" style={{marginTop: '10px'}}>
          {suffixSplit[0]}
        </Typography>
        <Typography align="center">
          in the {suffixSplit[1]}.
        </Typography>
      </>
    
    );
  };

  return (
    <>
      <form>
          <TextField 
              id="verificationCode"
              label="Verification Code"
              placeholder="Verification Code"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
          />
      </form>

      {formatVCode()}

      <Collapse in={message.open} mountOnEnter unmountOnExit>
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, open: false })}
        >
          {message.message}
        </Alert>
      </Collapse>

      <Button 
        fullWidth
        variant="contained"
        style={{ marginTop: '10px', marginBottom: '10px' }}
        onClick={handleOnClick}
        type="submit"
        disabled={validateForm()}
      >
        {loading ? <>Loading...</> : <>Submit</>}
      </Button>
    </>
  );
};

VerificationForm.propTypes = {
    incrementStep: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    vCodeMessage: PropTypes.string.isRequired,
};

export default VerificationForm;
