const parseDate = (dateString) => {
  // assuming that dates are always UTC
  if (dateString) {
    return new Date(Date.parse(dateString)).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    });
  }
  return '-';
};

export default parseDate;
