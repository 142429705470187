import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Graph } from 'react-d3-graph';
import { connect } from 'react-redux';

import GraphLegend from './GraphLegend';
import ErrorBoundary from '../../../../../components/ErrorBoundary/ErrorBoundary';
import Pop from '../../pop/Pop';
import Banner from '../../Banner/Banner';
import DialogTitleWithClose from '../../DialogTitleWithClose/DialogTitleWithClose';
import getAsset from '../../../functions/getAsset';
import SimpleCard from '../../Cards/SimpleCard';
import SimpleSlide from '../../SimpleSlide/SimpleSlide';
import { SITE_NAME_ABBR } from '../../../constants/constants';

const RelationsGraph = ({ assetId, graphData }) => {
  const graphRef = useRef();
  const parentRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDark = theme.palette.mode === 'dark';

  const [data, setData] = useState(graphData[assetId]);

  const [config] = useState({
    collapsible: true,
    height: window.innerHeight,
    width: window.innerWidth - 100,

    freezeAllDragEvents: false,
    highlightDegree: 1,
    highlightOpacity: 0.2,
    linkHighlightBehavior: true,

    nodeHighlightBehavior: true,
    panAndZoom: false,
    staticGraph: false,
    staticGraphWithDragAndDrop: false,
    d3: {
      alphaTarget: 0.001,
      gravity: -200,
      linkLength: 100,
      linkStrength: 0.1,
    },
    node: {
      fontColor: isDark ? 'white' : theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 'normal',
      highlightColor: 'SAME',
      highlightFontSize: 14,
      highlightFontWeight: 'bold',
      highlightStrokeColor: theme.palette.primary.main,
      highlightStrokeWidth: 'SAME',
      labelPosition: 'bottom',
      labelProperty: 'name',
      mouseCursor: 'pointer',
      opacity: 1,
      renderLabel: true,
      size: 100,
      strokeColor: 'none',
      strokeWidth: 1.5,
      symbolType: 'circle',
    },
    link: {
      fontSize: 8,
      fontWeight: 'normal',
      highlightColor: theme.palette.secondary,
      highlightFontSize: 12,
      highlightFontWeight: 'normal',
      labelProperty: 'label',
      mouseCursor: 'pointer',
      opacity: 0.8,
      renderLabel: false,
      semanticStrokeWidth: false,
      strokeWidth: 1,
      markerHeight: 6,
      markerWidth: 6,
      strokeDasharray: 0,
      strokeDashoffset: 0,
      strokeLinecap: 'butt',
    },
  });
  const [OpenDialog, setOpenDialog] = useState({
    open: false,
    assetId: 0,
  });

  const [openAsset, setOpenAsset] = useState({ open: false, asset: {} });

  const onDoubleClickNode = async (nodeId, node) => {
    if (nodeId && node.type === 'Asset' && node.assetId !== assetId) {
      const selectedAssetId = node.assetId;
      const asset = await getAsset(selectedAssetId);
      setOpenAsset({ open: true, asset });
    }
  };
  const [showHelp, setShowHelp] = useState(false);
  const [hideLegend, setHideLegend] = useState(false);
  return (
    <>
      <Banner
        title={<b>How to Use</b>}
        handler={() => setShowHelp(!showHelp)}
        open={showHelp}
        icon={<Help />}
      >
        <>
          <Typography paragraph>
            This graph shows various relations between the current asset being
            viewed (green star) and other assets (red squares) in {SITE_NAME_ABBR}.
          </Typography>
          <Typography variant="h6">Legend</Typography>
          <Typography paragraph>
            The Legend shows the different types of relations in the graph.
            Example: assets connect to the same orange circle have the same BoK
            topic in common. By selecting on a relationship on the legend will
            make those relationships vanish. Selecting on it again will bring
            them back. Selecting the arrow at the top of the legend will
            collapse it, Selecting it again will reopen it. Choosing the eye
            icon on the far side of the graph area will remove the legend,
            choosing it again will bring it back.
          </Typography>
          <Typography variant="h6">Graph</Typography>
          <Typography paragraph>
            The graph can be zoomed in on by using normal scrolling gestures
            with the mouse. Example: Using a scroll wheel or dragging two
            figures vertically across the track pad. Clicking and holding on a
            node will allow for dragging it within the graph area. Short click
            on an asset node will allow for opening that asset. Clicking around
            the graph will allow for dragging the graph as a whole.
          </Typography>
        </>
      </Banner>
      {isMobile && (
        <div
          style={{
            width: '200px',
            padding: '15px',
            display: hideLegend ? 'none' : 'block',
          }}
        >
          <GraphLegend graphData={graphData} data={data} setData={setData} />
        </div>
      )}
      <div style={{ minHeight: '600px', width: '100%' }} ref={parentRef}>
        {!isMobile && (
          <div
            style={{
              width: '200px',
              position: 'absolute',
              padding: '15px',
              display: hideLegend ? 'none' : 'block',
            }}
          >
            <GraphLegend graphData={graphData} data={data} setData={setData} />
          </div>
        )}

        <Paper style={{ float: 'right' }}>
          <Pop content="Hide Legend">
            <IconButton
              color={isDark ? 'secondary' : 'primary'}
              onClick={() => setHideLegend(!hideLegend)}
              size="large"
            >
              {hideLegend ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Pop>

          <Pop content="Help">
            <IconButton
              color={isDark ? 'secondary' : 'primary'}
              onClick={() => setShowHelp(!showHelp)}
              size="large"
            >
              <Help />
            </IconButton>
          </Pop>
        </Paper>
        <ErrorBoundary>
          <Graph
            ref={graphRef}
            config={config}
            id="graph-id"
            data={data}
            onClickNode={onDoubleClickNode}
          />
        </ErrorBoundary>
      </div>
      <Dialog
        open={OpenDialog.open}
        onClose={() => {
          setOpenDialog({ ...OpenDialog, open: false });
        }}
      >
        <DialogTitleWithClose
          onClose={() => {
            setOpenDialog({
              ...OpenDialog,
              open: false,
            });
          }}
        >
          Open Asset?
        </DialogTitleWithClose>
        <DialogContent>
          Would you like to open asset in another tab?
          <br />
          {/* {OpenDialog.assetId} */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog({ open: false });
              window.open(
                `http://${window.location.host}/asset/${OpenDialog.assetId}`,
                '_blank'
              );
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenDialog({
                ...OpenDialog,
                open: false,
              });
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleSlide
        open={openAsset.open}
        onClose={() => setOpenAsset({ ...openAsset, open: false })}
      >
        <SimpleCard asset={openAsset.asset} />
      </SimpleSlide>
    </>
  );
};

RelationsGraph.propTypes = {
  assetId: PropTypes.string.isRequired,
  graphData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  graphData: state.otmmSettings.graphData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RelationsGraph);
