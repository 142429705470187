export const CAPTURE_DASH_MASTER_SEARCH = 'Resources';
export const SEARCH_ALL_WILDCARD = '*';
export const FETCH_OTMM_SUBSCRIPTIONS = 'FETCH_OTMM_SUBSCRIPTIONS';
export const SEARCH_OTMM = 'SEARCH_OTMM';
export const SEARCH_ASSET = 'SEARCH_ASSET';
export const CRWS_DEFAULT = 'Resources';
export const SAVED_SEARCH_OTMM = 'SAVED_SEARCH_OTMM';
export const CPARS_SEARCH = 'CPARS';
export const GWAC_SEARCH = 'GWAC/IDIQ';
export const SAFT_SEARCH = 'System Safety (SAFT)';
export const SESS_SEARCH =
  'System Engineering Specifications and Standards (SESS)';
export const SCRE_SEARCH = 'Secure Cyber Resilient Engineering (SCRE)';
