// Form for having user submit the verification code received in email

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Collapse, TextField, Typography } from '@mui/material';

import { registrationApi } from '../../../../otmm/apis/otmm';

const SubmitCodeForm = ({ advanceToNextStep, stepsEmail, stepsUsername, setStepsVcode }) => {
	const [verificationCode, setVerificationCode] = useState({
    value: '',
    valid: false,
  });

	const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
		severity: 'success',
    message: '',
    open: false,
	});

	const error = (err) => {
		setMessage({
			severity: 'error',
			open: true,
			message: !err.response.data
				? `Invalid code. Please check the email to make sure you are entering the correct code.`
				: `${err.message} - no response from the server. If problem persists contact administrator.`,
		});
	};

	const handleOnClick = async (e) => {
		e.preventDefault();
		setLoading(true);

		let codeWasCorrect = false;

		await registrationApi
		.post(
			'/security/validate',
			JSON.stringify({
				code: Number(verificationCode.value),
					user: { 
						email: stepsEmail, 
						username: stepsUsername.toLowerCase()
					}
			})
		)
    .then(() => {
			codeWasCorrect = true;
      setStepsVcode(verificationCode.value);
    })
    .catch((err) => {
      error(err);
    })
    
    setLoading(false);

		if(codeWasCorrect){
			advanceToNextStep(); // Must do this at the bottom to avoid a memory leak
		}
	};

	const validateForm = () => verificationCode.valid;

	return (
		<>
			<Typography align="center">
				Please enter the confirmation code that was sent to{' '}
			</Typography>
			<Typography
			fontWeight="bold"
			align="center"
			>{
				`${stepsEmail}`}
			</Typography>
			<form onSubmit={(e) => e.preventDefault()}>
				<TextField
          fullWidth
					type="number"
					id="outlined-basic"
					onChange={(e) => setVerificationCode({value: e.target.value, valid: e.target.value.length >= 6})}
					style={{marginTop: '10px', marginBottom: '15px'}}
				/>

        <Collapse in={message.open} mountOnEnter unmountOnExit>
          <Alert
            severity={message.severity}
            onClose={() => setMessage({ ...message, open: false })}
          >
            {message.message}
          </Alert>
       </Collapse>

				<Button
					fullWidth
					variant="contained"
					onClick={handleOnClick}
					disabled={!validateForm()} 
					color="primary"
					style={{ marginTop: '10px', marginBottom: '10px' }}
				>
					{loading ? <>Loading...</> : <>Validate</>}
				</Button>
			</form> 
		</>
	);
};

SubmitCodeForm.propTypes = {
	advanceToNextStep: PropTypes.func.isRequired,
	stepsEmail: PropTypes.string.isRequired,
	stepsUsername: PropTypes.string.isRequired,
  setStepsVcode: PropTypes.func.isRequired,
}

export default SubmitCodeForm;