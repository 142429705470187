import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { ScatterPlot } from '@mui/icons-material';
import ReferencesAndRelationships from '../../../pages/ReferencesAndRelationships/ReferencesAndRelationships';
import Pop from '../../pop/Pop';
import DialogTitleWithClose from '../../DialogTitleWithClose/DialogTitleWithClose';

const GraphDialogButton = ({ asset, component, ...otherProps }) => {
  const [open, setOpen] = useState(false);

  const { title, assetId, approvalStatus } = asset;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const element = () => {
    switch (component) {
      case IconButton:
        return (
          <Pop content="View Relationship Graph">
            <IconButton
              onClick={handleOpen}
              aria-label="View Relationship Graph"
              {...otherProps}
              size="large"
            >
              <ScatterPlot />
            </IconButton>
          </Pop>
        );
      case Button:
        return (
          <Button
            {...otherProps}
            aria-label="View Relationship Graph"
            onClick={handleOpen}
            startIcon={<ScatterPlot />}
          >
            View Relationship Graph
          </Button>
        );

      default:
        return null;
    }
  };
  if (approvalStatus.value !== 'Approved') return null;
  return (
    <div>
      {element()}
      <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
        {title && title.value && (
          <DialogTitleWithClose onClose={handleClose}>
            {title.value}
          </DialogTitleWithClose>
        )}

        <DialogContent>
          <ReferencesAndRelationships assetId={assetId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
GraphDialogButton.defaultProps = {
  component: IconButton,
};

GraphDialogButton.propTypes = {
  asset: PropTypes.object.isRequired,
  component: PropTypes.any,
};

export default GraphDialogButton;
