import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ValidationCheckList from '../ValidationCheckList';

function PasswordField({
  state,
  setState,
  id,
  label,
  type,
  confirmationField,
  errorText,
  ...otherProps
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  /* Throw an error if password type and confirmationFields are both present in the component. */
  if (type === 'password' && confirmationField !== undefined) {
    throw new Error(
      'Cannot have Password Validation and Confirmation (matching) Validation. Use one or the other.'
    );
  }

  const fontColor = error ? 'red' : '#A9A9A9';

  const hasSpecialCharacter = (passwordToTest) =>
    passwordToTest.includes('!') ||
    passwordToTest.includes('@') ||
    passwordToTest.includes('?') ||
    passwordToTest.includes('#') ||
    passwordToTest.includes('$');

  const hasIllegalCharacters = (passwordToTest) =>
    passwordToTest.includes('%') ||
    passwordToTest.includes('/') ||
    passwordToTest.includes('\\') ||
    passwordToTest.includes('&');

  function validatePassword(value) {
    return (
      !value.length < 7 &&
      /[a-z]/.test(value) &&
      /[A-Z]/.test(value) &&
      hasSpecialCharacter(value) &&
      !hasIllegalCharacters(value)
    );
  }

  const handleChange = (e) => {
    setState({
      value: e.target.value,
      valid:
        type === 'password'
          ? validatePassword(e.target.value)
          : e.target.value === confirmationField,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleOnBlur = (e) => {
    if (e.target.value.trim() !== '') {
      if (type === 'password') {
        setError(!validatePassword(e.target.value));
      } else setError(e.target.value !== confirmationField);
    }
  };
  const theme = useTheme();
  const greenShade = theme.palette.mode === 'dark' ? '#99FF99' : 'green';

  return (
    <>
      <TextField
        id={id}
        label={label}
        aria-label={label}
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange}
        onBlur={handleOnBlur}
        error={error}
        helperText={error ? errorText : ''}
        {...otherProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {type === 'password' && (
        <Grid container spacing={0}>
          <ValidationCheckList
            color={state.value.length > 7 ? greenShade : fontColor}
          >
            Length is at least 8 characters.
          </ValidationCheckList>
          <ValidationCheckList
            color={/[a-z]/.test(state.value) ? greenShade : fontColor}
          >
            Must contain at least one lowercase letter.
          </ValidationCheckList>
          <ValidationCheckList
            color={/[A-Z]/.test(state.value) ? greenShade : fontColor}
          >
            Must contain at least one uppercase letter.
          </ValidationCheckList>
          <ValidationCheckList
            color={/\d/.test(state.value) ? greenShade : fontColor}
          >
            Must contain at least one number.
          </ValidationCheckList>
          <ValidationCheckList
            color={hasSpecialCharacter(state.value) ? greenShade : fontColor}
          >
            Must contain at least one special character.
          </ValidationCheckList>
        </Grid>
      )}
    </>
  );
}
PasswordField.propTypes = {
  confirmationField: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
};

PasswordField.defaultProps = {
  type: 'text',
  confirmationField: undefined,
  errorText: undefined,
  id: undefined,
  label: undefined,
};

export default PasswordField;
