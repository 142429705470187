import React from 'react';
import { Link, Alert } from '@mui/material';

// Check if window is defined (so if in the browser or in node.js).

const Unauthorized = () => {
  const path = window.location.pathname;

  return (
    <div
      style={{
        height: '50vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Alert
        severity="warning"
        style={{
          padding: '15px',
        }}
      >
        <b>{`No access to ${path}`}</b> <br />
        You do not have access to this page. If you believe this is an error,
        please <Link href="mailto:{SUPPORT_EMAIL}">contact support</Link>.
      </Alert>
    </div>
  );
};

export default Unauthorized;
