import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import DownloadButton from '../QuickSelectIcons/DownloadButton/DownloadButton';
import PdfDialog from '../PdfDialog/PdfDialog';
import GraphDialogButton from '../QuickSelectIcons/GraphDialogButton/GraphDialogButton';
import ExternalLinkDialog from '../CustomDialog/ExternalLinkDialog';

const DetailsButtonGroup = ({ asset, buttons }) => {
  const { assetMasterContent } = asset;
  return (
    <Grid container>
      {buttons.includes('download') && (
        <DownloadButton asset={asset} component={Button} size="small" />
      )}
      {buttons.includes('pdfviewer') && (
        <PdfDialog
          asset={asset}
          component={!assetMasterContent.url ? ExternalLinkDialog : Button}
          size="small"
        />
      )}
      {buttons.includes('graph') && (
        <GraphDialogButton asset={asset} component={Button} size="small" />
      )}
    </Grid>
  );
};

DetailsButtonGroup.propTypes = {
  asset: PropTypes.object.isRequired,
  buttons: PropTypes.array,
};

DetailsButtonGroup.defaultProps = {
  buttons: ['download', 'pdfviewer', 'graph'],
};

export default DetailsButtonGroup;
