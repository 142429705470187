import { ListAlt, ViewModule } from '@mui/icons-material';
import { Button, Hidden, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CARD_VIEW, LIST_VIEW } from '../../../../auth/functions/defaultView';
import { setDefaultView } from '../../../models/otmmModel/actions/otmmSettingsAction';
import OtmmSettingsIconButton from '../../OtmmSettingsIconButton/OtmmSettingsIconButton';

const ActionOptions = ({ defaultViewState }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const dispatch = useDispatch();
  const [view, setView] = useState(defaultViewState);

  const changeView = () => {
    if (view === LIST_VIEW) {
      setView(CARD_VIEW);
    } else setView(LIST_VIEW);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) dispatch(setDefaultView(view));

    return () => {
      mounted = false;
    };
  }, [view, dispatch]);
  return (
    <>
      <Hidden mdDown>
        <Button
          name={view}
          sx={{
            color: isDark ? 'primary.contrastText' : 'primary.main',
            backgroundColor: 'background.paper',
          }}
          value="settings"
          aria-label="Settings button"
          onClick={changeView}
          startIcon={view === CARD_VIEW ? <ListAlt /> : <ViewModule />}
        >
          {view === CARD_VIEW ? 'List View' : 'Card View'}
        </Button>
      </Hidden>
      <OtmmSettingsIconButton />
    </>
  );
};

ActionOptions.propTypes = {
  defaultViewState: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  totalResults: state.otmmState.pagination.totalResults,
  keywords: state.otmmState.otmmParams.keywords,
  selectedFilters: state.otmmState.filters.selected,
  searchTerm: state.otmmState.otmmParams.term,
  activePage: state.otmmState.pagination.activePage,
  currentPage: state.otmmState.pagination.currentPage,
  sortId: state.otmmState.sort.id,
  sortOrder: state.otmmState.sort.order,
  pageLimit: state.otmmState.pagination.pageLimit,
  searchName: state.otmmState.otmmParams.name,
  defaultViewState: state.otmmSettings.defaultView,
});

export default connect(mapStateToProps)(ActionOptions);
