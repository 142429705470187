import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Notifications, NotificationsActive } from '@mui/icons-material';
import Pop from '../../pop/Pop';
import Restricted from '../../../../../components/Restricted/Restricted';
import { USERS } from '../../../constants/constants';

const NotificationIconButton = ({
  asset,
  classes,
  cardDetails,
  isSubscribed,
  toggleSubscription,
}) => {
  const { assetId } = asset;
  return (
    <div>
      <Restricted userGroups={[USERS]}>
        <Pop content={isSubscribed ? `Don't notify me` : `Notify me`}>
          <IconButton
            classes={{ root: classes.contrastColor }}
            aria-label="add to notifications"
            // title="Subscription"
            style={isSubscribed ? { color: cardDetails.color } : null}
            onClick={() => toggleSubscription(assetId)}
            size="large"
          >
            {isSubscribed ? <NotificationsActive /> : <Notifications />}
          </IconButton>
        </Pop>
      </Restricted>
    </div>
  );
};

NotificationIconButton.propTypes = {
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cardDetails: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscription: PropTypes.func.isRequired,
};

export default NotificationIconButton;
