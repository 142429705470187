import React from 'react';
import PropTypes from 'prop-types';
import { METADATA_FIELD, METADATA_TABLE } from '../../constants/assetFields';
import CreateField from './CreateField';
import CreateTableFields from './CreateTableFields';

const CreateFields = ({ fields, values, updateValues }) =>
  Object.keys(fields).map((key) => {
    const field = fields[key];
    const { type } = field;
    switch (type) {
      case METADATA_FIELD:
        return (
          <div style={{ paddingLeft: 16, paddingRight: 16 }} key={key}>
            <CreateField
              field={field}
              values={values}
              updateValues={updateValues}
            />
          </div>
        );
      case METADATA_TABLE:
        return CreateTableFields(field, updateValues);
      default:
        return null;
    }
  });

CreateFields.propTypes = {
  fields: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default CreateFields;
