import getSecurityPolicyIds from './getSecurityPolicyIds';
import createJobIds from './createJobIds';
import createAsset from './createAsset';

const uploadBasicAssets = async (
  sourceId,
  files,
  modelId,
  modelSecurityPolicyIds,
  values
) => {
  const artifactModelId = 'getArtifactModelId(modelId)';
  const securityPolicyIds =
    modelSecurityPolicyIds || (await getSecurityPolicyIds(artifactModelId));

  // Create an asset for each file
  const jobIds = await createJobIds(files.length);
  const fileJobIds = [];
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const destinationJobId = jobIds.shift();
    // eslint-disable-next-line no-await-in-loop
    await createAsset({
      jobId: destinationJobId,
      modelId,
      values,
      file,
      securityPolicyIds,
    });
    fileJobIds.push(destinationJobId);
  }

  // TODO - May need to link files..?
  // Get the ids of all the assets created
  // const fileAssetIds = await getAssetIdsForJobs(fileJobIds);

  // Link fileAssets to the masterFileAsset
  // We may want to link assets...one day
  //   if (fileAssetIds.length > 0) {
  //     const response = await linkAssets({
  //       masterFileId,
  //       destinationIds: fileAssetIds,
  //     });

  //   return response;
  // }
  return null;
};

export default uploadBasicAssets;
