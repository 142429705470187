import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Home from './features/Home/components/Home';
import {
  fetchGuidedSearches,
  fetchSystemSettings,
} from './features/Home/models/otmmModel/functions/SettingsFunctions';
import Loading from './Loading';

const App = ({ fetchSystemSettings, fetchGuidedSearches }) => {
  const [loading, setLoading] = useState(true);
  useState(async () => {
    // fetch initial system settings
    // TODO fetch for Guided Search and store in redux instead of showing load.
    await fetchSystemSettings();
    await fetchGuidedSearches();
    setLoading(false);
  });
  return loading ? (
    <Loading active={loading} fullscreen>
      Loading...
    </Loading>
  ) : (
    <Home />
  );
};

App.propTypes = {
  fetchSystemSettings: PropTypes.func.isRequired,
  fetchGuidedSearches: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  otmmSettings: state.otmmSettings,
});

const mapDispatchToProps = {
  fetchSystemSettings,
  fetchGuidedSearches,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
