import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Paper } from '@mui/material';
import Pop from '../pop/Pop';

const AssetAvatar = ({ domain, classes, cardDetails, children }) => (
  <Pop id="popover" type="tooltip" content={`${domain.values}`}>
    <Avatar
      variant="rounded"
      className={classes.avatarColor}
      component={Paper}
      elevation={5}
    >
      {children && children}
      {!children && (
        <img
          style={{ height: '75%' }}
          alt="avatar"
          src={domain.values ? cardDetails.avatar : null}
        />
      )}
    </Avatar>
  </Pop>
);

AssetAvatar.propTypes = {
  domain: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cardDetails: PropTypes.object.isRequired,
  children: PropTypes.any,
};

AssetAvatar.defaultProps = {
  children: undefined,
};

export default AssetAvatar;
