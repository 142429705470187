import axios from 'axios';

async function fetchUpFile() {
  const response = await axios
    .get('/up.txt')
    .then((res) => ({ response: res }))
    .catch((err) => err);
  return response;
}

export default fetchUpFile;
