import { Settings } from '@mui/icons-material';
import {
  Alert,
  Button as MatButton,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import { setDefaultView } from '../../models/otmmModel/actions/otmmSettingsAction';
import {
  setOrderBy,
  setPageLimit,
  setSortId,
} from '../../models/otmmModel/functions/updateState';

import { otmm } from '../../../otmm/apis/otmm';

import {
  CRWS_MODEL_ID,
  CRWS_CONTENT_MATURITY,
} from '../../constants/assetFields';

const OtmmSettingsIconButton = ({
  pageLimit,
  sortOrder,
  setOrderBy,
  setSortId,
  setPageLimit,
  setPendingUpdate,
}) => {
  const theme = useTheme();
  const choices = ['25', '50', '100', '150'];
  const sortTypes = ['asc_', 'desc_'];
  const [values, setValues] = useState({
    pageLimit: pageLimit.toString(),
    sortId: CRWS_CONTENT_MATURITY,
    sortOrder,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [message, setMessage] = useState({
    text: '',
    show: false,
  });
  const [sortOptions, setSortOptions] = useState([]);

  const applyChanges = () => {
    setPageLimit(parseInt(values.pageLimit, 10));
    setSortId(values.sortId);
    setOrderBy(values.sortOrder);
    setPendingUpdate(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    const sortOptionsArray = []; // Array of alphabetically ordered sort options

    // This recursive function goes through every metadata_element_list to add all sortable options to the sortOptions array
    const checkForMetadata = (obj) => {
      if (obj.metadata_element_list) {
        const list = obj.metadata_element_list;

        for (let i = 0; i < list.length; i += 1) {
          checkForMetadata(list[i]);
        }
      } else if (obj.sortable) {
        // If the object's sortable value is true, add it to the sort options
        // Need to add alphabetically to make our options menu look nice
        if (sortOptionsArray.length === 0) {
          sortOptionsArray.push({
            key: 0,
            text: obj.name,
            value: obj.id,
          });
        } else {
          let indexToAdd = 0;

          for (let i = 0; i < sortOptionsArray.length; i += 1) {
            if (sortOptionsArray[i].text < obj.name) {
              indexToAdd += 1;
            }
          }

          // Splice will add to the given index while not deleting anything
          sortOptionsArray.splice(indexToAdd, 0, {
            key: sortOptionsArray.length,
            text: obj.name,
            value: obj.id,
          });
        }
      }
    };

    let metadataArray = [];

    const fetchData = async () => {
      await otmm
        .get(`metadatamodels/${CRWS_MODEL_ID}`)
        .then((res) => {
          metadataArray = res.data.metadata_model_resource.metadata_model;
          const metadataList = metadataArray.metadata_element_list.filter(
            (item) => item.id === 'DIMSD.CATEGORY.BOK'
          );

          metadataArray.metadata_element_list = metadataList;
          checkForMetadata(metadataArray);
        })
        .catch((err) => {
          setMessage({
            text: err,
            show: true,
          });
        });
    };

    fetchData();
    setSortOptions(sortOptionsArray); // Set the alphabetical list of sort options in the state
  }, []);

  const id = open ? 'action-settings-popover' : undefined;

  const ActionMenu = () => (
    <div
      style={{
        padding: 15,
        backgroundColor: theme.palette.background.paperAlt,
      }}
    >
      <Typography gutterBottom>
        <b>Sort by</b>
      </Typography>
      <Select
        fullWidth
        labelId="select order by"
        aria-label="select order by"
        name="ID"
        defaultValue="ID"
        variant="outlined"
        value={values.sortId ? values.sortId : ''}
        color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
        style={{ marginBottom: '15px' }}
        onChange={(e) => setValues({ ...values, sortId: e.target.value })}
      >
        {sortOptions.map((name, index) => (
          <MenuItem id={index} key={name.key} value={name.value}>
            {name.text}
          </MenuItem>
        ))}
      </Select>
      <Typography gutterBottom>
        <b>Order By</b>
      </Typography>
      <RadioGroup
        aria-label="Order by"
        value={values.sortOrder}
        onChange={(e) => setValues({ ...values, sortOrder: e.target.value })}
      >
        {sortTypes.map((order, index) => (
          <FormControlLabel
            value={order}
            id={index}
            key={order}
            aria-label={order}
            control={
              <Radio
                color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
              />
            }
            label={order === 'asc_' ? 'Ascending' : 'Descending'}
          />
        ))}
      </RadioGroup>
      <Typography gutterBottom>
        <b>Results per page</b>
      </Typography>
      <ToggleButtonGroup
        value={values.pageLimit}
        exclusive
        size="large"
        aria-label="Assets per page"
      >
        {choices.map((choice) => (
          <ToggleButton
            key={choice}
            color="primary"
            variant="outlined"
            onClick={(e) =>
              setValues({ ...values, pageLimit: e.target.textContent })
            }
            value={choice}
            aria-label={choice}
          >
            {choice}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <br /> <br />
      <div>
        <MatButton
          variant="contained"
          aria-label="Apply Changes"
          fullWidth
          onClick={applyChanges}
          sx={{ color: 'primary.light' }}
        >
          Apply Changes
        </MatButton>
      </div>
      {message.show && (
        <Alert
          onClose={() => setMessage({ ...message, show: false })}
          severity="error"
        >
          {message.text}
        </Alert>
      )}
    </div>
  );

  return (
    <div>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Settings />
      </IconButton>
      <Popover
        aria-label="Settings menu"
        anchorEl={anchorEl}
        open={open}
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <ActionMenu />
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  totalResults: state.otmmState.pagination.totalResults,
  keywords: state.otmmState.otmmParams.keywords,
  selectedFilters: state.otmmState.filters.selected,
  searchTerm: state.otmmState.otmmParams.term,
  activePage: state.otmmState.pagination.activePage,
  currentPage: state.otmmState.pagination.currentPage,
  sortId: state.otmmState.sort.id,
  sortOrder: state.otmmState.sort.order,
  pageLimit: state.otmmState.pagination.pageLimit,
  searchName: state.otmmState.otmmParams.name,
});

OtmmSettingsIconButton.defaultProps = {};
OtmmSettingsIconButton.propTypes = {
  pageLimit: PropTypes.number.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setSortId: PropTypes.func.isRequired,
  setPageLimit: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setPageLimit,
  setPendingUpdate,
  setOrderBy,
  setSortId,
  setDefaultView,
})(OtmmSettingsIconButton);
