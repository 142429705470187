import { useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext } from '../components/AuthProvider';
import isAuthResponseValid from '../functions/isAuthResponseValid';

export const GWAC_ONLY_ACCESS = 'GWAC Only Access';
export const GWAC_READ_WRITE = 'GWAC Read Write';
export const CPARS_ONLY_ACCESS = 'CPARS Only Access';
export const CPARS_READ_UNRESTRICTED = 'CPARS Read Unrestricted';
export const CPARS_READ_WRITE_UNRESTRICTED = 'CPARS Read Write Unrestricted';

const useUserInfo = () => {
  const { state } = useContext(AuthContext);
  const {
    authResponse,
    userGroups,
    userSession,
    userInfo,
    lightbox,
    isAuthenticating,
  } = state;

  const userLoggedIn = isAuthResponseValid(authResponse);
  const {
    role_name: roleName = null,
    login_name: loginName = null,
    user_full_name: userFullName = null,
    id: sessionId,
  } = userSession;
  const [accessList, setAccessList] = useState({
    cpars: false,
    gwac: false,
  });
  const getLightbox = () => lightbox;

  const isUserLoggedIn = () => userLoggedIn;

  const getUserLogin = () => loginName;

  const getUserFullName = () => userFullName;

  const getUserInfo = () => userInfo;

  const getUserGroups = () => ({
      userGroups,
      userGroupList: userGroups.map((g) => g.name),
    });

  const getAccessList = () => accessList;

  const isUserGuest = () => roleName === 'Guest';

  const isUserCurator = useCallback(() => roleName === 'Curator', [roleName]);

  const getUserRole = useCallback(() => roleName, [roleName]);

  const isUserAdmin = useCallback(() => roleName === 'Administrator', [roleName]);

  const isUserInGroup = useCallback(
    (groupName) => userGroups.length > 0
        ? userGroups.filter((group) => group.name === groupName).length > 0
        : false,
    [userGroups]
  );

  const hasAllAccess = useCallback(() => isUserAdmin(), [isUserAdmin]);

  const hasAccessToGroup = useCallback(
    (group) => hasAllAccess() || isUserInGroup(group),
    [hasAllAccess, isUserInGroup]
  );

  const hasWritePermission = () => (
      hasAccessToGroup(CPARS_READ_WRITE_UNRESTRICTED) ||
      hasAccessToGroup(GWAC_READ_WRITE)
    );

  useEffect(() => {
    setAccessList({
      cpars: hasAccessToGroup(CPARS_READ_WRITE_UNRESTRICTED),
      gwac: hasAccessToGroup(GWAC_READ_WRITE),
    });
  }, [hasAccessToGroup]);
  return {
    getUserLogin,
    getUserFullName,
    getUserInfo,
    getUserGroups,
    sessionId,
    isUserLoggedIn,
    isUserInGroup,
    isUserAdmin,
    isUserCurator,
    isUserGuest,
    getUserRole,
    hasWritePermission,
    hasAccessToGroup,
    hasAllAccess,
    getAccessList,
    isAuthenticating,
    getLightbox,
  };
};

export default useUserInfo;
