import { getDomainIcon } from '../../components/CRWS/functions/GetDomainTheme';

export default function transformNode(vertex, assetId, graph) {
  const nodeClass = vertex['@class'];

  function getShape() {
    if (vertex.uoiId === assetId) return 'star';
    if (nodeClass === 'Asset') return 'square';
    return 'circle';
  }

  const size = 200;
  const domainTheme = getDomainIcon(vertex.domain || '');
  const isCurrentAsset = assetId === vertex.uoiId;

  return {
    id: vertex['@rid'] || assetId,
    status: vertex.preapprovalStatus,
    type: vertex['@class'],
    importDate: vertex.importDate,
    name: vertex.shortTitle || vertex.longTitle || vertex.uoiId || vertex.name,
    count: vertex.count,
    epoch: vertex.epoch,
    assetId: vertex.uoiId,
    size: isCurrentAsset ? 300 : size,
    color: isCurrentAsset ? 'green' : graph.nodes[vertex['@class']],
    svg: isCurrentAsset ? '' : domainTheme.avatar,
    domain: vertex.domain,
    symbolType: getShape(),
  };
}
