import { RETRIEVED_ALL_USER_INFO } from '../constants/actionTypes';

const retrievedAllUserInfo = (userSession, userInfo, userGroups) => ({
    type: RETRIEVED_ALL_USER_INFO,
    userSession,
    userInfo,
    userGroups,
  });

export default retrievedAllUserInfo;
