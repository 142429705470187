import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Link as MatLink,
  Button,
  Typography,
} from '@mui/material';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import { SITE_NAME, SITE_NAME_ABBR } from '../../constants/constants';

function TermsOfUse() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Typography paragraph>
        Welcome to the {SITE_NAME} ({SITE_NAME_ABBR}) Website (hereafter
        referred to as “{SITE_NAME_ABBR}”). This is a US Department of
        Defense-sponsored Website under contract to SAIC Incorporated (as the
        “Prime Contractor”). By using the {SITE_NAME_ABBR} Website, you agree to
        follow and be bound by the following Terms and Conditions concerning
        your use of the Website. We may revise the Legal Notice and Terms of Use
        Policy at any time without notice. Areas of the Website may have
        different Terms of Use posted. If there is a conflict between the Legal
        Notice and Terms of Use Policy, and the Terms of Use posted for a
        specific area of the Website, the latter shall have precedence with
        respect to your use of that area of the Website.
      </Typography>

      <ol>
        <Typography paragraph>
          <li>
            <b>Privacy Statement</b>
            <br />
            Communications using, or data stored on, this website are not
            private, are subject to routine monitoring, interception, and
            search, and may be disclosed or used for any Federal
            Government-authorized purpose. Content on this website is non-secure
            and subject to the Freedom of Information Act (FOIA). The{' '}
            {SITE_NAME_ABBR} works to ensure that the privacy of individually
            identifiable information provided to or collected by the{' '}
            {SITE_NAME_ABBR} is appropriately maintained. The {SITE_NAME_ABBR}{' '}
            wants to ensure that visitors and users of the {SITE_NAME_ABBR}{' '}
            Website are provided with appropriate information and choice
            mechanisms to make and implement informed decisions on providing
            personally identifiable information. If you would like to find out
            more information on the {SITE_NAME_ABBR} Privacy Policy, click{' '}
            <MatLink
              style={{ cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            >
              here
            </MatLink>
            .
          </li>
        </Typography>

        <Typography paragraph>
          <li>
            <b>Disclaimer</b>
            <br />
            {` The ${SITE_NAME_ABBR} Website is for informational purposes only and the
            information herein is subject to change without notice. The ${SITE_NAME_ABBR}
            does not provide any warranties and specifically disclaims any
            liability in connection with any ${SITE_NAME_ABBR}/non-${SITE_NAME_ABBR} documents,
            publications, tools, databases, or services (hereafter referred to
            as “Products”).`}
            <br />
            <b>Warranties and Disclaimer</b>
            <br />
            {`
              THE OFFICE OF THE UNDER SECRETARY OF DEFENSE FOR RESEARCH AND ENGINEERING (OUSD(R&E)), DEPARTMENT OF DEFENSE (DOD), THE FEDERAL GOVERNMENT,
              ITS THIRD-PARTY SERVICE PROVIDERS, ${SITE_NAME_ABBR.toUpperCase()} CURATION TEAM, AND THE ${SITE_NAME_ABBR.toUpperCase()} PRIME CONTRACTOR DO NOT NECESSARILY ENDORSE, SUPPORT, SANCTION, ENCOURAGE, VERIFY,
              OR AGREE WITH THE COMMENTS, OPINIONS, OR STATEMENTS POSTED ON THE WEBSITE. ANY INFORMATION OR MATERIAL PLACED ONLINE, INCLUDING ADVICE AND OPINIONS,
              ARE THE VIEWS AND RESPONSIBILITY OF THOSE MAKING THE COMMENTS AND DO NOT NECESSARILY REPRESENT THE VIEWS OF THE DOD, OUSD(R&E), THE FEDERAL GOVERNMENT,
              ITS THIRD-PARTY SERVICE PROVIDERS, ${SITE_NAME_ABBR.toUpperCase()}, OR THE ${SITE_NAME_ABBR.toUpperCase()} PRIME CONTRACTOR. ALL DOCUMENTS AND INFORMATION POSTED TO THIS WEBSITE BY ${SITE_NAME_ABBR.toUpperCase()} CURATION TEAM
              OR ITS USERS MUST BE UNCLASSIFIED OR DESIGNATED DISTRIBUTION STATEMENT A: APPROVED FOR PUBLIC RELEASE; DISTRIBUTION UNLIMITED. ${SITE_NAME_ABBR.toUpperCase()} WEBSITE CONTENT
              AND AVAILABLE PRODUCTS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF MERCHANTABLITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FEDERAL GOVERNMENT, DOD, OUSD(R&E), AND THE ${SITE_NAME_ABBR.toUpperCase()} PRIME CONTRACTOR
              ASSUME NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN CONTENT, PRODUCTS OR EXTERNAL CONTENT WHICH IS REFERENCED BY OR LINKED TO THE ${SITE_NAME_ABBR.toUpperCase()} WEBSITE.
              REFERENCES TO CORPORATIONS, AND THEIR SERVICES AND CONTENT, ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AND DO NOT REPRESENT
              AN ENDORSEMENT OF SAME BY THE FEDERAL GOVERNMENT, DOD, OUSD(R&E), OR THE ${SITE_NAME_ABBR.toUpperCase()} PRIME CONTRACTOR. IN NO EVENT SHALL THE FEDERAL GOVERNMENT, DOD, OUSD(R&E),
              OR THE ${SITE_NAME_ABBR.toUpperCase()} AND ITS PRIME CONTRACTOR BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER,
              INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF DAMAGE,
              AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS INFORMATION. THE C${SITE_NAME_ABBR.toUpperCase()} CONTENT AND PRODUCTS
              MAY INCLUDE TECHNICAL OR OTHER INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. THESE CHANGES MAY BE INCORPORATED
              IN UPDATED REVISIONS OF ${SITE_NAME_ABBR.toUpperCase()} CONTENT AND PRODUCTS AT ANY TIME, BUT UNDER NO OBLIGATION BY THE FEDERAL GOVERNMENT, DOD, OUSD(R&E), AND THE ${SITE_NAME_ABBR.toUpperCase()}
              AND ITS PRIME CONTRACTOR.
            `}
          </li>
        </Typography>

        <Typography paragraph>
          <li>
            <b>Use of Website Information</b>
            <br />
            {`You may download, view, copy, and print documents and graphics
              incorporated in these documents (the "Documents") from the
              Website, subject to the following: (a) the Document is limited
              solely for personal, informational, non-commercial purposes, and
              (b) if the Document is identified in the ${SITE_NAME_ABBR} repository
              description as holding a Third-Party copyright, the original
              copyright holder must be cited appropriately, with requests for
              further use expressly granted by the original copyright holder in
              writing.`}
          </li>
        </Typography>

        <Typography paragraph>
          <li>
            <b>Use of Forums and Public Communications</b>
            <br />
            {`"Forum" means a letter to the ${SITE_NAME_ABBR}, its webmaster, the ${SITE_NAME_ABBR} Curation Team or employees of the Federal Government, Department of
Defense, or e-mail function offered as part of this Website experience. You agree to not upload, e-mail, post, publish or otherwise transmit
through a Forum any content that (a) is false or misleading; (b) contains viruses or other contaminating, destructive or altering
features, (c) is defamatory; (d) is harassing or invades another's privacy, or promotes bigotry, racism, hatred or harm against any
group or individual; (e) is obscene; (f) infringes on another's rights, including but not limited to intellectual property rights;
(g) constitutes unsolicited bulk e-mail, "junk e-mail," "Spam," or chain letters; or (h) violates any applicable laws or regulations. All documents and information posted to this website by ${SITE_NAME_ABBR} or its users must be unclassified, or designated Distribution Statement A: Approved for Public Release; Distribution Unlimited. Forums shall be used in a non-commercial manner only. You shall not distribute or otherwise publish any content containing a solicitation of funds, promotion, advertising for goods or services, or other commercial matter. You agree to not solicit other users of the Website to use or join or become members of any commercial online or offline service or organization. Except where expressly authorized by the ${SITE_NAME_ABBR}, you agree to not collect or store personal data about other users. By uploading, e-mailing, posting, publishing, or otherwise transmitting content to any Forum or submitting any content to the ${SITE_NAME_ABBR}, you automatically grant (or warrant that the owner of such rights has expressly granted) the ${SITE_NAME_ABBR} a perpetual, royalty-free, irrevocable, nonexclusive right and license to use, reproduce, modify, adapt, publish, transmit, and distribute such content in any form, medium, or technology now known or later developed. In addition, you warrant that all moral rights in the content have been waived.`}
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Passwords and Security</b>
            <br />
            You are responsible for maintaining the confidentiality of any
            password(s) you are given to access the Website, and are fully
            responsible for all activities that occur under your password(s).
            You agree to notify the {SITE_NAME_ABBR} immediately of any
            unauthorized use of your password(s). The {SITE_NAME_ABBR} is
            concerned about the security of any and all information obtained and
            stored by the {SITE_NAME_ABBR}, and has taken reasonable steps to
            prevent unauthorized access to that information.
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Termination of Use</b>
            <br />
            You agree that the {SITE_NAME_ABBR} may, at its sole discretion and
            at any time, terminate your access to the Website and any account(s)
            you may have in connection with the Website. The {SITE_NAME_ABBR}{' '}
            may monitor access to the Website.
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Third-Party Websites, Content, Products, and Services</b>
            <br />
            The Website provides links to external non-{SITE_NAME_ABBR} Websites
            and access to content, products, and services from third parties,
            including users, advertisers, affiliates, and sponsors of the
            Website. You agree that the {SITE_NAME_ABBR} is not responsible for
            the availability of, and content provided on, third-party Websites.
            You should refer to the policies posted by other Websites regarding
            their privacy and other topics before you use them. You agree that
            the {SITE_NAME_ABBR} is not responsible for third-party content
            accessible through the {SITE_NAME_ABBR} Website, including opinions,
            advice, statements, advertisements, and endorsements, and understand
            that you bear all risks associated with the use of such content. If
            you choose to purchase any products or services from a third party,
            your relationship is directly with the third party. You agree that
            the Federal Government, Department of Defense, the Office of the
            Under Secretary of Defense for Research and Engineering, and the{' '}
            {SITE_NAME_ABBR}
            and its Prime Contractor are not responsible for (a) the quality of
            third-party products or services; and (b) fulfilling any of the
            terms of your agreement with a third-party seller, including
            delivery of products or services and warranty obligations related to
            purchased products or services. You agree that the {
              SITE_NAME_ABBR
            }{' '}
            is not responsible for any loss or damage of any sort you may incur
            from dealing with any third party.
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Limitation of Liability</b>
            <br />
            IN NO EVENT SHALL THE FEDERAL GOVERNMENT, DEPARTMENT OF DEFENSE, THE
            OFFICE OF THE UNDER SECRETARY OF DEFENSE FOR RESEARCH AND
            ENGINEERING, OR THE CRWS-BOK AND ITS PRIME CONTRACTOR BE LIABLE FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES,
            OR DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA, OR USE, INCURRED BY
            YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT,
            ARISING FROM YOUR ACCESS TO, OR USE OF, THE CRWS-BOK WEBSITE OR
            PRODUCTS.
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Indemnity</b>
            <br />
            {`You agree to defend, indemnify and hold harmless the Federal
            Government, Department of Defense, the Office of the Under Secretary
            of Defense for Research and Engineering, and the ${SITE_NAME_ABBR} and its
            Prime Contractor, its officers, directors, employees, and agents from
            and against any and all claims, liabilities, damages, losses, or
            expenses, including reasonable attorneys' fees and costs, arising
            out of, or in any way connected with, your access to or use of the
            ${SITE_NAME_ABBR} Website and Products.`}
          </li>
        </Typography>
        <Typography paragraph>
          <li>
            <b>Applicable Laws</b>
            <br />
            All matters relating to your access to, and use of, the Website
            shall be governed all by applicable U.S. Federal and State laws.
          </li>
        </Typography>
      </ol>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitleWithClose onClose={() => setOpen(false)}>
          Privacy Policy
        </DialogTitleWithClose>
        <DialogContent>
          <PrivacyPolicy />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TermsOfUse;
