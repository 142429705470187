import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';

const createJobId = async (fileName) => {
  const params = new URLSearchParams();
  params.append('file_name', fileName);
  const response = await otmm.post('/jobs/imports', params);
  return response.data.job_handle.job_id;
};

export default createJobId;
