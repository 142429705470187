// Form for having user create their desired new password

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Collapse } from '@mui/material';

import { registrationApi } from '../../../../otmm/apis/otmm';

import {
  PasswordField,
} from '../../CustomFields/CustomFields';
import checkPasswordHistory from '../../../functions/checkPasswordHistory';

const NewPasswordForm = ({ advanceToNextStep, stepsEmail, stepsUsername, stepsVcode }) => {
  const [password, setPassword] = useState({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', valid: false });

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    severity: 'success',
    message: '',
    open: false,
  });

  const handleOnClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    let passChangeSuccessful = false;

    await registrationApi
    .put(
      '/reset-password',
      JSON.stringify({
        code: Number(stepsVcode),
        user: { email: stepsEmail, username: `${stepsUsername.toLowerCase()}`, password: password.value}
      })
    )
    .then(() => {
      passChangeSuccessful = true;
    })
    .catch((err) => {
			checkPasswordHistory(err, setMessage);
    })

    setLoading(false);
    
    if(passChangeSuccessful){
      advanceToNextStep(); // Must do this at the bottom to avoid a memory leak 
    }
  }; 

  const validateForm = () => ( password.value === confirmPassword.value && password.valid && confirmPassword.valid );

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
          <PasswordField
            fullWidth
            required
            id="Password"
            label="Password"
            aria-label="Password"
            variant="outlined"
            type="password"
            state={password}
            setState={setPassword}
            style={{marginTop: '10px', marginBottom: '5px'}}
          />
          <PasswordField
          fullWidth
            required
            id="ConfirmPassword"
            label="Confirm Password"
            aria-label="Confirm Password"
            variant="outlined"
            errorText="Does not match password."
            confirmationField={password.value}
            state={confirmPassword}
            setState={setConfirmPassword}
            style={{marginTop: '10px', marginBottom: '10px'}}
          />

          <Collapse in={message.open} mountOnEnter unmountOnExit>
            <Alert
              severity={message.severity}
              onClose={() => setMessage({ ...message, open: false })}
            >
              {message.message}
            </Alert>
          </Collapse>
  
          <Button
            fullWidth
            variant="contained"
            onClick={handleOnClick}
            disabled={!validateForm()} 
            color="primary"
            style={{ marginTop: '10px', marginBottom: '10px', padding: '8px 0px' }}
          >
            {loading ? <>Loading...</> : <>Update Password</>}
          </Button>
        </form>
    </>
  );
};

NewPasswordForm.propTypes = {
  advanceToNextStep: PropTypes.func.isRequired,
  stepsEmail: PropTypes.string.isRequired,
  stepsUsername: PropTypes.string.isRequired,
  stepsVcode: PropTypes.string.isRequired,
}

export default NewPasswordForm;
