import { otmmUrlEncoded } from '../../otmm/apis/otmm';
import isSuccessfulResponse from './isSuccessfulResponse';

const lockAsset = async (id) => {
  const params = new URLSearchParams();
  params.set('action', 'lock');

  const response = await otmmUrlEncoded.put(`assets/${id}/state`, params);

  return isSuccessfulResponse(id, response);
};

export default lockAsset;
