import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, Alert, Collapse} from '@mui/material';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import clearSearchSuggestions from '../actions/clearSearchSuggestions';
import formatSearchSuggestions from '../functions/formatSearchSuggestions';
import { SEARCH } from '../../../constants/paths';
import './SearchBar.css';
import fetchSearchSuggestions from '../actions/fetchSearchSuggestions';
import { addKeyword } from '../../Home/models/otmmModel/functions/updateState';


const KeywordSearch = ({
  addKeyword,
  searchSuggestions,
  clearSearchSuggestions,
  pendingUpdate
}) => {
  const [keyword, setKeyword] = useState('');
  const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
  const location = useLocation();


  function updateKeywords(pKeyword) {
    if (pKeyword !== '' && !hasInvalidCharacters) {
      if(!hasInvalidCharacters) 
      addKeyword(pKeyword.trim());
      setKeyword('');
    }
  }

  useEffect(() => {
    let mounted = true;
    if (!location.pathname === SEARCH && mounted) {
      setKeyword('');
      clearSearchSuggestions();
    }
    return () => {
      mounted = false;
    };
  }, [clearSearchSuggestions, location, setKeyword]);

  const formattedSearchSuggestions = useMemo(() => formatSearchSuggestions(searchSuggestions), [searchSuggestions]);

  const onSubmit = (event) => {
    event.preventDefault();
    updateKeywords(keyword);
  };

  const onSearchChange = useCallback(
    (e) => {
      if (e && e.target.value && e.target.value.length > 0) {
        fetchSearchSuggestions(e.target.value);
      }

      const invalidCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;


      setHasInvalidCharacters(invalidCharacters.test(e.target.value));
      
      
      setKeyword(e.target.value);

    },
    [setKeyword]
  );

  const handleSelect = (_e, values) => {
    // if (values !== null) updateKeywords();

    if (values && values.trim().length > 0) {
      setKeyword(values);
      updateKeywords(values);
    }
  };
  return (
    <form onSubmit={onSubmit} style={{ padding: 0 }}>
      <Collapse in={hasInvalidCharacters}>
      <Alert severity="error" >Keywords cannot have special characters.</Alert>
      </Collapse>
      <Autocomplete
        id="keywordSearch"
        inputValue={keyword}
        disableClearable
        onChange={handleSelect}
        style={{marginTop: "12px"}}
        clearOnEscape
        options={formattedSearchSuggestions.map((option) => option.title)}
        freeSolo
        disabled={pendingUpdate}
        renderInput={(params) => (
          <TextField
            {...params}
            value={keyword}
            onChange={onSearchChange}
            fullWidth
            type="text"
            variant="outlined"
            label="Keyword Filter"
            error={hasInvalidCharacters}
            placeholder="Enter keyword"
          />
        )}
        
      />
    </form>
  );
};
KeywordSearch.propTypes = {
  keywords: PropTypes.array.isRequired,
  addKeyword: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.array.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    searchSuggestions: state.searchSuggestions,
    isSearching: state.otmmState.loading,
    filters: state.otmmState.filters.allFilters,
    keywords: state.otmmState.otmmParams.keywords,
    pendingUpdate: state.otmmState.pendingUpdate
  });

export default connect(
  mapStateToProps,
  { addKeyword, clearSearchSuggestions }
)(KeywordSearch);
