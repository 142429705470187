import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.light,
  },
}));

const Loading = ({
  children,
  active,
  textAlign,
  ProgressIndicator,
  fullscreen,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  function RenderContent() {
    return (
      <div style={{ textAlign }}>
        <ProgressIndicator
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.text.secondary
                : 'primary',
          }}
        />
        <div>{children}</div>
      </div>
    );
  }

  function RenderFullscreen() {
    return (
      <Backdrop className={classes.backdrop} open={active}>
        <RenderContent />
      </Backdrop>
    );
  }

  return active && fullscreen ? (
    <RenderFullscreen />
  ) : (
    active && <RenderContent />
  );
};

Loading.propTypes = {
  children: PropTypes.any,
  active: PropTypes.any,
  textAlign: PropTypes.string,
  ProgressIndicator: PropTypes.any,
  fullscreen: PropTypes.bool,
};
Loading.defaultProps = {
  children: null,
  fullscreen: false,
  textAlign: 'center',
  active: true,
  ProgressIndicator: CircularProgress,
};

export default Loading;
