import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import { Favorite } from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import Pop from '../../pop/Pop';
import {
  deleteOtmmLightbox,
  addOtmmLightbox,
} from '../../../functions/fetchOtmmLightbox';
import Restricted from '../../../../../components/Restricted/Restricted';
import useUserInfo from '../../../../auth/hooks/useUserInfo';
import setLightbox from '../../../../auth/hooks/actions/setLightbox';
import { USERS } from '../../../constants/constants';

const LightboxButton = ({ assetId, type, ...otherProps }) => {
  const { getLightbox } = useUserInfo();
  const [isFavorite, setFavorite] = useState(false);
  const { assets, id } = getLightbox();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) setFavorite(assets.includes(assetId));
    return () => {
      mounted = false;
    };
  }, [assets, assetId]);

  const toggleFavorites = async () => {
    if (isFavorite) {
      deleteOtmmLightbox(assetId);

      const updatedAssets = assets.filter((value) => value !== assetId);
      dispatch(setLightbox({ updatedAssets, id }));
    } else {
      addOtmmLightbox([assetId]);
      if (!assets.includes(assetId)) assets.push(assetId);
      dispatch(setLightbox({ assets, id }));
    }
    setFavorite(!isFavorite);
  };

  switch (type) {
    case 'button':
      return <Button {...otherProps}>Favorites</Button>;

    default:
      return (
        <Restricted userGroups={[USERS]}>
          <Pop
            content={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
          >
            <IconButton
              classes={
                otherProps.cardColor
                  ? { root: otherProps.cardColor.contrastColor }
                  : null
              }
              onClick={toggleFavorites}
              style={isFavorite ? { color: '#ff0000' } : null}
              size="large"
            >
              <Favorite />
            </IconButton>
          </Pop>
        </Restricted>
      );
  }
};

LightboxButton.propTypes = {
  assetId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

LightboxButton.defaultProps = {
  type: 'icon',
};
const mapStateToProps = (state) => ({
  favoriteIds: state.otmmState.favorites,
});

export default connect(mapStateToProps)(LightboxButton);
