// import { useTheme } from '@mui/material';
import React from 'react';
import DoD from '../img/DoD-Icon-Dark-Simple.png';
import DefaultTitle from '../img/logos/DEBoK_Brain_and_Words.png';
import DefaultLogo from '../img/logos/DEBoK_Words.png';
import WhiteLogo from '../img/logos/DEBoK_Logo-White.png';
import USDRE from '../img/USDR&ELOGO.png';
import BrainImg from '../img/logos/DEBoK_Brain.png';
// import { SITE_NAME_ABBR } from '../features/Home/constants/constants';

const USDRELogo = ({ ...otherProps }) => (
  <a href="https://www.cto.mil/">
    <img
      src={USDRE}
      alt="Department of Defense Logo"
      style={{ height: '100px' }}
      {...otherProps}
    />
  </a>
);

const DoDLogo = ({ ...otherProps }) => (
  <a href="https://www.defense.gov">
    <img
      src={DoD}
      alt="Department of Defense Logo"
      style={{ height: '100px' }}
      {...otherProps}
    />
  </a>
);

// const DefaultCrwsImage = ({ ...otherProps }) => {
//   const theme = useTheme();
//   const isDark = theme.palette.mode === 'dark';

//   return (
//     <img
//       src={isDark ? WhiteLogo : DefaultLogo}
//       alt={`${SITE_NAME_ABBR} Logo`}
//       style={{
//         height: '125px',
//       }
//       }
//       {...otherProps}
//     />
//   );
// };

export { DefaultLogo, WhiteLogo, DefaultTitle, USDRELogo, DoDLogo, BrainImg };
