import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import CRWSDetails from '../../CRWSDetails/CRWSDetails';
import useAssetDetails from '../../../hooks/useAssetDetails';

function CrwsDetailDialog({
  assetId,
  openDetails,
  setOpenDetails,
  crws,
  isSubscribed,
  toggleSubscription,
  searchTerm,
  headers,
  classes,
}) {
  const [fullscreen, setFullscreen] = React.useState(false);
  const [firstOpen, setFirstOpen] = React.useState(false);
  if (openDetails && !firstOpen) setFirstOpen(true);
  const toggleFullscreen = (option) => {
    setFullscreen(option);
  };
  const {
    finish,
    detailsIsSubscribed,
    detailsToggleSubscription,
  } = useAssetDetails({
    assetId,
    isSubscribed,
    toggleSubscription,
  });

  const handleCloseDialog = () => {
    setFullscreen(false);
    setOpenDetails(false);
    finish();
  };

  return (
    <Dialog
      id={assetId}
      open={openDetails}
      onClose={handleCloseDialog}
      fullScreen={fullscreen}
      // onClose={finish}
      fullWidth
      // keepMounted={firstOpen}
      maxWidth="lg"
      scroll="body"
    >
      <CRWSDetails
        toggleHandleClose={handleCloseDialog}
        isFullscreen={fullscreen}
        toggleFullscreen={toggleFullscreen}
        crws={crws}
        isModal
        isSubscribed={detailsIsSubscribed}
        toggleSubscription={detailsToggleSubscription}
        searchTerm={searchTerm}
        headers={headers}
        classes={classes}
      />
    </Dialog>
  );
}

CrwsDetailDialog.propTypes = {
  assetId: PropTypes.string.isRequired,
  openDetails: PropTypes.bool.isRequired,
  crws: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscription: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  headers: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setOpenDetails: PropTypes.func.isRequired,
};

CrwsDetailDialog.defaultProps = {
  searchTerm: undefined,
};

export default CrwsDetailDialog;
