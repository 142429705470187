import { otmm } from '../../otmm/apis/otmm';
import { METADATA_TO_RETURN } from '../constants/assetFields';
import transformAssets from './transformAssets';

const getRelatedAssets = async (id) => {
  try {
    const response = await otmm.get(`assets/${id}/relatedassets`, {
      params: {
        load_type: 'metadata',
        metadata_to_return: METADATA_TO_RETURN.toString(), // preference_id: 'ARTESIA.PREFERENCE.GALLERYVIEW.DISPLAYED_FIELDS',
      },
    });
    return await transformAssets(response.data.assets_resource.asset_list);
  } catch (e) {
    return [];
  }
};

export default getRelatedAssets;
