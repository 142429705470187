import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import initialStateReducer from './initialStateReducer';
import searchSuggestionsReducer from '../features/search/reducers/searchSuggestionsReducer';
import { otmmReducer } from '../features/Home/models/otmmModel/reducers/otmmReducer';
import { otmmSettingsReducer } from '../features/Home/models/otmmModel/reducers/otmmSettingsReducer';
import themeReducer from './themeReducer';
import dialogReducer from './dialogReducer';
import tourReducer from './tourReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    otmmState: otmmReducer,
    otmmSettings: otmmSettingsReducer,
    tour: tourReducer,
    theme: themeReducer,
    dialog: dialogReducer,
    initialState: initialStateReducer,
    searchSuggestions: searchSuggestionsReducer,
  });
