import {
  otmm,
  GUIDED_SEARCH_URL,
  msiftorientdb,
} from '../../../../otmm/apis/otmm';
import transformLink from '../../../msorientdb/functions/transformLink';
import transformNode from '../../../msorientdb/functions/transformNode';
import {
  addAssetGraphData,
  addGuidedSearchOptions,
  setLoading,
  updateSystemSettings,
} from '../actions/otmmSettingsAction';

const EDGE_COLORS_PREF_ID = {
  component: 'GRAPH_DB',
  key: 'GRAPH_DB',
  name: 'EDGE_COLORS',
};

const EDGE_TYPES_PREF_ID = {
  component: 'GRAPH_DB',
  key: 'GRAPH_DB',
  name: 'EDGE_TYPES',
};

const NODE_TYPES_PREF_ID = {
  component: 'GRAPH_DB',
  key: 'GRAPH_DB',
  name: 'NODE_TYPES',
};
const NODE_COLORS_PREF_ID = {
  component: 'GRAPH_DB',
  key: 'GRAPH_DB',
  name: 'NODE_COLORS',
};

function isEqual(prefId, obj) {
  return (
    prefId.component === obj.component &&
    prefId.key === obj.key &&
    prefId.name === obj.name
  );
}

function cleanColors(string) {
  return string.split(`","`).map((color) => {
    const rgb = `rgb(${color.replace(/[/[\]\\"]/g, '')})`;
    return rgb;
  });
}

function cleanTypes(str) {
  return str.split(',');
}

function getMatch(systemSettings, type) {
  return systemSettings
    .filter((settings) => isEqual(settings.pref_data_id, type))
    .map((filteredNode) => type.name.includes('TYPES')
        ? cleanTypes(filteredNode.value)
        : cleanColors(filteredNode.value));
}

export const fetchGuidedSearches = () => async (dispatch) => {
  dispatch(setLoading(true));

  const areaList = [];
  const technologyList = [];

  const res = await otmm.get(`${GUIDED_SEARCH_URL}/`);
  const { options } = res.data;

  options.forEach((opt) => {
    if (!areaList.includes(opt.areaToProtect) && opt.areaToProtect !== '')
      areaList.push(opt.areaToProtect);
    if (
      !technologyList.includes(opt.technicalProcess) &&
      opt.technicalProcess !== ''
    )
      technologyList.push(opt.technicalProcess);
  });

  dispatch(setLoading(false));
  dispatch(
    addGuidedSearchOptions({
      AreasToProtect: areaList,
      TechnicalProcesses: technologyList,
      matches: options,
    })
  );
};

export const fetchSystemSettings = () => async (dispatch) => {
  dispatch(setLoading(true));
  const settings = await otmm.get(`/systemsettings`);
  const {
    system_settings: systemSettings,
  } = settings.data.system_settings_resource;

  const nodeList = getMatch(systemSettings, NODE_TYPES_PREF_ID);

  const nodeColorsList = getMatch(systemSettings, NODE_COLORS_PREF_ID);
  const edgeList = getMatch(systemSettings, EDGE_TYPES_PREF_ID);
  const edgeColorsList = getMatch(systemSettings, EDGE_COLORS_PREF_ID);

  let nodes = {};
  nodeList[0].forEach((type, index) => {
    nodes = { ...nodes, [type]: nodeColorsList[0][index] };
  });

  let edges = {};
  edgeList[0].forEach((type, index) => {
    edges = { ...nodes, [type]: edgeColorsList[0][index] };
  });
  dispatch(updateSystemSettings({ graph: { nodes, edges } }));
  dispatch(setLoading(false));
};

const convertVerticesToNodes = (ogNodes, graph, assetId) => {
  const formattedNodes = [];

  ogNodes.forEach((node) => {
    formattedNodes.push(transformNode(node, assetId, graph));
  });

  return formattedNodes;
};

const convertEdgesToLinks = (ogLinks, graph) => {
  const formattedLinks = [];

  ogLinks.forEach((link) => {
    formattedLinks.push(transformLink(link, graph));
  });
  return formattedLinks;
};

export const addAssetToGraph = (assetId) => async (dispatch, getState) => {
  const { graph } = getState().otmmSettings;
  dispatch(setLoading(true));
  await msiftorientdb.post(`/${assetId}`).then((response) => {
    const { edges, vertices } = response.data.graph;
    dispatch(
      addAssetGraphData({
        [assetId]: {
          links: convertEdgesToLinks(edges, graph),
          nodes: convertVerticesToNodes(vertices, graph, assetId),
        },
      })
    );
  });

  dispatch(setLoading(false));
};

export default fetchSystemSettings;
