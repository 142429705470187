import React from 'react';
import PropTypes from 'prop-types';

import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import { Button, ClickAwayListener } from '@mui/material';
import { Close } from '@mui/icons-material';
import Pop from '../pop/Pop';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
}));

export default function SimpleSlide({ open, onClose, children }) {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.root}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <div>
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Pop content="Close">
                <Button
                  onClick={onClose}
                  endIcon={<Close />}
                  style={{ backdropFilter: 'blur(6px)' }}
                >
                  Close
                </Button>
              </Pop>
            </div>
            <div>{children}</div>
          </div>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

SimpleSlide.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};
