import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../../../../Loading';

const LoadedData = ({
  children,
  loading,
  hasData,
  loadingContent,
  noDataContent,
}) => (
  <>
    <Loading active={loading} color="secondary" fullscreen>
      {loadingContent}
    </Loading>
    {!hasData && !loading && noDataContent}
    {hasData && !loading && children}
  </>
);

LoadedData.defaultProps = {
  children: null,
  hasData: true,
  loadingContent: <div>Retrieving Results...</div>,
  noDataContent: <div>No Data Found</div>,
};

LoadedData.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  hasData: PropTypes.bool,
  loadingContent: PropTypes.any,
  noDataContent: PropTypes.any,
};

export default LoadedData;
