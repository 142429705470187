import {
  FACET_DATE_INTERVAL_RESTRICTION,
  FACET_NUMERIC_RANGE_RESTRICTION,
} from '../constants/facetTypes';

const getFacetMetaProperty = (type, value) => {
  if (type === FACET_DATE_INTERVAL_RESTRICTION) {
    return { dateInterval: value };
  }
  if (type === FACET_NUMERIC_RANGE_RESTRICTION) {
    return { numericRange: value };
  }

  return {};
};

export default getFacetMetaProperty;
