import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  ASSETS,
  FILTERS,
  OTMM_PARAMS,
  PAGINATION,
  SORT,
} from '../../../constants/constants';

import { updateOtmmModel } from '../otmmModel';
import { setPendingUpdate, updateOtmmState } from '../actions/otmmActions';
import { SAVED_SEARCH } from '../../../../../constants/paths';
import { SEARCH_OTMM } from '../../../../../constants/searches';

export const setAssets = (assets) => async (dispatch, getState) => {
  dispatch(
    updateOtmmState(updateOtmmModel({ [ASSETS]: assets }, getState().otmmState))
  );
};

export const setActivePage = (activePage) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const pagination = updateOtmmModel({ activePage }, otmmState.pagination);
  const updateModel = updateOtmmModel({ [PAGINATION]: pagination }, otmmState);
  dispatch(updateOtmmState(updateModel));
};

export const setSelectedFilters = (selectedFilters) => async (
  dispatch,
  getState
) => {
  const { otmmState } = getState();
  const updatedModel = updateOtmmModel(
    {
      [FILTERS]: updateOtmmModel(
        { selected: selectedFilters },
        otmmState.filters
      ),
    },
    otmmState
  );

  dispatch(updateOtmmState(updatedModel));
};

export const clearSelectedFilters = () => async (dispatch) => {
  dispatch(setSelectedFilters([]));
};

export const removeSelectedFilter = (value, selectedFilter) => (
  dispatch,
  getState
) => {
  const { otmmState } = getState();
  const { selected: selectedFilters } = otmmState.filters;
  _.remove(selectedFilter.value, (v) => v.text === value.text);
  if (selectedFilter.value.length === 0) {
    _.remove(
      selectedFilters,
      ({ filter_id: filterId }) => filterId === selectedFilter.filter_id
    );
  }
  const updatedFilters = [...selectedFilters];
  dispatch(setSelectedFilters(updatedFilters));
  dispatch(setPendingUpdate(true));
};
export const setAllFilters = (allFilters) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const filters = updateOtmmModel({ allFilters }, otmmState.filters);
  const updatedModel = updateOtmmModel({ [FILTERS]: filters }, otmmState);
  dispatch(updateOtmmState(updatedModel));
};

export const setKeywords = (keywords) => async (dispatch, getState) => {
  const { otmmState } = getState();

  const otmmParams = updateOtmmModel({ keywords }, otmmState.otmmParams);
  const updatedModel = updateOtmmModel(
    { [OTMM_PARAMS]: otmmParams },
    otmmState
  );

  dispatch(updateOtmmState(updatedModel));
};

export const addKeyword = (keyword) => async (dispatch, getState) => {


  const { otmmState } = getState();
  const { keywords } = otmmState.otmmParams;
  if (keywords.includes(keyword)) {
    return;
  }
  keywords.push(keyword);
  dispatch(setKeywords(keywords));
  dispatch(setActivePage(1));
  dispatch(setPendingUpdate(true));
};

export const removeKeyword = (keyword) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const { keywords } = otmmState.otmmParams;
  const index = keywords.indexOf(keyword);
  if (index > -1) keywords.splice(index, 1);
  dispatch(setKeywords(keywords));
  dispatch(setPendingUpdate(true));
};

export const clearKeywords = () => async (dispatch) => {
  dispatch(setKeywords([]));
};

export const setSearchTerm = (term) => async (dispatch, getState) => {
  const { otmmState } = getState();

  const otmmParams = updateOtmmModel({ term }, otmmState.otmmParams);
  const updatedModel = updateOtmmModel(
    { [OTMM_PARAMS]: otmmParams },
    otmmState
  );
  dispatch(updateOtmmState(updatedModel));
};

export const setSavedSearchId = (savedSearchId) => async (
  dispatch,
  getState
) => {
  const { otmmState } = getState();

  const otmmParams = updateOtmmModel({ savedSearchId }, otmmState.otmmParams);
  const updatedModel = updateOtmmModel(
    { [OTMM_PARAMS]: otmmParams },
    otmmState
  );
  dispatch(updateOtmmState(updatedModel));
};

export const setOtmmCustom = (custom) => async (dispatch, getState) => {
  const { otmmState } = getState();

  const otmmParams = updateOtmmModel({ custom }, otmmState.otmmParams);
  const updatedModel = updateOtmmModel(
    { [OTMM_PARAMS]: otmmParams },
    otmmState
  );
  dispatch(updateOtmmState(updatedModel));
};

export const setOtmmName = (name) => async (dispatch, getState) => {
  const { otmmState } = getState();

  const otmmParams = updateOtmmModel({ name }, otmmState.otmmParams);
  const updatedModel = updateOtmmModel(
    { [OTMM_PARAMS]: otmmParams },
    otmmState
  );
  dispatch(updateOtmmState(updatedModel));
};

export const setTotalResults = (totalResults) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const pagination = updateOtmmModel({ totalResults }, otmmState.pagination);
  const updatedModel = updateOtmmModel({ [PAGINATION]: pagination }, otmmState);
  dispatch(updateOtmmState(updatedModel));
};

export const setCurrentPage = (currentPage) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const pagination = updateOtmmModel({ currentPage }, otmmState.pagination);
  const updateModel = updateOtmmModel({ [PAGINATION]: pagination }, otmmState);
  dispatch(updateOtmmState(updateModel));
};

export const setSearchType = (type) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const otmmParams = updateOtmmModel({ type }, otmmState.otmmParams);
  const updateModel = updateOtmmModel({ [OTMM_PARAMS]: otmmParams }, otmmState);
  dispatch(updateOtmmState(updateModel));
};

export const onSortByChange = (order, id) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const sort = updateOtmmModel({ order, id }, otmmState.sort);
  const updatedModel = updateOtmmModel({ [SORT]: sort }, otmmState);
  dispatch(updateOtmmState(updatedModel));
};

export const setOrderBy = (order) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const sort = updateOtmmModel({ order }, otmmState.sort);
  const updatedModel = updateOtmmModel({ [SORT]: sort }, otmmState);

  dispatch(updateOtmmState(updatedModel));
};

export const setSortId = (id) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const sort = updateOtmmModel({ id }, otmmState.sort);
  const updatedModel = updateOtmmModel({ [SORT]: sort }, otmmState);

  dispatch(updateOtmmState(updatedModel));
};

export const clearKeywordsAndFilters = () => async (dispatch) => {
  dispatch(clearKeywords());
  dispatch(clearSelectedFilters());
};

export const setPageLimit = (pageLimit) => async (dispatch, getState) => {
  const { otmmState } = getState();
  const pagination = updateOtmmModel({ pageLimit }, otmmState.pagination);
  const updateModel = updateOtmmModel({ [PAGINATION]: pagination }, otmmState);
  dispatch(updateOtmmState(updateModel));
};

export const searchByTerm = (term) => async (dispatch) => {
  dispatch(setSearchType(SEARCH_OTMM));
  dispatch(setSearchTerm(term));
  dispatch(clearKeywordsAndFilters());
  dispatch(setOtmmName('Resources'));
  dispatch(setActivePage(1));

  dispatch(setPendingUpdate(true));
};

export const onRunSavedSearch = (id) => async (dispatch) => {
  const history = useHistory();

  dispatch(setPendingUpdate(true));
  history.push(SAVED_SEARCH.replace(':id', id));
};
