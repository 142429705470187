import { otmm } from '../../otmm/apis/otmm';

export default async (lookupDomainId) => {
  const results = await otmm.get(`lookupdomains/${lookupDomainId}`);
  const { data } = results;
  if (data)
    return {
      id: data.lookup_domain_resource.lookup_domain.domainId,
      values: data.lookup_domain_resource.lookup_domain.domainValues,
    };
  return results;
};
