import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import { METADATA_TO_RETURN } from '../constants/assetFields';
import transformAssets from './transformAssets';

async function runSavedSearch(id, limit, sortId, sortOrd) {
  const params = new URLSearchParams();
  params.append('return_search_object', true);
  params.append('load_type', 'metadata');
  params.append('level_of_detail', 'slim');
  params.append('after', 0);
  params.append('limit', limit);
  // params.append(
  //   'preference_id',
  //   'ARTESIA.PREFERENCE.SPREADSHEETVIEW.DISPLAYED_FIELDS'
  // );
  params.append('metadata_to_return', METADATA_TO_RETURN);
  params.append('multilingual_language_code', 'en_US');
  if (sortId !== 'DEFAULT.RELEVANCE') {
    params.append('sort', sortOrd + sortId);
  }

  const response = await otmm.post(`/search/saved/${id}`, params);

  const facets =
    response.data.search_result_resource.search_result
      .facet_field_response_list;

  const selectedFacets =
    response.data.search_result_resource.search_object
      .facet_field_restriction_list;

  const assets = await transformAssets(
    response.data.search_result_resource.asset_list
  );

  const totalResults =
    response.data.search_result_resource.search_result.total_hit_count;

  return { assets, facets, selectedFacets, totalResults };
}

export default runSavedSearch;
