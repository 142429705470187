import { Container } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SecureRoute from '../../../components/SecureRoute/SecureRoute';
import {
  ABOUT,
  ACTIVATE_ACCOUNT,
  ASSET,
  CONTRIBUTE,
  FAVORITES,
  FEEDBACK,
  GLOSSARY,
  HOME,
  NOTIFICATIONS,
  PROFILE,
  RELEASE_PAGE,
  REVIEW,
  SAVED_SEARCH,
  SAVED_SEARCHES_TABLE,
  SEARCH,
  SEARCHVAR,
  SIGNOUT,
  UNAUTHORIZED,
} from '../../../constants/paths';
import Page404 from '../../../pages/Page404';
import Unauthorized from '../../../Unauthorized';
import { TESTERS, USERS } from '../constants/constants';
import Review from '../Review/Review';
import About from './About/About';
import ActivateUser from './ActivateUser/ActivateUser';
import AssetView from './assets/AssetView';
import ContributePage from './Contribute/ContributePage';
import CookieDisclaimer from './CookieDisclaimer/CookieDisclaimer';
import CrwsDialog from './CrwsDialog/CrwsDialog';
import DrawerContainer from './DrawerContainer/DrawerContainer';
import Favorites from './Favorites/Favorites';
import Feedback from './Feedback/Feedback';
import Filters from './filters/Filters';
import Footer from './Footer/Footer';
import Glossary from './Glossary/Glossary';
import Header from './Header/Header';
import './Home.css';
import RunGuidedSearch from './LandingPage/components/GuidedSearch/RunGuidedSearch';
import LandingPage from './LandingPage/LandingPage';
import ReleasePage from './ReleasePage/ReleasePage';
import SavedSearch from './SavedSearch/SavedSearch';
import Search from './Search/Search';
import Signout from './Signout/Signout';
import TourGuide from './tour/TourGuide';
import NotificationSettings from './User/ProfileManagement/Pages/NotificationSettings/NotificationSettings';
import UserSettings from './User/ProfileManagement/Pages/UserSettings/UserSettings';
import ViewSaveSearches from './ViewSaveSearches/ViewSaveSearches';

const Home = () => (
  <div>
    <Header />
    <div
      style={{
        minHeight: '75vh',
        padding: 24,
      }}
    >
      <Container>
        <Switch>
          <Route exact path={ASSET}>
            <AssetView />
          </Route>

          <Route path={SAVED_SEARCH} exact>
            <DrawerContainer menuItems={<Filters />}>
              <SavedSearch />
            </DrawerContainer>
          </Route>

          <Route path={SEARCH} exact>
            <DrawerContainer menuItems={<Filters />}>
              <Search />
            </DrawerContainer>
          </Route>
          <Route path={REVIEW}>
            <Review />
          </Route>

          <Route path={SEARCHVAR}>
            <DrawerContainer menuItems={<Filters />}>
              <RunGuidedSearch />
            </DrawerContainer>
          </Route>

          <Route path={HOME} exact>
            <LandingPage />
          </Route>
          <SecureRoute userGroups={[USERS]} path={FAVORITES} exact>
            <Favorites />
          </SecureRoute>
          <SecureRoute userGroups={[USERS]} path={SAVED_SEARCHES_TABLE} exact>
            <ViewSaveSearches />
          </SecureRoute>

          <SecureRoute userGroups={[USERS]} path={RELEASE_PAGE} exact>
            <ReleasePage />
          </SecureRoute>

          <Route path={CONTRIBUTE} exact>
            <ContributePage />
          </Route>

          <Route path={ABOUT} exact>
            <About />
          </Route>

          <Route path={GLOSSARY} exact>
            <Glossary />
          </Route>

          {/* User Settings */}
          <SecureRoute path={PROFILE} exact>
            <UserSettings />
          </SecureRoute>
          <SecureRoute path={NOTIFICATIONS} exact>
            <NotificationSettings />
          </SecureRoute>

          <Route path={SIGNOUT} exact>
            <Signout />
          </Route>

          <Route path={ACTIVATE_ACCOUNT} exact>
            <ActivateUser />
          </Route>

          <Route path={UNAUTHORIZED} exact>
            <Unauthorized />
          </Route>
          <SecureRoute userGroups={[TESTERS]} path={FEEDBACK} exact>
            <Feedback />
          </SecureRoute>

          <Route path={HOME}>
            <Page404 />
          </Route>
        </Switch>
      </Container>

      <CrwsDialog />
    </div>
    <TourGuide />
    <CookieDisclaimer />
    <Footer color="primary" position="relative" />
  </div>
);

export default Home;
