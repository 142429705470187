import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './DetailsContent.css';
import getRelatedFiles from '../../functions/getRelatedFiles';
import LoadedData from '../loadedData/LoadedData';
import Tabs from './Tabs';

const DetailsContent = ({ asset, children, searchTerm, color }) => {
  const {
    assetId,
    assetMasterContent,
    assetSupportingContent = [],
    copyright,
  } = asset;

  const [loading, setLoading] = useState(false);
  const [supportingContent, setSupportingContent] = useState([]);
  const masterContent = useMemo(() => _.isEmpty(assetMasterContent) ? undefined : assetMasterContent, [assetMasterContent]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (mounted) {
        setLoading(true);
        const relatedFiles = await getRelatedFiles(assetId);
        setSupportingContent([...assetSupportingContent, ...relatedFiles]);
        setLoading(false);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [assetId, assetSupportingContent]);

  const hasFiles = useMemo(() => masterContent || supportingContent.length > 0, [masterContent, supportingContent.length]);

  return (
    <LoadedData loading={loading}>
      {hasFiles ? (
        <Tabs
          color={typeof color === 'object' ? color[500] : color}
          hasFiles={hasFiles}
          masterContent={masterContent}
          supportingContent={supportingContent}
          copyright={copyright}
          searchTerm={searchTerm}
        >
          {children}
        </Tabs>
      ) : (
        <Tabs>{children}</Tabs>
      )}
    </LoadedData>
  );
};

DetailsContent.propTypes = {
  asset: PropTypes.object.isRequired,
  children: PropTypes.any,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  searchTerm: PropTypes.string,
};

DetailsContent.defaultProps = {
  color: '',
  children: undefined,
  searchTerm: undefined,
};

export default DetailsContent;
