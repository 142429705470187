import React from 'react';
import PropTypes from 'prop-types';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ContactForm from '../../ContactPage/ContactForm';
import CustomDialog from '../../CustomDialog/CustomDialog';

function ReportAProblem({ asset, classes }) {
  const { assetId } = asset;
  return (
    <div>
      <CustomDialog
        button
        className={classes.button}
        maxWidth="md"
        variant="text"
        startIcon={<ReportProblemIcon />}
        title="Report a Problem"
        style={{ marginRight: '15px' }}
        label="Report a Problem"
      >
        <ContactForm
          fullWidth
          variant="outlined"
          subject={`Problem with resource: ${assetId}`}
          body="This resource has a problem with: "
        />
      </CustomDialog>
    </div>
  );
}

ReportAProblem.propTypes = {
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ReportAProblem;
