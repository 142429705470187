import { otmm } from '../../otmm/apis/otmm';
import {
  FETCH_SEARCH_SUGGESTIONS_REQUEST,
  FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  FETCH_SEARCH_SUGGESTIONS_FAILURE,
} from '../constants/actionTypes';

const fetchSearchSuggestionsRequest = () => ({
    type: FETCH_SEARCH_SUGGESTIONS_REQUEST,
  });

const fetchSearchSuggestionsSuccess = (searchSuggestions) => ({
    type: FETCH_SEARCH_SUGGESTIONS_SUCCESS,
    payload: searchSuggestions,
  });

const fetchSearchSuggestionsFailure = (error) => ({
    type: FETCH_SEARCH_SUGGESTIONS_FAILURE,
    error,
  });

const fetchSearchSuggestions = (inputString) => async (dispatch) => {
  dispatch(fetchSearchSuggestionsRequest);
  try {
    const response = await otmm.get(
      `/search/text/suggestions?input=${inputString}`
    );

    dispatch(
      fetchSearchSuggestionsSuccess(
        response.data.search_suggestion_result_resource.search_suggestion_result
          .suggestion_list
      )
    );
  } catch (error) {
    dispatch(fetchSearchSuggestionsFailure(error));
  }
};

export default fetchSearchSuggestions;
