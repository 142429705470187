import getRelatedAssets from './getRelatedAssets';

const getRelatedFiles = async (assetId) => {
  const assets = await getRelatedAssets(assetId);
  return assets.map((asset) => ({
    ...asset.assetMasterContent,
    assetId: asset.assetId,
  }));
};

export default getRelatedFiles;
