import {
  METADATA_FIELD,
  METADATA_TABLE,
  METADATA_TABULAR_FIELD,
} from '../constants/assetFields';
import fetchDomain from './fetchDomain';

const arrayToObject = (array) =>
  array.reduce((obj, field) => {
    const objcpy = { ...obj };
    objcpy[field.id] = field;
    return objcpy;
  }, {});

export const getDomainField = async (field, pValues) => {
  const { domain_id: domainId, id } = field;
  let value = '';
  if (pValues) {
    value = pValues[id];
  }

  const { values } = await fetchDomain(domainId);
  // Verify default value is found in the array.
  const isFound = pValues
    ? values.find((val) => val.display_value.toLowerCase() === value.toLowerCase())
    : undefined;

  return { ...field, values, value: isFound ? isFound.field_value.value : '' };
};

const convertFields = async (field, values) => {
  const { domained, id } = field;
  let value = '';
  if (values) {
    value = values[id];
  }
  if (domained) return getDomainField(field, values);
  return { ...field, value };
};

const convertTable = async (field, values) => {
  const { metadata_element_list: metadataElementList, id, name, type } = field;
  const tables = Promise.all(
    metadataElementList.map((element) => {
      const { domained, id } = element;

      let value = '';
      if (values) {
        value = values[id];
      }
      if (domained) return getDomainField(element, values);
      return { ...element, value };
    })
  );

  return { id, name, type, fields: arrayToObject(await tables) };
};

const transformFields = async (fields, options) => {
  const values = options ? options.defaultValues : undefined;

  if (fields && fields.map.length > 0) {
    const updatedFields = Promise.all(
      fields.map((field) => {
        const { type } = field;

        switch (type) {
          case METADATA_FIELD:
            return convertFields(field, values);
          case METADATA_TABLE: {
            return convertTable(field, values);
          }
          case METADATA_TABULAR_FIELD:
            return convertFields(field, values);

          default:
            return { ...field, value: '' };
        }
      })
    );
    const updated = await updatedFields;

    let tableFields = {};

    updated
      .filter((field) => field.type === METADATA_TABLE)
      .forEach((field) => {
        tableFields = { ...tableFields, ...field.fields };
      });

    const tempFields = arrayToObject(
      updated.filter((field) => field.type !== METADATA_TABLE)
    );

    return {
      defaultFields: arrayToObject(updated),
      defaultValues: { ...tableFields, ...tempFields },
    };
  }

  return null;
};

export default transformFields;
