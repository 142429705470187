import { HelpOutlineOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import Banner from '../Banner/Banner';
import PageHeader from '../PageHeader/PageHeader';
import Pop from '../pop/Pop';
import SaveSearchTable from './SaveSearchTable/SaveSearchTable';

function ViewSaveSearches() {
  const [showHelp, setShowHelp] = React.useState(false);
  return (
    <>
      <PageHeader
        title="Save Search"
        action={
          <Pop content="Help">
            <IconButton onClick={() => setShowHelp(!showHelp)} size="large">
              <HelpOutlineOutlined size="medium" />
            </IconButton>
          </Pop>
        }
      />

      <Banner
        open={showHelp}
        icon={<HelpOutlineOutlined />}
        title="Save Search Help"
        handler={() => setShowHelp(!showHelp)}
      >
        <>
          <ul>
            <li>
              <Typography>
                Select Private or Public to choose the type of Save Search.
              </Typography>
            </li>
            <li>
              <Typography>Choose a Saved Search</Typography>
            </li>
            <li>
              <Typography>Click Search to perform the search.</Typography>
            </li>
          </ul>
        </>
      </Banner>

      <SaveSearchTable style={{ hight: '100%' }} />
    </>
  );
}

ViewSaveSearches.propTypes = {};
export default ViewSaveSearches;
