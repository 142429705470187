import { otmm } from '../../otmm/apis/otmm';

const getSavedSearches = async (id) => {
  const response = otmm
    .get(`savedsearches/${id}`)
    .then((res) => res.data.saved_search_resource.saved_search)
    .catch((e) => ({ error: e.response.data.exception_body.http_response_code }));
  return response;
};

export default getSavedSearches;
