import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function CrwsTitle({ asset, oprShort }) {
  const { shortTitle, title } = asset;
  return (
    <>
      <Typography variant="body2" noWrap>
        <b>{shortTitle.value ? shortTitle.value : title.value}</b>
      </Typography>
      <Typography variant="body2" noWrap>
        {shortTitle.value && title.value}
      </Typography>
      <Typography variant="body2">{oprShort.values || ''}</Typography>
      {!oprShort.values && <br />}
      {!shortTitle.value && <br />}
    </>
  );
}

CrwsTitle.propTypes = {
  asset: PropTypes.object.isRequired,
  oprShort: PropTypes.object.isRequired,
};

export default CrwsTitle;
