import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import { useRouteMatch } from 'react-router-dom';
import { ASSET } from '../../../../../constants/paths';

function ModalIcons({
  isModal,
  isSmall,
  isFullscreen,
  toggleFullscreen,
  toggleHandleClose,
}) {
  const routeAsset = useRouteMatch({
    path: ASSET,
    exact: true,
  });

  useEffect(() => {
    let mounted = true;
    if (!routeAsset && mounted) {
      if (isSmall && !isFullscreen) toggleFullscreen(true);
      if (!isSmall && isFullscreen) toggleFullscreen(false);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmall]);

  function getMinimize() {
    if (isModal) {
      if (!isSmall && isFullscreen) {
        return (
          <IconButton
            color="inherit"
            // style={{ flexGrow: '1', display: 'flex' }}
            onClick={() => toggleFullscreen(!isFullscreen)}
            size="large"
          >
            <FilterNoneIcon />
          </IconButton>
        );
      }
      if (!isSmall && !isFullscreen) {
        return (
          <IconButton
            color="inherit"
            // style={{ flexGrow: '1', display: 'flex' }}
            onClick={() => toggleFullscreen(!isFullscreen)}
            size="large"
          >
            <CheckBoxOutlineBlankIcon />
          </IconButton>
        );
      }
    }
    return <></>;
  }

  return (
    <div>
      {getMinimize()}
      <IconButton
        color="inherit"
        // style={{ flexGrow: '1', display: 'flex' }}
        onClick={toggleHandleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}

ModalIcons.propTypes = {
  isModal: PropTypes.bool.isRequired,
  isSmall: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  toggleHandleClose: PropTypes.func.isRequired,
};

export default ModalIcons;
