import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@mui/material';

const CreateTextField = ({ field, values, updateValues, ...otherProps }) => {
  const { id, name } = field;

  const { value } = values[id];
  return (
    <FormControl fullWidth key={id} sx={{ marginBottom: 3 }}>
      <TextField
        key={id}
        label={name}
        {...otherProps}
        value={value}
        onChange={(e) => updateValues(e, field)}
        onBlur={(e) => updateValues(e, field)}
      />
    </FormControl>
  );
};

CreateTextField.propTypes = {
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
};

CreateTextField.defaultProps = {};
export default CreateTextField;
