import axios from 'axios';
import { SIGNOUT } from '../../../constants/paths';
import authStorage from '../../auth/functions/authStorage';
import axiosRequestTransformer from '../../auth/functions/axiosRequestTransformer';
import getOtmmUrl from '../functions/getOtmmUrl';

export const SEARCH_FREQUENCIES_BASE_URL =
  process.env.REACT_APP_SEARCH_FREQUENCIES_BASE_URL;
export const REGISTRATION_BASE_URL = process.env.REACT_APP_REGISTRATION_API;
export const SES_MAILER_BASE_URL = process.env.REACT_APP_SES_MAILER_BASE_URL;
export const MSIFTDATASVC_BASE_URL = process.env.REACT_APP_MSORIENTDB_BASE_URL;
export const GUIDED_SEARCH_URL = process.env.REACT_APP_GUIDED_SEARCH_URL;

const otmmUrlEncoded = axios.create({
  baseURL: getOtmmUrl('/otmmapi/v5/'),
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  transformRequest: axiosRequestTransformer,
});

export { otmmUrlEncoded };

const jsonServer = axios.create({
  baseURL: 'http://localhost:3004/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  transformRequest: axiosRequestTransformer,
});

export { jsonServer };

export const otmm = axios.create({
  baseURL: '/otmmapi/v5/',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: axiosRequestTransformer,
});

const handleError = (err) => {
  const code = err.response.status;
  if (!authStorage.get()) window.location.reload();
  switch (code) {
    case 401: {
      const isGuest =
        authStorage.get().session_resource.session.role_name === 'Guest';
      authStorage.remove();

      if (isGuest) {
        window.location.reload();
      } else {
        window.location = SIGNOUT;
      }
      break;
    }
    case 404: {
      window.location = '/pagenotfound';
      break;
    }
    default: {
      break;
    }
  }
};

otmm.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    handleError(error);
    return Promise.reject(error);
  }
);

export const msiftorientdb = axios.create({
  baseURL: MSIFTDATASVC_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registrationApi = axios.create({
  baseURL: REGISTRATION_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/*
export const registrationApi = axios.create({
  baseURL: 'http://localhost:8080/api/registration/',
  headers: {
    'Content-Type': 'application/json',
  },
});
*/

export const localRegistrationApi = axios.create({
  baseURL: 'http://localhost:8081/api/registration/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default otmm;
