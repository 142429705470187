import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import Pop from '../pop/Pop';

const ApprovalIcon = ({ reviewBoard }) => {
  const { getUserLogin } = useUserInfo();
  const { User } = reviewBoard.values;
  const theme = useTheme();

  const index = User.values.indexOf(getUserLogin());
  // Do not return an icon if the user hasn't done anything with review board.
  if (index === -1) return null;

  return (
    <Pop content="Review of this resource is complete.">
      <CheckIcon
        color="primary"
        sx={{
          color: theme.palette.mode === 'dark' ? 'text.secondary' : 'primary',
        }}
      />
    </Pop>
  );
};

ApprovalIcon.propTypes = {
  reviewBoard: PropTypes.object.isRequired,
};

export default ApprovalIcon;
