import { useState } from 'react';
import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';

const useSubscription = (subscribed) => {
  const [isSubscribed, setSubscribed] = useState(subscribed);

  const toggleSubscription = async (id) => {
    if (isSubscribed) {
      otmm.delete(`/assets/${id}/subscriptions`);
    } else {
      otmm.post(`/assets/${id}/subscriptions`);
    }
    setSubscribed(!isSubscribed);
  };

  return {
    isSubscribed,
    toggleSubscription,
  };
};

export default useSubscription;
