const TOUR_KEY = 'tour';
const tourStorage = {
  get: () => {
    const tourString = localStorage.getItem(TOUR_KEY);
    return tourString === null;
  },
  set: (value) => {
    localStorage.setItem(TOUR_KEY, value);
  },
  remove: () => {
    localStorage.removeItem(TOUR_KEY);
  },
};

export default tourStorage;
