function transformSaveSearchToRows(dataList) {
  const rowList = [];
  dataList.forEach((element) => {
    const row = {
      name: element.name,
      description: element.description,
      id: element.id,
      frequency: element.notificationFrequency,
    };
    rowList.push(row);
  });
  return rowList;
}

export default transformSaveSearchToRows;
