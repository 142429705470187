import {
  Edit,
  ExitToApp,
  Favorite,
  Feedback,
  NotificationsActive,
  Pageview,
  Person,
  RateReview,
} from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  FAVORITES,
  FEEDBACK,
  NOTIFICATIONS,
  PROFILE,
  REVIEW_NOMINATED,
  SAVED_SEARCHES_TABLE,
} from '../../../../../constants/paths';
import useAuth from '../../../../auth/hooks/useAuth';
import useUserInfo from '../../../../auth/hooks/useUserInfo';
import { REVIEWERS, TESTERS } from '../../../constants/constants';

export default () => {
  const { getUserFullName } = useUserInfo();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };
  const history = useHistory();
  return [
    {
      icon: <Person />,
      listItem: true,
      label: (
        <>
          Signed in as <strong> {` ${getUserFullName()}`} </strong>
        </>
      ),
    },
    {
      divider: true,
    },
    {
      icon: <Edit />,
      label: 'My Profile',
      onClick: () => history.push(PROFILE),
    },
    {
      icon: <Favorite />,
      label: 'My Favorites',
      onClick: () => history.push(FAVORITES),
    },
    {
      icon: <Pageview />,
      label: 'My Saved Searches',
      onClick: () => history.push(SAVED_SEARCHES_TABLE),
    },
    {
      icon: <NotificationsActive />,
      label: 'My Notifications',
      onClick: () => history.push(NOTIFICATIONS),
    },
    {
      divider: true,
    },
    {
      icon: <RateReview />,
      restricted: true,
      userGroups: [REVIEWERS],
      label: 'Nominations Under Review',
      onClick: () => history.push(REVIEW_NOMINATED),
    },

    {
      icon: <Feedback />,
      restricted: true,
      userGroups: [TESTERS],
      label: 'Feedback',
      onClick: () => history.push(FEEDBACK),
    },
    {
      divider: true,
    },
    {
      icon: <ExitToApp />,
      label: 'Sign out',
      onClick: handleLogout,
    },
  ];
};
