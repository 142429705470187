import { TYPE_THEME } from '../actions/themeActions';

const themeType = localStorage.getItem('theme')
  ? localStorage.getItem('theme')
  : 'light';

const initialState = {
  mode: themeType,
};

export default (state = initialState, { type, payload: mode }) => {
  switch (type) {
    case TYPE_THEME:
      return { ...state, mode };

    default:
      return state;
  }
};
