import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { connect } from 'react-redux';
import Assets from '../assets/Assets';
import {
  fetchWithRedux,
  fetchSavedSearchRedux,
} from '../../functions/fetchAssets';

import { SAVED_SEARCH } from '../../../../constants/paths';

function SavedSearch({ fetchSavedSearchRedux, fetchWithRedux, pendingUpdate }) {
  const routeSavedSearch = useRouteMatch({
    path: SAVED_SEARCH,
    exact: true,
  });
  useState(() => {
    // It may be a good idea to store the "last search" as another otmmState model and then set it back if the user wants to go back to the last results so that if they are looking at favorites and want to return to the last results those things will not be changed... may be a good idea to have the fetchSub dispatch to an lastOtmmState which copies the current state of otmm and if they hit a back button they can be reassigned to the old state.

    // OR the Saved Search, Current Search, and Favorites could all be independant states and updated accordingly based on which fetch is being called. This may make things a bit more difficult if searching for assets after doing saved search though.

    /** Return results for this saved search using params. */
    // fetchWithRedux();
    // fetchAsset('41b06a1ae76e486d29059b7ba2e4aafff77fd0cf');
    fetchSavedSearchRedux(routeSavedSearch.params.id);
  });

  useEffect(() => {
    let mounted = true;
    if (pendingUpdate && mounted) {
      fetchWithRedux();
    }

    return () => {
      mounted = false;
    };
  }, [pendingUpdate, fetchWithRedux]);

  return <Assets />;
}
SavedSearch.propTypes = {
  fetchSavedSearchRedux: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired,
  fetchWithRedux: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pendingUpdate: state.otmmState.pendingUpdate,
});

export default connect(mapStateToProps, {
  fetchSavedSearchRedux,
  fetchWithRedux,
})(SavedSearch);
