import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Assets from '../assets/Assets';
import { fetchLightbox } from '../../functions/fetchAssets';

function Favorites({ fetchLightbox, pendingUpdate }) {
  useState(async () => fetchLightbox());

  useEffect(() => {
    let mounted = true;
    if (pendingUpdate && mounted) {
      fetchLightbox();
    }

    return () => {
      mounted = false;
    };
  }, [pendingUpdate, fetchLightbox]);
  return (
    <div>
      <Assets />
    </div>
  );
}

Favorites.propTypes = {
  fetchLightbox: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  pendingUpdate: state.otmmState.pendingUpdate,
});

export default connect(mapStateToProps, { fetchLightbox })(Favorites);
