import React from 'react';
import PropTypes from 'prop-types';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useRouteMatch } from 'react-router-dom';
import { Chip, Rating, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import {
  removeSelectedFilter,
  removeKeyword,
} from '../../models/otmmModel/functions/updateState';
import { FAVORITES, REVIEW_NOMINATED } from '../../../../constants/paths';
import { CRWS_CONTENT_MATURITY, USER_RATING } from '../../constants/assetFields';
import getContentMaturityBadge from '../../functions/getContentMaturityBadge';

function FiltersBar({
  filters,
  keywords,
  searchTerm,
  selectedFilters,
  removeKeyword,
  removeSelectedFilter,
  pendingUpdate
}) {
  const routeFavorites = useRouteMatch({
    path: FAVORITES,
    exact: true,
  });

  const routeReviewNominated = useRouteMatch({
    path: REVIEW_NOMINATED,
    exact: true,
  });

  const getFieldName = (filterId) => {
    let value = null;
    filters.forEach((filter) => {
      if (filter.id === filterId) value = filter.label;
    });

    return value;
  };

  const theme = useTheme();

  // Returns a label based on the selectedFilter category
  const generateLabel = (selectedFilter, mapItem) => {
      const id = selectedFilter.filter_id;

      if( getFieldName(id) ){
        if(id === USER_RATING){
          return ( 
              <div style={{ display: 'flex' }}> 
                {getFieldName(id)}: 
                <Rating 
                  name='read-only' 
                  readOnly 
                  value={parseFloat(mapItem.text)} 
                  size='small'
                />
              </div> 
          );
        }

        if(id === CRWS_CONTENT_MATURITY){
          return getContentMaturityBadge(mapItem.text, mapItem.count);
        }

        return `${getFieldName(id)}: ${mapItem.text}`;
      }  
      return mapItem.text;
  };

  return (
    <div style={{ marginBottom: '5px' }} tour="filters-bar">
      {searchTerm && !routeReviewNominated && !routeFavorites && (
        <Chip
          label={`Search term: ${searchTerm}`}
          // size="small"
          style={theme.chip.searchTerm}
        />
      )}
      {keywords.length !== 0 &&
        keywords.map((item, index) => (
          <Chip 
            key={index.toString().concat('Chippy')}
            icon={<VpnKeyIcon />}
            label={item}
            disabled={pendingUpdate}
            style={theme.chip.keyword}
            onDelete={() => {
              removeKeyword(item);
            }}
          />
        ))}
      {selectedFilters.length !== 0 &&
        selectedFilters.reduce((acc, selectedFilter) => {
          acc.push(
            selectedFilter.value.map((v) => (
              <Chip
                key={`${selectedFilter.id}, ${v.text}`}
                style={theme.chip.filter}
                disabled={pendingUpdate}
                label={
                  generateLabel(selectedFilter, v)
                }
                onDelete={() => removeSelectedFilter(v, selectedFilter)}
              />
            ))
          );
          return acc;
        }, [])}
    </div>
  );
}

FiltersBar.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  keywords: PropTypes.array.isRequired,
  removeKeyword: PropTypes.func.isRequired,
  removeSelectedFilter: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired
};

FiltersBar.defaultProps = {
  searchTerm: null,
};
const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  totalResults: state.otmmState.pagination.totalResults,
  keywords: state.otmmState.otmmParams.keywords,
  filters: state.otmmState.filters.allFilters,
  selectedFilters: state.otmmState.filters.selected,
  searchTerm: state.otmmState.otmmParams.term,
  pendingUpdate: state.otmmState.pendingUpdate,
});

export default connect(mapStateToProps, {
  removeKeyword,
  removeSelectedFilter,
})(FiltersBar);
