import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExternalLinkDialog from '../CustomDialog/ExternalLinkDialog';

const useStyles = makeStyles({
  table: {},
});

export default function MetadataTable({ table, tableCellStyle }) {
  const classes = useStyles();
  const { rows, columns } = table;

  const displayRows = (row) => {
    const difference = columns.length - Object.keys(row).length;

    const blankRows = [];
    if (difference > -1)
      for (let index = 0; index < difference + 1; index += 1) {
        const k = index;
        blankRows.push(<TableCell key={k} />);
      }
    return (
      <TableRow key={row.id}>
        {Object.keys(row).map((key) => {
          const value = row[key];

          const isURL =
            value &&
            typeof value === 'string' &&
            (value.includes('http') || value.includes('www'));

          if (key === 'id') return null;
          return (
            <TableCell key={key} style={tableCellStyle}>
              {isURL ? (
                <ExternalLinkDialog tabIndex={0} label={value}>
                  {value}
                </ExternalLinkDialog>
              ) : (
                value
              )}
            </TableCell>
          );
        })}
        {blankRows}
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="metadata table">
        <TableHead>
          <TableRow>
            {columns.map((col) => {
              const { headerName, field } = col;
              return (
                <TableCell key={field}>
                  <b>{headerName}</b>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{rows.map((row) => displayRows(row))}</TableBody>
      </Table>
    </TableContainer>
  );
}

MetadataTable.propTypes = {
  table: PropTypes.object.isRequired,
  tableCellStyle: PropTypes.object,
};

MetadataTable.defaultProps = {
  tableCellStyle: {},
};
