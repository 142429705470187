import { defaultView } from '../../../../auth/functions/defaultView';
import { LOADING } from '../../../constants/constants';
import {
  ADD_DATA_FOR_GRAPH,
  SET_DEFAULT_VIEW,
  UPDATE_SYSTEM_SETTINGS,
  SET_GUIDED_SEARCH_OPTIONS,
} from '../actions/otmmActionTypes';

const initialState = {
  loading: false,
  graphData: {},
  guidedSearch: {},
  defaultView: defaultView.get(),
};

export const otmmSettingsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_SYSTEM_SETTINGS:
      return { ...state, ...payload };
    case LOADING:
      return { ...state, loading: payload };
    case ADD_DATA_FOR_GRAPH: {
      const graphData = { ...state.graphData, ...payload };
      return { ...state, graphData };
    }
    case SET_GUIDED_SEARCH_OPTIONS: {
      const guidedSearch = { ...state.guidedSearch, ...payload };
      return { ...state, guidedSearch };
    }
    case SET_DEFAULT_VIEW: {
      return { ...state, defaultView: payload };
    }

    default:
      return state;
  }
};

export default otmmSettingsReducer;
