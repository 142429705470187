import _ from 'lodash';
import convertToOtmmDateTime from './convertToOtmmDateTime';

const getType = (datatype) => {
  switch (datatype) {
    case 'DATE': {
      return 'dateTime';
    }
    case 'NUMBER': {
      return 'decimal';
    }
    default:
      return 'string';
  }
};

const createOtmmAsset = async ({ modelId, values, securityPolicyIds }) => {
  const metaDataElementList = [];
  Object.keys(values).forEach((key) => {
    if (values[key].value) {
      metaDataElementList.push({
        id: key,
        type: values[key].type,
        ...(typeof values[key].value === 'string' && {
          value: {
            value: {
              type: getType(values[key].dataType),
              value:
                values[key].dataType === 'DATE'
                  ? convertToOtmmDateTime(values[key].value, modelId)
                  : values[key].value,
            },
          },
        }),
        ...(Array.isArray(values[key].value) && {
          values: values[key].value.map((val) => {
            const { value, type } = val;
            return {
              value: {
                type,
                value:
                  type === 'dateTime' ? convertToOtmmDateTime(value) : value,
              },
            };
          }),
        }),
      });
    }
  });

  const asset = JSON.stringify({
    asset_resource: {
      asset: {
        metadata: {
          metadata_element_list: metaDataElementList,
        },
        metadata_model_id: modelId,
        security_policy_list: _.map(securityPolicyIds, (id) => ({
          id,
        })),
      },
    },
  });
  return asset;
};

export default createOtmmAsset;
