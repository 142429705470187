const getTableDataFromTabularField = ({
  fieldValues,
  exclude,
  custom,
  ...otherProps
}) => {
  const tempRows = [];
  const rows = [];

  const columns = [];

  if (!fieldValues) return { rows: tempRows, cols: columns };

  const fields = Object.values(fieldValues);
  if (fields.length > 0) {
    const len = fields[0].values.length;
    for (let i = 0; i < len; i += 1) {
      let obj;
      const tempCol = [];
      fields.forEach((field) => {
        const { name, id, values } = field;
        if (
          (exclude && (typeof exclude === 'object' && exclude.includes(id))) ||
          (typeof exclude === 'string' && id === exclude)
        )
          return;

        const value = values[i];
        if (value) {
          obj = { ...obj, [id]: { name, value } };
          if (custom && custom.field === id) {
            const renderParams = custom;
            if (renderParams)
              tempCol.push({
                field: id,
                headerName: name,
                renderParams,
                ...otherProps,
              });
          } else {
            tempCol.push({ field: id, headerName: name, ...otherProps });
          }
        }
      });
      if (obj) tempRows.push(obj);

      if (tempCol) {
        tempCol.forEach((col) => {
          let found = false;
          columns.forEach((item) => {
            if (item.field === col.field) {
              found = true;
            }
          });
          if (!found) columns.push(col);
        });
      }
    }
  }
  tempRows.forEach((row, index) => {
    let obj = { id: index };
    Object.keys(row).map((key) => {
      const { value } = row[key];
      obj = { ...obj, [key]: value };
      return null;
    });
    rows.push(obj);
  });

  const hasData = rows.length > 0 && columns.length > 0;

  return { rows, columns, hasData };
};

export default getTableDataFromTabularField;
