import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CreateComboBox = ({ field, values, updateValues }) => {
  const { id, values: fieldValues, name } = field;
  const { value } = values[id];

  return (
    <FormControl fullWidth key={`${name}-${id}`} sx={{ marginBottom: 3 }}>
      <FormLabel>{name}</FormLabel>
      <Select
        labelId={`${name}-${id}`}
        id={id}
        // value={values[id].value}
        value={value}
        onBlur={(e) => updateValues(e, field)}
        onChange={(e) => updateValues(e, field)}
      >
        {fieldValues.map((value) => (
          <MenuItem
            value={value.field_value.value}
            key={value.field_value.value}
          >
            {value.display_value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CreateComboBox.propTypes = {
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default CreateComboBox;
