const USERNAME_KEY = 'Username';
export const savedUsername = {
  get: () => localStorage.getItem(USERNAME_KEY),
  set: (view) => {
    localStorage.setItem(USERNAME_KEY, view);
  },
  delete: () => {
    localStorage.removeItem(USERNAME_KEY);
  },
};

export default savedUsername;
