import sessionStorageClient from './sessionStorageClient';

const STORAGE_KEY = 'authResponse';
const authStorage = {
  get: () => {
    const authString = sessionStorageClient.getItem(STORAGE_KEY);
    if (!authString) {
      return authString;
    }
    const auth = JSON.parse(authString);
    return auth;
  },
  set: (authResponse) => {
    sessionStorageClient.setItem(STORAGE_KEY, JSON.stringify(authResponse));
  },
  remove: () => {
    sessionStorageClient.removeItem(STORAGE_KEY);
  },
};

export default authStorage;
