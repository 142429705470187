import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useSubscription from '../../hooks/useSubscription';
import './CRWS.css';

import noThumbnail from './icons/default.jpg';
import controlledAccessThumbnail from './icons/controlledAccess.png';
import useCardColor from '../../hooks/useCardColor';
import CrwsDetailDialog from './components/CrwsDetailDialog';
import RenderAbstract from './components/renderAbstract';
import Subtitle from './components/Subtitle';
import GetDomainTheme from './functions/GetDomainTheme';
import AssetStatus from '../assetStatus/AssetStatus';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import AssetRating from '../AssetRating/AssetRating';
import QuickSelectIcons from '../QuickSelectIcons/QuickSelectIcons';
import AssetAvatar from '../AssetAvatar/AssetAvatar';
import CrwsTitle from '../CrwsTitle/CrwsTitle';

const CRWS = ({ crws: asset, viewingSubscribed, searchTerm }) => {
  const {
    abstract,
    title,
    subscribed,
    assetId,
    domains,
    controlledAccess,
    contentMaturityReason,
    copyright,
    assetMasterContent,
    contentMaturityColor,
    versions,
    sources,
    dateImported,
    dateLastUpdated,
  } = asset;
  const { OPRShort: oprShort } = sources.values;

  const { DateEffective: dateEffective, DescriptionofVersion: version } =
    versions.values;
  const { Domain: domain } = domains.values;

  const { thumbnail } = assetMasterContent || {};
  const [expanded, setExpanded] = React.useState(false);

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};

  const tabIndex = 0;
  const classes = useCardColor(cardDetails.color);
  const { toggleSubscription, isSubscribed } = useSubscription(subscribed);
  const showCard = (viewingSubscribed && isSubscribed) || !viewingSubscribed;
  const [openDetails, setOpenDetails] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDetails(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const headers = {
    delimiter: <br />,
    fields: [{ name: title.name, value: title.value }],
  };

  return (
    <>
      {showCard && (
        <>
          <div
            role="button"
            tabIndex={tabIndex}
            className={classes.card}
            onKeyDown={(e) => {
              if (e.key === 13) {
                handleOpenDialog();
              }
            }}
          >
            <Card raised className={classes.card}>
              <CardActionArea
                onClick={handleOpenDialog}
                className={classes.content}
              >
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    <AssetAvatar
                      domain={domain}
                      classes={classes}
                      cardDetails={cardDetails}
                      elevation={3}
                    />
                  }
                  title={
                    <div style={{ width: '200px' }}>
                      <CrwsTitle
                        asset={asset}
                        headers={headers}
                        oprShort={oprShort}
                      />
                    </div>
                  }
                  action={
                    <VerifiedIcon
                      size={50}
                      reason={contentMaturityReason.value}
                      color={contentMaturityColor.value}
                    />
                  }
                />
                <div style={{ position: 'relative' }}>
                  <CardMedia
                    variant="outlined"
                    title={title.value}
                    className={classes.cardMedia}
                    onClick={handleOpenDialog}
                    tabIndex={0}
                    role="button"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={
                        thumbnail ||
                        (controlledAccess.value === 'Yes'
                          ? controlledAccessThumbnail
                          : noThumbnail)
                      }
                      alt="Thumbnail"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        flexShrink: '0',
                      }}
                    />
                  </CardMedia>{' '}
                  <div
                    style={{
                      position: 'absolute',
                      left: 10,
                      top: 0,
                    }}
                  >
                    <AssetStatus
                      dateImported={dateImported}
                      dateLastUpdated={dateLastUpdated}
                      cardDetails={cardDetails}
                    />
                  </div>
                </div>
                <CardContent className={classes.content}>
                  <Subtitle dateEffective={dateEffective} version={version} />
                  <Typography variant="caption">
                    Copyright: {copyright.value}
                  </Typography>
                </CardContent>
              </CardActionArea>

              <CardActions className={classes.content}>
                <AssetRating asset={asset} classes={classes} />
              </CardActions>
              <CardActions disableSpacing className={classes.content}>
                <QuickSelectIcons
                  asset={asset}
                  classes={classes}
                  cardDetails={cardDetails}
                  isSubscribed={isSubscribed}
                  toggleSubscription={toggleSubscription}
                />

                {abstract.value && (
                  <IconButton
                    className={classes.expand}
                    classes={{ root: classes.contrastColor2 }}
                    title="Expand"
                    onClick={handleExpandClick}
                    size="large"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                )}
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.content}>
                  <RenderAbstract abstract={abstract} classes={classes} />
                </CardContent>
              </Collapse>
            </Card>
          </div>
          <CrwsDetailDialog
            assetId={assetId}
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
            crws={asset}
            isSubscribed={isSubscribed}
            toggleSubscription={toggleSubscription}
            searchTerm={searchTerm}
            headers={headers}
            classes={classes}
          />
        </>
      )}
    </>
  );
};

CRWS.propTypes = {
  crws: PropTypes.object.isRequired,
  viewingSubscribed: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string,
};

CRWS.defaultProps = {
  searchTerm: undefined,
};

export default CRWS;
