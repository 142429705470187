import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, useTheme, Paper } from '@mui/material';

import Split from 'react-split'; // For split panels. Documentation: https://github.com/nathancahill/split/tree/master/packages/splitjs#sizes

import AssetDetailsHeader from './AssetDetailsHeader';
import AssetExpansionDetails from './AssetExpansionDetails';
import PdfViewer from '../PdfViewer/PdfViewer';

import AssetReferencesPane from './AssetReferencesPane';

const AssetDetails = ({ asset }) => {
  const viewType = 'AssetDetailsView';

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md')); // Used for type of JSX returned

  return (
    <>
      <AssetDetailsHeader asset={asset} />

      <Paper square>
        {!isMedium && (
          <Split
            className="split"
            minSize={[600, 400]}
            sizes={[60, 40]}
            gutterSize={10}
            gutterAlign="center"
            style={{ display: 'flex', paddingBottom: '20px' }}
          >
            <div style={{ padding: '5px' }}>
              <PdfViewer asset={asset} viewType={viewType} />
            </div>
            <div style={{ padding: '5px' }}>
              <AssetExpansionDetails
                asset={asset}
                buttonGroupProps={{ buttons: ['download', 'graph'] }}
                detailsProps={{ disableAccordion: true }}
              />
            </div>
          </Split>
        )}

        {isMedium && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              style={{ paddingBottom: '20px', minHeight: '800px' }}
            >
              <PdfViewer asset={asset} viewType={viewType} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              style={{ paddingBottom: '20px' }}
            >
              <AssetExpansionDetails
                asset={asset}
                buttonGroupProps={{ buttons: ['download', 'graph'] }}
                detailsProps={{ disableAccordion: true }}
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AssetReferencesPane asset={asset} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

AssetDetails.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetDetails;
