import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';

import EmailForm from './forms/EmailForm';
import SubmitCodeForm from './forms/SubmitCodeForm';
import NewPasswordForm from './forms/NewPasswordForm';

const ForgotPasswordStepContent = ({
  activeStep,
  advanceToNextStep
}) => {

  const [stepsEmail, setStepsEmail] = useState(''); // Get from EmailForm.js (pass down setter)
  const [stepsUsername, setStepsUsername] = useState(''); // Get from EmailForm.js (pass down setter)
  const [stepsVcode, setStepsVcode] = useState(''); // Get from SubmitCodeForm.js (pass down setter)

  switch (activeStep) {
    // User enters email to request a code for resetting password
    case 0:
      return (
        <>
          <EmailForm 
            advanceToNextStep={advanceToNextStep}
            setStepsEmail={setStepsEmail}
            setStepsUsername={setStepsUsername}
          />
        </>
      );

    case 1:
      // User gets code from email and enters in text field
      return (
        <>
          <SubmitCodeForm 
            advanceToNextStep={advanceToNextStep} 
            stepsEmail={stepsEmail} 
            stepsUsername={stepsUsername} 
            setStepsVcode={setStepsVcode}
          />
        </>
      );

    case 2:
      // User creates the new password
      return (
        <>
          <NewPasswordForm 
            advanceToNextStep={advanceToNextStep} 
            stepsEmail={stepsEmail} 
            stepsUsername={stepsUsername} 
            stepsVcode={stepsVcode} 
          />
        </>
      );

    case 3:
      // User has successfully changed the pass. offer to route to login screen
      return (
        <>
          <Typography align="center" variant="h6">
              Your password has been successfully changed. Please click the button below to login.
          </Typography>
          <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={advanceToNextStep}
          style={{ marginTop: '10px', marginBottom: '10px' }}>
            Login
          </Button>
        </>
      );

    default:
      return <div>unknown step</div>;
  }
};

ForgotPasswordStepContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  advanceToNextStep: PropTypes.func,
};

ForgotPasswordStepContent.defaultProps = {
  advanceToNextStep: undefined
};

export default ForgotPasswordStepContent;