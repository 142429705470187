import React from 'react';
import { Badge, Button, Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

export const LinkButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    border: '1px solid',
    textDecoration: 'none',
    ...theme.typography.body1,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.light
        : theme.palette.primary.main,
    backgroundColor: 'transparent ',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'none',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
}))(Button);

// export const StyledCardHeader = withStyles((theme) => ({
//   root: {
//     backgroundColor: 'transparent',
//     borderBottom: `50px solid ${theme.palette.primary.main}`,
//     fontFamily: 'Quantico',
//   },
// }))(CardHeader);

export const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

export const ReferenceTabs = withStyles((theme) => ({
  root: {
    borderTop: '3px solid #e8e8e8',
    borderRight: '1px solid #e8e8e8',
  },
  indicator: {
    // width: '10',
    backgroundColor: theme.palette.secondary.main,
  },
}))(Tabs);

export const ReferenceTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,

      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
