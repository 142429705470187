import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import Theme from './components/Theme';

function ThemeWrapper({ children, themeMode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme(themeMode)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  themeMode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
});

export default connect(mapStateToProps)(ThemeWrapper);
