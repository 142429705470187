import { useDispatch } from 'react-redux';
import HandleExternalLink from '../../CustomDialog/HandleExternalLink';

const CreateExternalLinkMenuItems = (links) => {
  const dispatch = useDispatch();
  return links.map((link) => {
    const { url, label } = link;
    return {
      label,
      onClick: () =>
        HandleExternalLink({
          href: url,
          title: label,
          dispatch,
        }),
    };
  });
};

export default CreateExternalLinkMenuItems;
