import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import { METADATA_TO_RETURN } from '../constants/assetFields';
import transformAssets from './transformAssets';

async function fetchOtmmSubscriptions() {
  const params = new URLSearchParams();
  params.append('return_search_object', true);
  params.append('load_type', 'metadata');
  params.append('level_of_detail', 'slim');
  params.append('metadata_to_return', METADATA_TO_RETURN);
  let hasError;
  params.append('multilingual_language_code', 'en_US');
  const response = await otmm.post('/search/saved/5', params).catch((e) => {
    hasError = e;
  });

  const assets = hasError
    ? []
    : await transformAssets(response.data.search_result_resource.asset_list);
  return { assets, hasError };
}

export default fetchOtmmSubscriptions;
