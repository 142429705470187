import React, { useState, useCallback, useEffect } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const ClipboardCopyIcon = ({ textToCopy, uniqueId, labelText, component:Component, children, onComplete, setAnchorEl, ...otherProps }) => {
  const [copied, setCopied] = useState(false);
  const [copiedTimeout, setCopiedTimeout] = useState();

  const copyCardURL = useCallback(() => {
    document.getElementById(`text-to-copy-${uniqueId}`).select();
    document.execCommand('copy');
    setCopied(true);
    clearTimeout(copiedTimeout);
    setCopiedTimeout(setTimeout(() => setCopied(false), 5000));
    if(onComplete) onComplete();
  }, [uniqueId, copiedTimeout, setCopied, setCopiedTimeout, onComplete]);

  const handleOnClick = (event) => {
    if(setAnchorEl){
      setAnchorEl(event.currentTarget);
    }

    copyCardURL();
  }

  useEffect(() => () => {
      clearTimeout(copiedTimeout);
    });

  return (
    <>
      <input
        id={`text-to-copy-${uniqueId}`}
        readOnly
        value={textToCopy}
        style={{ position: 'absolute', left: '-9999px' }}
      />

    {
      Component ? 
      <Component 
        {...otherProps} 
        onClick={(event) => {
          handleOnClick(event);
        }}
      >
        {children}
      </Component>:
       <ListItem button onClick={copyCardURL}>
        <ListItemIcon {...otherProps}>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText primary={labelText} />
        <ListItemIcon>
          {copied && <CheckIcon style={{ color: 'green' }} />}
        </ListItemIcon>
      </ListItem>
    }
     
    </>
  );
};

ClipboardCopyIcon.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  uniqueId: PropTypes.string,
  labelText: PropTypes.string,
  component: PropTypes.any,
  onComplete: PropTypes.func,
  children: PropTypes.element,
  setAnchorEl: PropTypes.func
};

ClipboardCopyIcon.defaultProps = {
  uniqueId: uuid(),
  labelText: 'Copy Link',
  component: undefined,
  onComplete: undefined,
  children: undefined,
  setAnchorEl: undefined
};

export default ClipboardCopyIcon;
