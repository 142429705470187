export function separateTermAndKeys(term) {
  let str;
  const keywords = [];
  str = term.replace(/["'()]/g, '');
  str = str.split(' and ');
  if (str.length > 0) {
    for (let index = 1; index < str.length; index += 1) {
      const key = str[index];
      keywords.push(key);
    }
  }
  return {
    searchTerm: str[0],
    keywords,
  };
}

export function cleanString(dirty) {
  return dirty
    .replace(/%/g, '%25')
    .replace(/&/g, '%26')
    .replace(/:/g, '%3A')
    .replace(/"/g, '%22')
    .replace(/\\/g, '%5C')
    .replace(/~/g, '%7E')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/,/g, '%2C')
    .replace(/\$/g, '%24')
    .replace(/#/g, '%23')
    .replace(/\//g, '%2F')
    .replace(/`/g, '%60')
    .replace(/@/g, '%40')
    .replace(/=/g, '%3D')
    .replace(/\?/g, '%3F')
    .replace(/\^/g, '%5E')
    .replace(/{/g, '%7B')
    .replace(/}/g, '%7D')
    .replace(/\|/g, '%7C')
    .replace(/\+/g, '%2B')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')
    .replace(/\[/g, '%5B')
    .replace(/!/g, '%21')
    .replace(/\]/g, '%5D');
}

export default separateTermAndKeys;
