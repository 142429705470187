import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import createJobId from './createJobId';
// import getAssetIdsForJobs from './getAssetIdsForJobs';
import createOtmmAsset from './createOtmmAsset';
import getSecurityPolicyIds from './getSecurityPolicyIds';
import uploadBasicAssets from './uploadBasicAssets';
import createAsset from './createAsset';
import { SITE_NAME_ABBR } from '../constants/constants';

const uploadAsset = async ({
  values,
  files = [],
  masterFile,
  modelSecurityPolicyIds,
}) => {
  const securityPolicyIds =
    modelSecurityPolicyIds ||
    (await getSecurityPolicyIds(values.metadata_model_id));

  let response;
  let assetJobId;

  if (files.length === 0) {
    const data = new FormData();
    data.append(
      'asset_representation',
      new Blob(
        [
          await createOtmmAsset({
            modelId: values.metadata_model_id,
            values,
            securityPolicyIds,
          }),
        ],
        {
          type: 'application/json; charset=utf-8',
        }
      )
    );
    data.append('no_content', true);
    data.append('job_name', `Creating Asset from ${SITE_NAME_ABBR} UI`);

    response = await otmm.post('/assets', data);
    assetJobId = response.data.job_handle.job_id;
  } else if (masterFile !== undefined) {
    // Create the asset for the master file
    assetJobId = await createJobId(masterFile.name);
    response = await createAsset({
      jobId: assetJobId,
      modelId: values.metadata_model_id,
      values,
      file: masterFile,
      securityPolicyIds,
    });
  }

  // Create an asset for each file
  if (files.length > 0) {
    // Source ID was placed with the master file above, but giving a fake value for now.
    const sourceId = Date.now;
    const result = await uploadBasicAssets(
      sourceId,
      files,
      values.metadata_model_id,
      null,
      values
    );
    return result;
  }

  return response;
};

export default uploadAsset;
