import React, { useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import './Filters.css';
import FilterItems from '../filterItems/FilterItems';
import { setSelectedFilters } from '../../models/otmmModel/functions/updateState';

const FilterTree = ({ filters }) => {
  const [collapsedFilterPanels] = useState([]);

  const [filterTopics, setFilterTopics] = React.useState(
    filters.map((filter) => filter.label)
  );

  function handleOnClick(topic) {
    const arr = [...filterTopics];
    const index = filterTopics.indexOf(topic);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(topic);
    }
    setFilterTopics(arr);
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      aria-label="filters list"
    >
      {filters.map((filter) => (
        <TreeItem
          onClick={() => handleOnClick(filter.label)}
          key={filter.id}
          nodeId={filter.label}
          label={`${filter.label} (${filter.values.length})`}
        >
          <FilterItems
            active={!collapsedFilterPanels.includes(filter.id)}
            filter={filter}
          />
        </TreeItem>
      ))}
    </TreeView>
  );
};

FilterTree.propTypes = {
  filters: PropTypes.array.isRequired,
};
FilterTree.defaultProps = {};
const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  isSearching: state.otmmState.loading,
  filters: state.otmmState.filters.allFilters,
});

export default connect(mapStateToProps, { setSelectedFilters })(FilterTree);
