import authStorage from '../functions/authStorage';
import isAuthResponseValid from '../functions/isAuthResponseValid';

const defaultAuthState = () => {
  const prevStorage = authStorage.get();
  if (prevStorage !== null && isAuthResponseValid(prevStorage)) {
    const { session } = prevStorage.session_resource;
    return {
      authResponse: {
        ...prevStorage,
      },
      userGroups: [],
      userInfo: {},
      userSession: session,
      isAuthenticating: true,
      lightbox: {
        assets: [],
        id: null,
      },
    };
  }
  return {
    authResponse: {},
    userGroups: [],
    userInfo: {},
    userSession: {},
    isAuthenticating: true,
    lightbox: {
      assets: [],
      id: null,
    },
  };
};

export default defaultAuthState;
