import {
  FACET_DATE_INTERVAL_RESPONSE,
  FACET_DATE_INTERVAL_RESTRICTION,
  FACET_NUMERIC_RANGE_RESPONSE,
  FACET_NUMERIC_RANGE_RESTRICTION,
  FACET_SIMPLE_RESTRICTION,
} from '../constants/facetTypes';

const getFacetRestrictionType = (filter) => {
  if (filter.type === FACET_DATE_INTERVAL_RESPONSE) {
    return FACET_DATE_INTERVAL_RESTRICTION;
  }
  if (filter.type === FACET_NUMERIC_RANGE_RESPONSE) {
    return FACET_NUMERIC_RANGE_RESTRICTION;
  }

  return FACET_SIMPLE_RESTRICTION;
};

export default getFacetRestrictionType;
