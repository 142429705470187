import { CardContent, Link, Typography, Grid } from '@mui/material';
import React from 'react';
import { DefaultLogo } from '../../../../constants/images';
import { SITE_NAME_ABBR } from '../../constants/constants';
import PageHeader from '../PageHeader/PageHeader';

const Feedback = () => (
  <>
    <PageHeader title={<Typography variant="h5">Feedback</Typography>} />
    <CardContent>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <div>
            <img
              src={DefaultLogo}
              style={{ width: 300 }}
              alt={`${SITE_NAME_ABBR} Logo`}
            />
          </div>
        </Grid>
        <Grid item>
          <Typography>
            <b>Survey Password</b>
          </Typography>
          <code>v1.2testsurvey</code>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            Help us improve the your experience by filling the survey{' '}
            <Link
              href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_5pRCYIWwZqaFtlQ"
              alt="Feedback"
              target="_blank"
              color="text.disabled"
            >
              here.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </>
);
export default Feedback;
