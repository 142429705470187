import { defaultView } from '../../../../auth/functions/defaultView';
import { LOADING } from '../../../constants/constants';
import {
  ADD_DATA_FOR_GRAPH,
  SET_DEFAULT_VIEW,
  UPDATE_SYSTEM_SETTINGS,
  SET_GUIDED_SEARCH_OPTIONS,
} from './otmmActionTypes';

export const updateSystemSettings = (payload) => ({
  type: UPDATE_SYSTEM_SETTINGS,
  payload,
});

export const setLoading = (payload) => ({ type: LOADING, payload });

export const addAssetGraphData = (payload) => ({ type: ADD_DATA_FOR_GRAPH, payload });

export const addGuidedSearchOptions = (payload) => ({ type: SET_GUIDED_SEARCH_OPTIONS, payload });

export const setDefaultView = (payload) => {
  defaultView.set(payload);
  return { type: SET_DEFAULT_VIEW, payload };
};
