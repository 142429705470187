import { createTheme, useTheme } from '@mui/material/styles';

const Theme = (type) => {
  const isLight = type === 'light';
  const primary = '#6D359C';
  const secondary = '#A5E6BA';
  const paperColor = isLight ? '#FEFCFD' : '#414141';
  const backgroundColor = isLight ? '#FEFCFD' : '#414141';
  const selectedColor = isLight ? '#C2B0E3' : '#7046BE'; // #BFC6E8 #3D498F
  const primaryText = isLight ? '#000505' : '#F9FAFD';
  const secondaryText = isLight ? '#181E44' : '#CED3EE';
  const disabledText = isLight ? '#9CA6DD' : '#9CA6DD';
  const hintText = isLight ? '#6D359C' : '#6D359C';
  const popOverBg = isLight ? '#FEFCFD' : '#414141';
  const themeType = type;
  const theme = useTheme();
  const customTheme = {
    palette: {
      mode: themeType,
      primary: {
        main: primary,
        light: '#FEFCFD',
        dark: '#4A4063',
        // contrastText: '#F9FAFD',
      },
      secondary: {
        main: secondary,
        light: '#E3DAF2',
        // dark: '#34205B',
        // contrastText: '#F9FAFD',
      },
      background: {
        default: backgroundColor,
        paper: paperColor,
        // paperAlt: '#A9B3D1',
        paperAlt: popOverBg,
      },

      divider: '#F9FAFD',
      text: {
        primary: primaryText,
        secondary: secondaryText,
        disabled: disabledText,
        hint: hintText,
      },
    },
    components: {
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor: primary,
            color: '#F9FAFD',
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            color: secondaryText,
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            marginLeft: '-11px',
            marginRight: '16px',
            width: '100%',
          },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            margin: 'auto',
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: primary,
            color: primaryText,
            padding: '8px 16px',
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor,
            color: primaryText,
            boxShadow: theme.shadows[3],
            fontSize: '1em',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: isLight ? 'inherit' : primaryText,
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          deletable: {
            '&:hover, &:focus': {
              outline: `1pt solid ${secondary}`,
              boxShadow: theme.shadows[1],
            },
          },
        },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: selectedColor,
              '&:hover': {
                backgroundColor: selectedColor,
                filter: 'brightness(1.1)',
              },
            },
          },
          selected: {},
        },
      },

      // MuiCssBaseline: {
      //   styleOverrides: {
      //     body: {
      //       backgroundImage: `url(${
      //         type === 'light' ? background : backgroundDark
      //       })`,
      //     },
      //   },
      // },
      MuiTreeItem: {
        styleOverrides: {
          content: {
            '&.Mui-selected': {
              backgroundColor: selectedColor,
              '&:hover': {
                backgroundColor: selectedColor,
                filter: 'brightness(1.1)',
              },
            },
          },
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: false,
      },
      MuiTextField: {
        variant: 'outlined',
        margin: 'normal',
      },
      MuiSelect: {
        variant: 'outlined',
      },

      MuiCard: {
        raised: true,
        variant: 'outlined',
      },
    },
    cards: {
      type1: primary,
      type2: '#9AC6C5',
      type3: '#7785AC',
      type4: secondary,
      default: '#ACB0BD',
    },
    accent: {
      blue: '#D0DBF6',
    },
    typography: {
      h1: { fontFamily: 'Quantico' },
      h2: { fontFamily: 'Quantico' },
      h3: { fontFamily: 'Quantico' },
      h4: { fontFamily: 'Quantico' },
      h5: { fontFamily: 'Quantico' },
      h6: { fontFamily: 'Quantico' },

      body1: {
        fontFamily: 'Martel',
        // fontSize: '1rem',
      },
      body2: {
        fontFamily: 'Martel',
        // fontSize: '1rem',
      },
      subtitle2: {
        fontVariant: 'small-caps',
        fontSize: '14px',
      },
    },

    chip: {
      searchTerm: {
        backgroundColor: secondary,
        color: '#F9FAFD',
        margin: '3px',
        marginTop: '5px',
      },
      filter: {
        margin: '3px',
        marginTop: '5px',
      },
      keyword: {
        color: 'inherit',
        margin: '3px',
        marginTop: '5px',
      },
    },
  };

  return createTheme(customTheme);
};
export default Theme;
