import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Autocomplete, MenuItem, Rating, TextField } from '@mui/material';
import { connect } from 'react-redux';

import './Filters.css';
import createFilter from '../../functions/createFilter';
import {
  setSelectedFilters,
  setActivePage,
} from '../../models/otmmModel/functions/updateState';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import {
  CRWS_CONTENT_MATURITY,
  USER_RATING,
} from '../../constants/assetFields';
import getContentMaturityBadge from '../../functions/getContentMaturityBadge';

const FilterSelect = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  setPendingUpdate,
  pendingUpdate,
}) => {
  const [filterValue, setFilterValue] = useState('');

  const getCurrentValues = (filterId) =>
    _.chain(selectedFilters)
      .find({ filter_id: filterId })
      .defaultTo(createFilter(filterId))
      .value().value;

  const isSelected = (filterId, value) => {
    const currentValues = getCurrentValues(filterId, selectedFilters);
    if (currentValues.length > 0) {
      const labels = currentValues.map((v) => v.text);
      return labels.includes(value);
    }
    return false;
  };

  // formattedFacetList returns an array of every facet (filter) that is available for the user to select
  function formattedFacetList() {
    const arr = [];
    filters.forEach((filter) => {
      filter.values.forEach((item) => {
        const value = {
          value: item.value,
          id: filter.id,
          type: filter.type,
          count: item.count,
          label: filter.label,
        };

        if (!isSelected(filter.id, item.value.text)) arr.push(value);
      });
    });
    return arr;
  }

  // updateFacets will update the list of available filters
  // It acceps the facet of the "current" filter that the user just selected
  function updateFacets(facet) {
    if (facet) {
      const currentFilters = [...selectedFilters];
      const currentFilter = _.chain(currentFilters)
        .remove(({ filter_id: id }) => id === facet.id)
        .first()
        .defaultTo(createFilter(facet.id, facet.type))
        .value();
      currentFilter.value.push(facet.value);
      currentFilters.push(currentFilter);
      setSelectedFilters(currentFilters);
      setActivePage(1);
      setPendingUpdate(true);
    }
  }

  // formatMenuItemLabel will determine what kind of label we would like to return
  // I.e., a <Rating /> (for the stars) or a regular text label
  const formatMenuItemLabel = (item) => {
    if (item.id === USER_RATING) {
      return (
        <div style={{ display: 'flex' }}>
          <Rating
            name="read-only"
            readOnly
            value={parseFloat(item.value.text)}
          />
          ({item.count})
        </div>
      );
    }

    if (item.id === CRWS_CONTENT_MATURITY) {
      return getContentMaturityBadge(item.value.text, item.count);
    }

    return (
      <>
        {' '}
        {item.value.text} ({item.count}){' '}
      </>
    );
  };

  return (
    <Autocomplete
      options={formattedFacetList()}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Search Filters"
          fullWidth
          placeholder="Search Filters"
          variant="outlined"
        />
      )}
      aria-label="Filter"
      autoHighlight
      disableClearable
      disabled={pendingUpdate}
      id="combo-box-demo"
      inputValue={filterValue}
      getOptionLabel={(option) => `${option.value.text} (${option.count})`}
      groupBy={(option) => option.label}
      onChange={(event, newValue) => {
        updateFacets(newValue);
      }}
      style={{ marginTop: '10px' }}
      renderOption={(props, option) => {
        const { value, id } = option;
        const { text } = value;
        return (
          <MenuItem onClick={() => updateFacets(option)} key={`${id}${text}`}>
            {formatMenuItemLabel(option)}
          </MenuItem>
        );
      }}
    />
  );
};

FilterSelect.defaultProps = {};

FilterSelect.propTypes = {
  filters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  isSearching: state.otmmState.loading,
  filters: state.otmmState.filters.allFilters,
  selectedFilters: state.otmmState.filters.selected,
  keywords: state.otmmState.otmmParams.keywords,
  pendingUpdate: state.otmmState.pendingUpdate,
});

export default connect(mapStateToProps, {
  setSelectedFilters,
  setPendingUpdate,
  setActivePage,
})(FilterSelect);
