import React from 'react'
import PropTypes from 'prop-types'

import {
    Button,
    Typography,
} from '@mui/material';

const UserLoginForm = ({incrementStep}) => (
    <>
        <Typography align="center" variant="h6" style={{ marginTop: '30px', marginBottom: '10px' }}>
            Your account has been successfully verified! 
        </Typography>
        <Button 
            fullWidth
            color="primary"
            variant="contained"
            style={{ marginTop: '10px', marginBottom: '10px' }}
            onClick={incrementStep} 
        >
            Please click here to login.
        </Button>
    </>
);


UserLoginForm.propTypes = {
    incrementStep: PropTypes.func.isRequired,
};

export default UserLoginForm;