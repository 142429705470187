import { Grid, Link as MatLink, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BrainImg } from '../../../../constants/images';
import { RELEASE_PAGE } from '../../../../constants/paths';
import {
  CURRENT_VERSION,
  SITE_NAME,
  SITE_NAME_ABBR,
} from '../../constants/constants';
import PageHeader from '../PageHeader/PageHeader';
import Pop from '../pop/Pop';

const useStyles = makeStyles(() => ({
  subheadings: {
    fontFamily: 'Martel',
    fontSize: '20px',
    fontWeight: 700,
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '1%',
  },
  colAlign: {
    textAlign: 'center',
  },
}));

function Contribute() {
  const history = useHistory();
  const classes = useStyles();
  const content = (
    <>
      <PageHeader title={`Contribute to ${SITE_NAME_ABBR}`} centered />

      <Grid container spacing={2}>
        <Grid item md={8}>
          <Typography paragraph>
            Thank you for your interest in submitting {SITE_NAME} and related
            technology-focused content to the DEBoK. The success and relevancy
            of the DEBoK is dependent upon the participation and contributions,
            which enable community-wide learning, and drives progression in the
            field of {SITE_NAME} {SITE_NAME_ABBR}.
          </Typography>

          <Typography className={classes.subheadings} h2>
            {' '}
            How to Contribute to DEBoK
          </Typography>
          <Typography>
            <>
              <ol>
                <li>
                  Send your contribution to the DEBoK mailox using the most
                  relevant template.
                </li>
                <li>
                  Acceptable content file types for attaching your submission to
                  the template are Microsoft Word, Microsoft PowerPoint, and
                  Adobe .pdf
                </li>
                <li>
                  [Submissions must contain at a minimum the following
                  information: name, email, phone, organization,
                  article/submission title, article submission author(s),
                  article/submission technical area/domain.]
                </li>
                <li>
                  At this time, only material that is UNCLASSIFIED, DISTRIBUTION
                  A, Approved for Public Release may be submitted.
                </li>
                <li>
                  Your submission will be reviewed; if there are any questions,
                  the DEBoK curation Team will contact you.
                </li>
                <li>
                  Your submission content will be scheduled for review by the
                  DEBoK Steering Committee for addition to the DEBoK repository,
                  and you will be notified by email of the final status of your
                  submission.
                </li>
              </ol>
            </>
          </Typography>
          <Typography>
            We sincerely appreciate your efforts in sharing critical information
            with the larger {SITE_NAME} community.
          </Typography>
          <Typography className={classes.colAlign} paragraph>
            Current Version of {SITE_NAME_ABBR}:{' '}
            <Pop content="Click here for release notes">
              <MatLink
                tabIndex={0}
                component={Link}
                to={RELEASE_PAGE}
                onClick={() => history.push(RELEASE_PAGE)}
              >
                {CURRENT_VERSION}
              </MatLink>
            </Pop>
          </Typography>
        </Grid>
        <Grid md={4} className={classes.imgContainer}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={BrainImg}
            className={classes.logo}
            alt={`${SITE_NAME_ABBR} Logo`}
          />
        </Grid>
      </Grid>
    </>
  );

  return content;
}

export default Contribute;
