import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Divider } from '@mui/material';
import AssetDetailsPane from './AssetDetailsPane';
import DetailsButtonGroup from '../DetailsButtonGroup/DetailsButtonGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    width: 500,
  },
}));

export default function AssetExpansionPanel({
  asset,
  detailsProps,
  buttonGroupProps,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width: '100%' }}>
      <Divider />
      <DetailsButtonGroup asset={asset} {...buttonGroupProps} />
      <AssetDetailsPane asset={asset} {...detailsProps} />
    </div>
  );
}

AssetExpansionPanel.propTypes = {
  asset: PropTypes.object.isRequired,
  detailsProps: PropTypes.object,
  buttonGroupProps: PropTypes.object,
};

AssetExpansionPanel.defaultProps = {
  detailsProps: null,
  buttonGroupProps: null,
};
