import { otmm } from '../../otmm/apis/otmm';
import lockAsset from './lockAsset';
import unlockAsset from './unlockAsset';
import getAsset from './getAsset';
import getAssetMetadata from './getAssetMetadata';
import unlinkAssets from './unlinkAssets';
import uploadBasicAssets from './uploadBasicAssets';

const createAsset = (metadata) =>
  JSON.stringify({
    edited_asset: {
      data: {
        metadata,
      },
    },
  });

const editAsset = async ({ assetId, values, unlinkIds, files, modelId }) => {
  if (unlinkIds && unlinkIds.length > 0) {
    await unlinkAssets({ assetId, destinationIds: unlinkIds });
  }

  if (files && files.length > 0) {
    uploadBasicAssets(assetId, files, modelId);
  }

  const metadata = getAssetMetadata(values, modelId);
  if (await lockAsset(assetId)) {
    try {
      await otmm.patch(`assets/${assetId}`, createAsset(metadata));
    } finally {
      unlockAsset(assetId);
    }
  }

  return getAsset(assetId);
};

export default editAsset;
