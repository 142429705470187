import {
  FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  CLEAR_SEARCH_SUGGESTIONS,
} from '../constants/actionTypes';

const searchSuggestionsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      return action.payload;
    case CLEAR_SEARCH_SUGGESTIONS:
      return [];
    default:
      return state;
  }
};

export default searchSuggestionsReducer;
