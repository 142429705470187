/* 
This function will determine if a user is trying to reset a password to a pass that they have used before.
It does so by looking at the message that is attached to the error that the API returns.
*/
import PropTypes from 'prop-types';

/*
@params
   err - the err that is returned by the failed api call
   setComponent - sets the various components that display the error to the user (snackbar, message, etc)
*/
const checkPasswordHistory = (err, setComponent) => {
  const passwordHistoryError = 'The provided new password was found in the password history for the user';
  const potentialPassError = err.response.data.error;

  if(potentialPassError && potentialPassError.includes(passwordHistoryError)){
    // Set the appropriate error message for the component
    setComponent({
      severity: 'error',
      open: true,
      message: passwordHistoryError
    });
  } else {
    setComponent({
      severity: 'error',
        open: true,
        message:
          err.response.status === 409
            ? 'Password has already been used please choose a different one.'
            : 'Password change failed: please check your password is correct. If problem persists contact administrator.',
    })

    /*
      Leaving this here for now: may need to reformat the message that the else statement uses when setting component

      severity: 'error',
      open: true,
      message: err.response
        ? `${err.response.data.message}`
        : `${err.message} - no response from the server. If problem persists contact administrator.`,
    */
  }
};

checkPasswordHistory.PropTypes = {
  err: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired
};

export default checkPasswordHistory;