import convertToOtmmDateTime from './convertToOtmmDateTime';

const getType = (datatype) => {
  switch (datatype) {
    case 'DATE': {
      return 'dateTime';
    }
    case 'NUMBER': {
      return 'decimal';
    }
    default:
      return 'string';
  }
};

const getAssetMetadata = (values, modelId) => {
  const metaDataElementList = [];
  Object.keys(values).forEach((key) => {
    const isDate = values[key].dataType === 'DATE';
    const isString = isDate || values[key].dataType === 'CHAR';
    const value = values[key].value === '' ? null : values[key].value;
    metaDataElementList.push({
      id: key,
      type: values[key].type,
      ...(isString && {
        value: {
          value: {
            type: getType(values[key].dataType),
            value:
              isDate && value != null
                ? convertToOtmmDateTime(value, modelId)
                : value,
          },
        },
      }),
      ...(Array.isArray(value) && {
        values: value.map((val) => ({
            value: {
              type: 'string',
              value: val,
            },
          })),
      }),
    });
    // }
  });

  return metaDataElementList;
};

export default getAssetMetadata;
