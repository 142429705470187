import React, { useState } from 'react';
import { Description } from '@mui/icons-material';

import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import PdfViewer from '../PdfViewer/PdfViewer';
import Pop from '../pop/Pop';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';

const PdfDialog = ({ children, asset, component, ...otherProps }) => {
  const [open, setOpen] = useState(false);
  const { title } = asset;
  const { assetMasterContent } = asset;
  // Do not display a button for assets without a pdf.
  if (!assetMasterContent.url) return null;

  const handleOpen = () => setOpen(!open);

  const element = () => {
    if (children)
      return (
        <Box
          onClick={() => setOpen(true)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleOpen();
            }
          }}
        >
          {children}
        </Box>
      );
    let result = null;
    switch (component) {
      case IconButton:
        result = (
          <Pop content="View Document">
            <IconButton
              onClick={() => setOpen(true)}
              aria-label="Preview Document"
              {...otherProps}
              size="large"
            >
              <Description />
            </IconButton>
          </Pop>
        );
        break;

      case Button:
        result = (
          <Button
            onClick={() => setOpen(true)}
            aria-label="View Document"
            startIcon={<Description />}
            {...otherProps}
          >
            View Document
          </Button>
        );
        break;

      default:
        break;
    }
    return result;
  };

  return (
    <>
      {element()}
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        {title && title.value && (
          <DialogTitleWithClose onClose={handleOpen}>
            {title.value}
          </DialogTitleWithClose>
        )}
        <DialogContent>
          <PdfViewer asset={asset} />
        </DialogContent>
      </Dialog>
    </>
  );
};
PdfDialog.defaultProps = {
  children: undefined,
  component: IconButton,
};

PdfDialog.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  asset: PropTypes.object.isRequired,
};

export default PdfDialog;
