import { CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { DefaultTitle } from '../constants/images';
import PageHeader from '../features/Home/components/PageHeader/PageHeader';
import { SITE_NAME_ABBR } from '../features/Home/constants/constants';

function Page404() {
  const Logo = DefaultTitle;
  return (
    <>
      <PageHeader title="404: Page Not Found" />
      <CardMedia>
        <div style={{ textAlign: 'center' }}>
          <img
            src={Logo}
            style={{
              minWidth: '200px',
              maxWidth: '400px',
            }}
            alt={`${SITE_NAME_ABBR} Logo`}
          />
        </div>
      </CardMedia>
      <CardContent>
        <Typography variant="h6">
          <b>Page Not Found</b>
        </Typography>
        <Typography paragraph variant="body1">
          The page specified was not found. It could have been moved, is
          temporary unavailable or has been removed. Please check the url for
          errors. If after checking for errors the problem still persists,
          please contact administration.
        </Typography>
      </CardContent>
    </>
  );
}

export default Page404;
