import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, SvgIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

function ValidationCheckList({ children, color }) {
  return (
    <Grid item container>
      <Grid item>
        <SvgIcon fontSize="small" style={{ color }}>
          <CheckIcon />
        </SvgIcon>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ color }}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
}

ValidationCheckList.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default ValidationCheckList;
