export const FACET_DATE_INTERVAL_RESPONSE =
  'com.artesia.search.facet.FacetDateIntervalFieldResponse';
export const FACET_NUMERIC_RANGE_RESPONSE =
  'com.artesia.search.facet.FacetNumericRangeFieldResponse';
export const FACET_SIMPLE_RESPONSE =
  'com.artesia.search.facet.FacetSimpleFieldResponse';
export const FACET_NUMERIC_RANGE_RESTRICTION =
  'com.artesia.search.facet.FacetNumericRangeFieldRestriction';
export const FACET_DATE_INTERVAL_RESTRICTION =
  'com.artesia.search.facet.FacetDateIntervalFieldRestriction';
export const FACET_SIMPLE_RESTRICTION =
  'com.artesia.search.facet.FacetSimpleFieldRestriction';
