import React, { createContext, useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import authReducer from '../hooks/reducers/authReducer';
import handleAuthResponse from '../hooks/funcions/handleAuthResponse';
import checkSession from '../functions/checkSession';
import startAuthentication from '../hooks/actions/startAuthentication';
import defaultAuthState from '../constants/defaultAuthState';

import isAuthResponseValid from '../functions/isAuthResponseValid';

import { otmm } from '../../otmm/apis/otmm';
import retrievedAllUserInfo from '../hooks/actions/retrievedAllUserInfo';
import { getLightboxId } from '../../Home/functions/fetchOtmmLightbox';
import setLightbox from '../hooks/actions/setLightbox';
import { fetchFavoritesId } from '../../Home/functions/fetchAssets';

const AuthContext = createContext();

const AuthProvider = ({ children, navigate }) => {
  const callbackDomain =
    typeof window !== 'undefined'
      ? `${window.location.protocol}//${window.location.host}`
      : 'http://localhost:8000';

  const [state, dispatch] = useReducer(authReducer, defaultAuthState());
  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    callback_domain: callbackDomain,
    navigate,
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue({ ...contextValue, state });
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Verify user is logged-in on AuthProvider mount
  useEffect(() => {
    dispatch(startAuthentication());
    const authResponse = checkSession({ dispatch });
    handleAuthResponse({ dispatch, authResponse });
  }, []);

  const { authResponse } = state;

  const isUserLoggedIn = isAuthResponseValid(authResponse);

  useEffect(() => {
    if (!isUserLoggedIn) return;
    async function fetchUserData() {
      const userSession = await otmm
        .get(`/sessions`)
        .then((response) => response.data.session_resource.session)
        .catch(() => []);

      const userInfo = await otmm
        .get(`/users/me`)
        .then((response) => response.data.user_resource.user)
        .catch(() => ({}));

      const userGroups = await otmm
        .get(`/usergroups/me`)
        .then((response) => response.data.user_groups_resource.user_group_list)
        .catch(() => ({}));

      dispatch(retrievedAllUserInfo(userSession, userInfo, userGroups));
      // Do not search for lightbox if the user is a Guest. Guests do not have access to lightbox.
      dispatch(
        setLightbox({
          id: await getLightboxId(),
          assets: await fetchFavoritesId(),
        })
      );
    }
    fetchUserData();
  }, [authResponse, isUserLoggedIn]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
  navigate: PropTypes.func,
};

AuthProvider.defaultProps = {
  children: null,
  navigate: () => {},
};

export default AuthProvider;
export { AuthContext };
