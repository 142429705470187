const getDateIndex = (dateEffective) => {
  if (dateEffective.values[0] && dateEffective.values.length > 1) {
    let prevDate = 0;
    let currIndex = 0;
    for (let i = 0; i < dateEffective.values.length; i += 1) {
      if (Date.parse(dateEffective.values[i]) > prevDate) {
        currIndex = i;
        prevDate = Date.parse(dateEffective.values[i]);
      }
    }
    return currIndex;
  }
  return 0;
};

export default getDateIndex;
