import React, { useState } from 'react';
import {
  CardContent,
  Card,
  CardHeader,
  Button,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import Pop from '../pop/Pop';
import clearValues from '../../functions/clearValues';
import CreateField from './CreateField';

const CreateTableFields = (field, updateValues) => {
  const DATE = 'DATE';

  const { id, fields, name } = field;

  const [tableValues, setTableValues] = useState();
  const [currentValues, setCurrentValues] = useState({ ...fields });

  const updateCurrentValues = (e, field) => {
    const { id } = field;
    setCurrentValues({
      ...currentValues,
      [id]: {
        ...currentValues[id],
        ...field,
        value: e.target.value,
      },
    });
  };

  const handleAddToTable = () => {
    let tempValues = { ...tableValues };
    let hasValue = false;
    Object.keys(currentValues).forEach((key) => {
      const field = currentValues[key];
      const { value: fValue, id, data_type: dataType } = field;
      const isDate = dataType === DATE;
      const type = isDate ? 'dateTime' : 'string';
      const value = isDate ? `${fValue}T00:00:00` : fValue;

      if (value !== '') hasValue = true;
      tempValues = {
        ...tempValues,
        [id]: {
          ...tempValues[id],
          ...field,
          value:
            tempValues[id] && tempValues[id].value
              ? [...tempValues[id].value, { type, value }]
              : [{ value, type }],
        },
      };
    });
    if (hasValue) {
      // Updating values without the event will merge the data.
      updateValues(tempValues);
      setTableValues(tempValues);
      setCurrentValues(clearValues(currentValues));
    }
  };

  function handleRemoveRow(index) {
    // Remove values at index on all objects in tableValues
    const tempState = { ...tableValues };
    Object.keys(tempState).forEach((key) => {
      const field = tempState[key];
      if (field.value) {
        field.value.splice(index, 1);
      }
    });

    setTableValues(tempState);
    updateValues(tempState);
  }

  const FieldTable = () => {
    const table = tableValues;
    let length = 0;
    const cols = Object.keys(table).map((key) => {
      const { id, name, value } = table[key];
      if (length < value.length) length = value.length;
      return { field: id, headerName: name, flex: 1 };
    });

    function getHeight(length) {
      if (length === 1) return 200;
      if (length === 2) return 300;
      if (length === 3) return 300;
      if (length > 3) return 500;
      return 200;
    }

    cols.push({
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const { rowIndex } = params;
        return (
          <Pop content="Remove Row">
            <IconButton onClick={() => handleRemoveRow(rowIndex)} size="large">
              <Delete />
            </IconButton>
          </Pop>
        );
      },
    });

    const rows = [];

    Object.keys(table).forEach((key) => {
      const { value, id } = table[key];
      for (let index = 0; index < length; index += 1) {
        rows[index] = rows[index]
          ? {
              ...rows[index],
              id: index,
              [id]: value[index].value,
            }
          : {
              id: index,
              [id]: value[index].value,
            };
      }
    });

    return rows.length === 0 || cols.length === 0 ? null : (
      <div style={{ height: getHeight(rows.length), margin: 15 }}>
        <DataGrid
          rows={rows}
          columns={cols}
          width="100%"
          disableSelectionOnClick
          style={{ border: '1pt solid red' }}
        />
      </div>
    );
  };

  return (
    <Card key={id} style={{ marginTop: '5px' }}>
      <CardHeader title={name} />
      <CardContent>
        {Object.keys(fields).map((key) => {
          const f = fields[key];
          return (
            <CreateField
              key={key}
              field={f}
              values={currentValues}
              updateValues={updateCurrentValues}
            />
          );
        })}
        <Button variant="outlined" onClick={handleAddToTable}>
          Add
        </Button>
      </CardContent>
      {tableValues && <FieldTable table={tableValues} />}
    </Card>
  );
};

export default CreateTableFields;
