// import the fields form constraints
import {
  CRWS_TITLE,
  CONTENT_MATURITY,
  USER_RATING,
  SHORT_NAME,
  CONTROLLED_ACCESS,
  CRWS_ABSTRACT,
  CRWS_TOTAL_USER_RATINGS,
  CRWS_COPYRIGHT,
  CONTENT_MATURITY_REASON,
  CRWS_MATURITY_COLOR,
  TABULAR_REFERENCES,
  TABULAR_DOMAIN_TYPE,
  TABULAR_CRWS_SOURCES,
  TABULAR_CRWS_TOPICS,
  TABULAR_DIMSD_VERSIONS,
  TABULAR_MSIFT_AUTHORS,
  CRWS_SOURCE_URL,
  CRWS_JUSTIFICATION,
  CRWS_ORIGINAL_COPYRIGHT_HOLDER_NAME,
  CRWS_PREAPPROVAL_STATUS,
  TABULAR_MSIFT_REVIEW_BOARD,
  CRWS_REVIEW_START,
  CRWS_REVIEW_END,
} from '../constants/assetFields';

import createModel from './createModel';

const CRWSModel2 = {
  // Tabular fields
  references: { col: TABULAR_REFERENCES },
  domains: { col: TABULAR_DOMAIN_TYPE },
  sources: { col: TABULAR_CRWS_SOURCES },
  topics: { col: TABULAR_CRWS_TOPICS },
  versions: { col: TABULAR_DIMSD_VERSIONS },
  authors: { col: TABULAR_MSIFT_AUTHORS },
  reviewBoard: { col: TABULAR_MSIFT_REVIEW_BOARD },

  // Fields
  title: { col: CRWS_TITLE },
  userRating: { col: USER_RATING },
  contentMaturity: { col: CONTENT_MATURITY },
  controlledAccess: { col: CONTROLLED_ACCESS },
  sourceUrl: { col: CRWS_SOURCE_URL },
  shortTitle: { col: SHORT_NAME },
  contentMaturityReason: { col: CONTENT_MATURITY_REASON },
  contentMaturityColor: { col: CRWS_MATURITY_COLOR },
  abstract: { col: CRWS_ABSTRACT },
  totalUserRating: { col: CRWS_TOTAL_USER_RATINGS },
  originalCopyrightHolder: { col: CRWS_ORIGINAL_COPYRIGHT_HOLDER_NAME },
  justification: { col: CRWS_JUSTIFICATION },
  copyright: { col: CRWS_COPYRIGHT },
  approvalStatus: { col: CRWS_PREAPPROVAL_STATUS },
  reviewStart: { col: CRWS_REVIEW_START },
  reviewEnd: { col: CRWS_REVIEW_END },
};

const createCrwsTabularModel = (asset) => createModel(asset, CRWSModel2);

export default createCrwsTabularModel;
