import { CRWS_DEFAULT } from '../../../constants/searches';
import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import getSearchId from './getSearchId';

async function fetchTotalQuantity() {
  const term = '*';
  const activePage = '1';
  const limit = '25';
  const path = '/search/text';
  const facetRestrictionList = {};

  const searchConfiguration = await otmm.get('/searchconfigurations');

  const searchId = getSearchId(searchConfiguration, CRWS_DEFAULT);

  const facetList = {
    facet_generation_behavior: 'EXCLUDE',
    field_id: 'ARTESIA.FIELD.MODEL',
    type: 'com.artesia.search.facet.FacetSimpleFieldRestriction',
    value_list: ['Resources'],
  };
  facetRestrictionList.facet_restriction_list = {
    facet_field_restriction: facetList,
  };

  const params = new URLSearchParams();
  params.append('keyword_query', `(${term})`);
  params.append('merge_filters', false);
  params.append('load_multilingual_values', true);
  params.append('level_of_detail', 'slim');
  params.append('after', activePage === 1 ? 0 : (activePage - 1) * limit);
  params.append('limit', limit);
  params.append('multilingual_language_code', 'en_US');
  params.append('search_config_id', searchId);
  params.append('keyword_scope_id', 3);
  // params.append('metadata_to_return', METADATA_TO_RETURN);
  params.append('facet_restriction_list', JSON.stringify(facetRestrictionList));

  const response = await otmm.post(path, params);

  const totalResults =
    response.data.search_result_resource.search_result.total_hit_count;

  return { totalResults };
}

export default fetchTotalQuantity;
