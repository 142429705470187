import { useState, useEffect } from 'react';

const useAssetDetails = ({ assetId, isSubscribed, toggleSubscription }) => {
  const [detailsIsSubscribed, setIsSubscribed] = useState(isSubscribed);

  useEffect(() => {
    let mounted = true;
    if (mounted) setIsSubscribed(isSubscribed);
    return () => {
      mounted = false;
    };
  }, [isSubscribed]);

  const detailsToggleSubscription = () => {
    setIsSubscribed(!detailsIsSubscribed);
  };

  const finish = () => {
    if (detailsIsSubscribed !== isSubscribed) {
      toggleSubscription(assetId);
    }
  };

  return {
    finish,
    detailsIsSubscribed,
    detailsToggleSubscription,
  };
};

export default useAssetDetails;
