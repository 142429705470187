import { SEARCH_OTMM } from '../../../../../constants/searches';
import { LOADING, PENDING_UPDATE } from '../../../constants/constants';
import {
  SET_ERROR,
  SET_LOADING,
  SET_PENDING_UPDATE,
  UPDATE_OTMM,
} from '../actions/otmmActionTypes';
import { createOtmmModel, updateOtmmModel } from '../otmmModel';

export const otmmReducer = (
  state = createOtmmModel({
    otmmParams: { term: '', type: SEARCH_OTMM },
  }),
  action
) => {
  switch (action.type) {
    case UPDATE_OTMM: {
      return action.payload;
    }
    case SET_LOADING: {
      return updateOtmmModel({ [LOADING]: action.payload }, state);
    }
    case SET_PENDING_UPDATE: {
      return updateOtmmModel({ [PENDING_UPDATE]: action.payload }, state);
    }
    case SET_ERROR: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default otmmReducer;
