import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Step, StepLabel, Stepper } from '@mui/material';

import getSteps from './functions/getSteps'; // Steps array for the stepper
import ForgotPasswordStepContent from './ForgotPasswordStepContent';

const ForgotPassword = ({ didForgetPassword, error403 }) => {

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // Error state for if the user was routed here by a locked account (403)
  const [message, setMessage] = useState({text: error403.text, open: error403.open});
  
  const advanceToNextStep = () => {
    if(activeStep === 3) {
      // Pass has been changed, route user to login screen
      didForgetPassword(false);
    }
    else {
      setActiveStep(activeStep + 1);
    }
  } 

  return (
    <>
      <Stepper activeStep={activeStep} style={{marginTop: '15px', marginBottom: '10px'}}>
        {steps.map((step) => (
            <Step key={step}>
              <StepLabel>
                {step}
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>

      {message.open && (
        <Alert 
          severity='error' 
          onClose={() => setMessage({...message, open: false})}
          style={{margin: '10px 0px'}}
        >
          {message.text}
        </Alert>
      )}
     
      <ForgotPasswordStepContent
        activeStep={activeStep}
        advanceToNextStep={advanceToNextStep}
      />
    </> 
  );
};

ForgotPassword.propTypes = {
  didForgetPassword: PropTypes.func,
  error403: PropTypes.object
};

ForgotPassword.defaultProps = {
  didForgetPassword: undefined,
  error403: {text: '', open: false}
};

export default ForgotPassword;