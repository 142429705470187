import { Clear, Help, Send } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  Grid,
  Grow,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  BEST_PRACTICE_FG,
  LESSONS_LEARNED_HL_FG,
  NOMINATIONS_FG,
} from '../../constants/fieldGroups';
import clearValues from '../../functions/clearValues';
import SelectFiles from '../addAsset/SelectFiles';
import CopyrightPage from '../CopyrightPage/CopyrightPage';
import CreateFieldGroupForm from '../CreateFieldGroupForm/CreateFieldGroupForm';
import CustomDialog from '../CustomDialog/CustomDialog';
import PageHeader from '../PageHeader/PageHeader';
import Pop from '../pop/Pop';

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const NominateArtifactForm = ({ handleOnSubmit }) => {
  // New stuff for dynamic nomination
  const [infoOpen, setInfoOpen] = useState(false);
  const [values, setValues] = useState();

  // if (values) console.log('nominated values', values)

  const [files, setFiles] = useState([]);
  const [masterFile, setMasterFile] = useState();
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const [selection, setSelection] = useState(NOMINATIONS_FG);
  const [selectedTitle, setSelectedTitle] = useState('Resources');
  const classes = useStyles();

  function clearForm() {
    setFiles([]);
    setMasterFile(undefined);
    setValues(clearValues(values));
  }

  function validateRequiredFields() {
    if (!values) return false;
    const req = Object.keys(values).filter((key) => {
      const field = values[key];
      const { required } = field;
      return required;
    });
    let hasValue = false;

    req.forEach((field) => {
      if (values[field].value !== '') hasValue = true;
      else hasValue = false;
    });

    return hasValue;
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setSelection(value);
    switch (value) {
      case LESSONS_LEARNED_HL_FG:
        setSelectedTitle('Lessons Learned: High Level');
        break;
      case BEST_PRACTICE_FG:
        setSelectedTitle('Best Practices');
        break;
      default:
        setSelectedTitle('Resources');
        break;
    }
  };

  function CopyrightButton() {
    return (
      <Pop content="See more information on copyright">
        <Button
          onClick={() => setInfoOpen(true)}
          color="inherit"
          size="small"
          startIcon={<Help />}
        >
          Copyright Information
        </Button>
      </Pop>
    );
  }

  return (
    <div>
      <>
        <Typography>Select a document type:</Typography>
        <Select
          fullWidth
          value={selection}
          onChange={handleChange}
          style={{ marginBottom: '24px' }}
        >
          <MenuItem value={NOMINATIONS_FG} id="Resources">
            Resource
          </MenuItem>
          <MenuItem value={BEST_PRACTICE_FG} id="Best Practices">
            Best Practices
          </MenuItem>
          <MenuItem value={LESSONS_LEARNED_HL_FG}>
            Lessons Learned: High Level
          </MenuItem>
        </Select>
        <PageHeader title={selectedTitle} />
        <CreateFieldGroupForm fieldGroup={selection} setValues={setValues} />
        {/* <CreateFields
            fields={fields}
            values={values}
            updateValues={updateValues}
          /> */}
        <CopyrightButton />

        <Dialog open={infoOpen}>
          <CopyrightPage />
          <DialogActions>
            <Button onClick={() => setInfoOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <SelectFiles
          fullWidth
          files={files}
          setFiles={setFiles}
          masterFile={masterFile}
          setMasterFile={setMasterFile}
          color="inherit"
        />
        <br />
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          spacing={3}
          className={classes.actionArea}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Send />}
              onClick={() => {
                handleOnSubmit(values, files).then(() => {
                  clearForm();
                  setSnackBar({
                    open: true,
                    severity: 'success',
                    message: 'Resource uploaded for review',
                  });
                });
              }}
              disabled={!validateRequiredFields()}
            >
              Nominate
            </Button>
          </Grid>
          <Grid item>
            <CustomDialog
              button
              label="Clear"
              confirmation
              handlers={[clearForm]}
              title="Clear"
              endIcon={<Clear />}
              sx={{ color: 'text.secondary' }}
            >
              Are you sure you want to clear?
            </CustomDialog>
          </Grid>
        </Grid>
      </>

      <Snackbar
        TransitionComponent={Grow}
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBar({ open: false, severity: snackBar.severity })
        }
      >
        <Alert severity={snackBar.severity}>{snackBar.message}</Alert>
      </Snackbar>
    </div>
  );
};
NominateArtifactForm.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
};
export default NominateArtifactForm;
