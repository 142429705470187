import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/pdfjs-express';
import { connect } from 'react-redux';

const PdfViewer = ({ asset, searchTerm, viewType, theme }) => {
  const viewer = useRef(null);
  const [instance, setInstance] = useState(null);

  const { assetMasterContent } = asset;
  const { url } = assetMasterContent;

  useEffect(() => {
    let mounted = true;
    if (mounted)
      WebViewer(
        {
          licenseKey: 'XftL17UQqFulSMwnT1m4',
          path: '/lib',
          extension: 'pdf',
        },
        viewer.current
      ).then((instance) => {
        setInstance(instance);
      });

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted && instance && url) {
      instance.loadDocument(url);
      const { docViewer, setZoomLevel, enableDownload } = instance;
      setZoomLevel(1.25);
      enableDownload();
      docViewer.on('documentLoaded', () => {
        if (searchTerm && searchTerm !== '*') {
          instance.searchTextFull(searchTerm, {
            wholeWord: true,
          });
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [url, instance, searchTerm]);

  useEffect(() => {
    let mounted = true;

    if (mounted && instance) {
      const { setTheme } = instance;
      setTheme(theme);
    }
    return () => {
      mounted = false;
    };
  }, [theme, instance]);

  return (
    <div
      ref={viewer}
      style={
        viewType === 'AssetDetailsView'
          ? { height: '100%' }
          : { height: '100vh' }
      }
    />
  );
};

PdfViewer.propTypes = {
  searchTerm: PropTypes.string,
  viewType: PropTypes.string,
  asset: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

PdfViewer.defaultProps = { searchTerm: undefined, viewType: undefined };

const mapStateToProps = (state) => ({
  searchTerm: state.otmmState.otmmParams.term,
  theme: state.theme.mode,
});

export default connect(mapStateToProps)(PdfViewer);
