import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const RenderAbstract = ({ abstract, classes }) => (
  <>
    {abstract.value && (
      <div className={classes.abstract}>
        <Typography variant="h6">Abstract</Typography>
        <Typography>{abstract.value}</Typography>
      </div>
    )}
  </>
);

RenderAbstract.propTypes = {
  abstract: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default RenderAbstract;
