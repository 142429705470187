import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';

import SkeletonTable from '../SkeletonTable/SkeletonTable';
import CrwsRow from './CrwsRow';
import CrwsUnapprovedRow from './CrwsUnapprovedRow';

const TableCell = withStyles(() => ({
  root: {
    padding: '5px',
  },
}))(MuiTableCell);

function AssetDataTable({ isSearching, assets, searchTerm }) {
  const unApprovedAssets = assets.filter(
    (asset) => asset.approvalStatus.value !== 'Approved'
  );
  const approvedAssets = assets.filter(
    (asset) => asset.approvalStatus.value === 'Approved'
  );

  return isSearching ? (
    <SkeletonTable />
  ) : (
    <>
      {approvedAssets.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="Approved Resources table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <b>Domain</b>
                </TableCell>
                <TableCell>
                  <b>Title</b>
                </TableCell>
                <TableCell>
                  <b>Version Date</b>
                </TableCell>

                <TableCell>
                  <b>Usability</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {approvedAssets.map((asset) => (
                  <CrwsRow
                    key={asset.assetId}
                    searchTerm={searchTerm}
                    asset={asset}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="Unapproved Resources table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <b>Domain</b>
                </TableCell>
                <TableCell>
                  <b>Title</b>
                </TableCell>
                <TableCell>
                  <b>Justification</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {unApprovedAssets.map((asset) => (
                  <CrwsUnapprovedRow
                    key={asset.assetId}
                    searchTerm={searchTerm}
                    asset={asset}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
AssetDataTable.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  assets: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
    assets: state.otmmState.assets,
    isSearching: state.otmmState.loading,
    searchTerm: state.otmmState.otmmParams.term,
  });

export default connect(mapStateToProps)(AssetDataTable);
