import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import AssetStatus from '../assetStatus/AssetStatus';

function AssetTitle({ asset, cardDetails }) {
  const { shortTitle, title, dateImported, dateLastUpdated } = asset;
  return (
    <div>
      <Grid
        container
        display="flex"
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Grid item xs tabIndex={0}>
          {shortTitle.value && (
            <>
              <b>{shortTitle.value}</b>
              <br />
            </>
          )}
          {title.value}
        </Grid>
        <Grid item>
          <AssetStatus
            dateImported={dateImported}
            dateLastUpdated={dateLastUpdated}
            cardDetails={cardDetails}
          />
        </Grid>
      </Grid>
    </div>
  );
}

AssetTitle.propTypes = {
  asset: PropTypes.object.isRequired,
  cardDetails: PropTypes.object.isRequired,
};

export default AssetTitle;
