import otmmAuth from '../../auth/apis/otmmAuth';
import { cleanString } from '../functions/sharedFunctions';

async function Login({ username, password }) {
  const response = await otmmAuth
    .post(
      '',
      `username=${username.toLowerCase()}&password=${cleanString(password)}`
    )
    .then((res) => {
      const now = new Date();
      res.data.expiresAt = now.getTime() + 24 * 60 * 60 * 1000;
      return res;
    })
    .catch((error) => ({ error }));
  return response;
}

Login.propTypes = {};

export default Login;
