import { otmm } from '../../otmm/apis/otmm';

const deleteSavedSearch = async (id) => {
  await otmm.delete(`savedsearches/${id}`);
  // TODO: can you wrap this in error protection to allow deletion
  // of existing saved searches that don't have search freq
  await otmm.delete(`custom/searchfrequencies/${id}`);
};

export default deleteSavedSearch;
