import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Fab } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import Pop from '../pop/Pop';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let mounted = true;

    if (mounted) window.scrollTo(0, 0);

    return () => {
      mounted = false;
    };
  }, [pathname]);

  return null;
}

export function ScrollToTopButton() {
  const [show, setShow] = useState(false);
  const handleScroll = () => {
    window.scrollTo(0, 0);
    setShow(false);
  };
  useLayoutEffect(() => {
    const scrollListener = () => {
      const showScrollToTopButton = window.pageYOffset > 200;
      if (!show && showScrollToTopButton) setShow(true);
    };
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [show]);

  return show ? (
    <div style={{ position: 'fixed', bottom: 40, right: 30 }}>
      <Pop content="Back to Top">
        <Fab size="small" color="primary" onClick={handleScroll}>
          <ExpandLess />
        </Fab>
      </Pop>
    </div>
  ) : null;
}
