import { otmm } from '../../otmm/apis/otmm';

export default async (fieldGroupId) => {
  const results = await otmm.get(`metadatafieldgroups/${fieldGroupId}`);
  const { data } = results;
  if (data) {
    return {
      name: data.metadata_field_group_resource.metadata_field_group.name,
      fields:
        data.metadata_field_group_resource.metadata_field_group
          .metadata_element_list,
    };
  }

  return results;
};
