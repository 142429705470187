import { otmm, SEARCH_FREQUENCIES_BASE_URL } from '../../otmm/apis/otmm';

const getNotificationFrequencies = async () => {
  const response = await otmm
    .get(`${SEARCH_FREQUENCIES_BASE_URL}/options`)
    .catch((err) => err);
  return (
    response.data || [
      { label: 'default value', id: '0' },
      { label: 'fake value', id: '1' },
    ]
  );
};

export default getNotificationFrequencies;
