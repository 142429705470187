import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
} from '@mui/material';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAssetToGraph } from '../../models/otmmModel/functions/SettingsFunctions';
import RelationsGraph from '../../components/CRWSDetails/components/RelationsGraph';
import StyledLinearProgress from '../../components/StyledLinearProgress/StyledLinearProgress';
import { LinkButton } from '../../../../components/StyledComponents';
import ContactForm from '../../components/ContactPage/ContactForm';

const ReferencesAndRelationships = ({
  assetId,
  graphData,
  cardColor,
  addAssetToGraph,
}) => {
  useState(() => {
    addAssetToGraph(assetId);
  });
  const [open, setOpen] = useState(false);
  const data = graphData[assetId];
  if (data && data.nodes.length === 0)
    return (
      <div
        style={{
          minHeight: '600px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Resource has no relationships.</Typography>
        <br />

        <LinkButton onClick={() => setOpen(true)}>
          Something not right? Contact us.
        </LinkButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Contact us</DialogTitle>
          <DialogContent>
            <ContactForm
              fullWidth
              variant="outlined"
              subject="Relationship problem with resource"
              body={`Resource ID: ${assetId}, `}
            />
          </DialogContent>
        </Dialog>
      </div>
    );

  return !graphData[assetId] ? (
    <>
      <StyledLinearProgress color={cardColor} />

      <div
        // elevation={5}
        style={{
          minHeight: '600px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Loading graph data....</Typography>
        <CircularProgress />
      </div>
    </>
  ) : (
    <RelationsGraph assetId={assetId} data={data} />
  );
};

ReferencesAndRelationships.propTypes = {
  graphData: PropTypes.object.isRequired,
  assetId: PropTypes.string.isRequired,
  cardColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  addAssetToGraph: PropTypes.func.isRequired,
};
ReferencesAndRelationships.defaultProps = {
  cardColor: undefined,
};
const mapStateToProps = (state) => ({
  graphData: state.otmmSettings.graphData,
  isLoading: state.otmmSettings.loading,
});

const mapDispatchToProps = {
  addAssetToGraph,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferencesAndRelationships);
