import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../../../constants/paths';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import useAuth from '../../../auth/hooks/useAuth';

function Signout() {
  const history = useHistory();
  const { logout } = useAuth();
  const { isUserGuest, isUserLoggedIn } = useUserInfo();
  const [open, setOpen] = useState(!isUserGuest());

  useEffect(() => {
    const doLogout = async () => {
      await logout();
      history.push(HOME);
    };

    if ((isUserLoggedIn() && !isUserGuest()) || !isUserLoggedIn()) {
      doLogout();
    } else history.push(HOME);
  });

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Signed out</DialogTitle>
        <DialogContent
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '30px',
          }}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="h6">You have been signed out...</Typography>
            </Grid>

            <Grid item>
              <Button
                onClick={() => {
                  setOpen(false);
                  history.push(HOME);
                  window.location.reload();
                }}
                color="primary"
                variant="contained"
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Signout;
