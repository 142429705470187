import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import transformAssets from './transformAssets';
import getSearchId from './getSearchId';
import getFacetRestrictionType from './getFacetRestrictionType';
import getFacetValueList from './getFacetValueList';
import { METADATA_TO_RETURN } from '../constants/assetFields';

async function searchOtmm(
  term,
  searchName,
  filters,
  activePage,
  limit,
  sortId,
  sortOrd,
  keywords
) {
  const searchConfiguration = await otmm.get('/searchconfigurations');
  const searchId = getSearchId(searchConfiguration, searchName);
  const facetRestrictionList = {};
  if (filters.length) {
    const facetFieldRestriction = filters.map((filter) => ({
      type: getFacetRestrictionType(filter),
      facet_generation_behavior: 'EXCLUDE',
      field_id: filter.filter_id,
      value_list: getFacetValueList(filter),
    }));
    facetRestrictionList.facet_restriction_list = {
      facet_field_restriction: facetFieldRestriction,
    };
  }

  function getKeywords() {
    if (keywords.length !== 0) {
      let strKeys = '';

      keywords.forEach((keyword) => {
        strKeys += `and (${keyword}) `;
      });
      return strKeys;
    }
    return '';
  }
  const params = new URLSearchParams();
  params.append('keyword_query', `(${term}) ${getKeywords()}`);
  params.append('merge_filters', false);
  params.append('load_type', 'metadata');
  params.append('load_multilingual_values', true);
  params.append('level_of_detail', 'slim');
  params.append('after', activePage === 1 ? 0 : (activePage - 1) * limit);
  params.append('limit', limit);
  params.append('multilingual_language_code', 'en_US');
  params.append('search_config_id', searchId);
  params.append('keyword_scope_id', 3);

  params.append('metadata_to_return', METADATA_TO_RETURN);
  params.append('facet_restriction_list', JSON.stringify(facetRestrictionList));
  if (sortId !== 'DEFAULT.RELEVANCE') {
    params.append('sort', sortOrd + sortId);
  }

  const response = await otmm.post('/search/text', params);

  const facets =
    response.data.search_result_resource.search_result
      .facet_field_response_list;

  const assets = await transformAssets(
    response.data.search_result_resource.asset_list
  );

  const totalResults =
    response.data.search_result_resource.search_result.total_hit_count;

  return { assets, facets, totalResults };
}

export default searchOtmm;
