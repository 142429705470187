import React, { useCallback } from 'react';
import _ from 'lodash';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import bytes from 'bytes';
import PropTypes from 'prop-types';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useDropzone } from 'react-dropzone';
import './AddAsset.css';
import { Publish } from '@mui/icons-material';
import { SITE_NAME_ABBR } from '../../constants/constants';

const SelectFiles = ({ files, setFiles, masterFile, setMasterFile }) => {
  const [openDialog, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const extCheck = (filesArray) => {
    let invalid = false;
    let { length } = filesArray;

    for (let i = 0; i < length; i += 1) {
      if (filesArray[i].name.split('.').pop() !== 'pdf') {
        invalid = true;
        filesArray.splice(i, 1);
        length -= 1;
      }
    }

    if (invalid) {
      handleOpen();
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (incomingFiles) => {
      const nonDuplicateFiles = incomingFiles.filter((incomingFile) => {
        let isDup = false;
        files.forEach((file) => {
          if (
            file.name === incomingFile.name &&
            file.size === incomingFile.size
          ) {
            isDup = true;
          }
        });
        return !isDup;
      });
      setFiles([...nonDuplicateFiles, ...files]);
      if (!masterFile && setMasterFile) {
        setMasterFile(nonDuplicateFiles[0]);
      }
    },
  });

  const onRemove = useCallback(
    (index) => {
      const updatedFiles = [...files];
      const deletedFile = files[index];
      updatedFiles.splice(index, 1);
      setFiles(updatedFiles);
      if (setMasterFile && deletedFile === masterFile) {
        if (updatedFiles.length > 0) {
          setMasterFile(updatedFiles[0]);
        } else {
          setMasterFile(undefined);
        }
      }
    },
    [files, masterFile, setFiles, setMasterFile]
  );

  const hasFiles = !_.isEmpty(files);

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unsupported File Format
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          <Typography>
            One or more of the file added are in an unsupported file format. The
            only file format supported by {SITE_NAME_ABBR} is pdf.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>OK</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <div
              {...getRootProps({
                className: classNames({
                  dropzone: !hasFiles,
                  'dropzone-active': hasFiles,
                }),
              })}
            >
              <input {...getInputProps()} />
              {!hasFiles && (
                <p>Click Select File(s) below or drag your resource here.</p>
              )}
              {hasFiles && (
                <List aria-label="Files added list">
                  {files.map((file, index) => {
                    extCheck(files);
                    const extension = file.name.split('.').pop();
                    return (
                      <ListItem key={file.path}>
                        <ListItemIcon>
                          <FileIcon
                            extension={extension}
                            size={50}
                            {...defaultStyles[extension]}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          secondary={bytes(file.size, { unitSeparator: ' ' })}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => onRemove(index)}
                            aria-label="remove"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button
            onClick={open}
            variant="outlined"
            endIcon={<Publish />}
            sx={{ color: 'text.secondary' }}
          >
            Select File(s)
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SelectFiles.propTypes = {
  files: PropTypes.array.isRequired,
  masterFile: PropTypes.object,
  onMakeMaster: PropTypes.func,
  isEdit: PropTypes.bool,
  setFiles: PropTypes.func.isRequired,
  setMasterFile: PropTypes.func,
};

SelectFiles.defaultProps = {
  masterFile: undefined,
  onMakeMaster: undefined,
  isEdit: false,
  setMasterFile: undefined,
};

export default SelectFiles;
