import { Typography, Link as MatLink } from '@mui/material';
import React from 'react';
import { SITE_NAME, SITE_NAME_ABBR, SUPPORT_EMAIL } from '../../constants/constants';
import ExternalLinkDialog from '../CustomDialog/ExternalLinkDialog';

function AccessibilityInformation() {
  return (
    <>
      <Typography paragraph>
        {SITE_NAME} ({SITE_NAME_ABBR}) is
        committed to making its portal page and electronic resources accessible
        to individuals of all abilities. Our success is measured by meeting or
        exceeding the requirements of Section 508 of the Rehabilitation Act (29
        U.S.C. § 794(d)), as amended by the Workforce Investment Act of 1998
        (Public Law 105-108). Section 508 requires that all individuals with
        disabilities (whether federal employees or members of the general
        public) have access to and use of information and data, comparable to
        that provided to individuals without disabilities, unless an undue
        burden would be imposed on us. More information about Section 508 may be
        found{' '}
        <ExternalLinkDialog label="https://www.section508.gov/manage/laws-and-policies/">
          https://www.section508.gov/manage/laws-and-policies/
        </ExternalLinkDialog>
      </Typography>

      <Typography paragraph>
        If you use assistive technology (such as a screen reader, eye tracking
        device, voice recognition software, etc.) and have difficulty accessing
        information on this website, please use the DOD CIO{' '}
        <ExternalLinkDialog label="Accessibility/Section 508">
          https://dodcio.defense.gov/DoDSection508/Std_Stmt.aspx
        </ExternalLinkDialog>{' '}
        site to report the difficulty. You will be asked to provide the URL (web
        address) of the material you tried to access and a description of the
        problem you experienced.
      </Typography>

      <Typography paragraph>
        The majority of our resource documents are viewed as Portable Document
        Format (PDF) files. To view documents stored as PDF files, your local
        computer must have the Adobe Acrobat Reader DC application, or a Web
        browser plug-in that supports the PDF file format.
      </Typography>

      <Typography paragraph>
        Download the{' '}
        <ExternalLinkDialog label="Adobe Acrobat Reader DC">
          https://acrobat.adobe.com/us/en/acrobat/pdf-reader.html
        </ExternalLinkDialog>
      </Typography>

      <Typography paragraph>
        As the technology of the Internet evolves, we share the ongoing task of
        learning how to improve the accessibility of our resource documents and
        our services with you. We welcome your comments for improving the
        accessibility of our web assets and services. Please share your
        comments, questions, or concerns related to accessibility or our Section
        508 Program with{' '}
        <MatLink href="mailto:{SUPPORT_EMAIL}">
          {SUPPORT_EMAIL}
        </MatLink>
        .
      </Typography>
    </>
  );
}

AccessibilityInformation.propTypes = {};

export default AccessibilityInformation;
