import {
  LOGIN,
  LOGOUT,
  RETRIEVED_ALL_USER_INFO,
  START_AUTHENTICATION,
  STOP_AUTHENTICATION,
  ERROR,
  USER_LIGHTBOX,
} from '../constants/actionTypes';

import authStorage from '../../functions/authStorage';
import defaultAuthState from '../../constants/defaultAuthState';

const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN: {
      const { authResponse } = action;
      authStorage.set(authResponse);
      return {
        ...state,
        authResponse,
      };
    }
    case LOGOUT:
      return {
        ...state,
        ...defaultAuthState,
      };
    case RETRIEVED_ALL_USER_INFO: {
      const { userSession, userInfo, userGroups } = action;
      return {
        ...state,
        userSession,
        userInfo,
        userGroups,
      };
    }

    case USER_LIGHTBOX: {
      const { lightbox } = action;
      return {
        ...state,
        lightbox,
      };
    }
    case START_AUTHENTICATION:
      return {
        ...state,
        isAuthenticating: true,
      };
    case STOP_AUTHENTICATION:
      return {
        ...state,
        isAuthenticating: false,
      };
    case ERROR: {
      const { errorType, error } = action;
      return {
        ...state,
        ...defaultAuthState,
        errorType,
        error,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
