import { Container } from '@mui/material';
import React from 'react';
import LoginForm from './LoginForm';

export default function LoginSideBySide() {
  return (
    <Container>
      <LoginForm />
    </Container>
  );
}
