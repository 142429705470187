/*
  This component is used by AssetDetails.js
  It contains similiar code to CRWSDetails.js and is used to display the asset header info,
  such as title, verification icon, avatar, etc
*/

import { CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { matchPath } from 'react-router-dom';
import { ASSET } from '../../../../constants/paths';
import useCardColor from '../../hooks/useCardColor';
import AssetAvatar from '../AssetAvatar/AssetAvatar';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';
import CrwsDetailSubheader from '../CRWSDetails/components/CrwsDetailSubheader';
import CrwsTitle from '../CrwsTitle/CrwsTitle';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';

const AssetDetailsHeader = ({ asset }) => {
  const {
    contentMaturityReason,
    contentMaturityColor,
    domains,
    sources,
    title,
    versions,
  } = asset;

  const { OPRShort: oprShort } = sources.values;
  const { Domain: domain } = domains.values;

  const headers = {
    delimiter: <br />,
    fields: [{ name: title.name, value: title.value }],
  };

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};
  const classes = useCardColor(cardDetails.color);

  const isAssetDetailsView = matchPath(window.location.pathname, ASSET); // Does the current window address contain 'asset' ?

  return (
    <>
      <CardHeader
        className={isAssetDetailsView ? "" : classes.gridHeader}
        action={
          <VerifiedIcon
            size={50}
            reason={contentMaturityReason.value}
            color={contentMaturityColor.value}
          />
        }
        title={
          <CrwsTitle asset={asset} oprShort={oprShort} headers={headers} />
        }
        subheader={<CrwsDetailSubheader asset={asset} versions={versions}/>}
        avatar={
          <AssetAvatar
            domain={domain}
            classes={classes}
            cardDetails={cardDetails}
            elevation={3}
          />
        }
      />
    </>
  );
};

AssetDetailsHeader.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetDetailsHeader;
