import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UnapprovedAssets from '../components/UnapprovedAssets/UnapprovedAssets';
import Page404 from '../../../pages/Page404';
import SecureRoute from '../../../components/SecureRoute/SecureRoute';
import { REVIEWERS } from '../constants/constants';
import { REVIEW, REVIEW_NOMINATED } from '../../../constants/paths';

const userGroups = [REVIEWERS];
const Review = () => (
  <Switch>
    <SecureRoute userGroups={userGroups} path={REVIEW_NOMINATED} exact>
      <UnapprovedAssets />
    </SecureRoute>
    <Route path={REVIEW}>
      <Page404 />
    </Route>
  </Switch>
);

Review.propTypes = {};

export default Review;
