import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Collapse } from '@mui/material';

export default function Banner({
  title,
  severity,
  children,
  handler,
  open,
  ...otherProps
}) {
  return (
    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
      <Collapse in={open}>
        <Alert severity={severity} onClose={handler} {...otherProps}>
          <AlertTitle>{title}</AlertTitle>
          {children}
        </Alert>
      </Collapse>
    </div>
  );
}

Banner.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  severity: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

Banner.defaultProps = {
  severity: 'info',
};
