import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { alpha, IconButton, TextField, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import clearSearchSuggestions from '../actions/clearSearchSuggestions';
import fetchSearchSuggestions from '../actions/fetchSearchSuggestions';
import formatSearchSuggestions from '../functions/formatSearchSuggestions';
import { SEARCH } from '../../../constants/paths';
import fetchTotalQuantity from '../../Home/functions/fetchTotalQuantity';
import {
  searchByTerm,
  setSearchTerm,
} from '../../Home/models/otmmModel/functions/updateState';

const SearchBar = ({
  variant,
  fetchSearchSuggestions,
  setSearchTerm: pSetSearchTerm,
  searchTerm: pSearchTerm,
  searchSuggestions,
  searchByTerm,
  autoFocus,
}) => {
  const searchRef = useRef();
  const location = useLocation();
  const history = useHistory();
  // const classes = useStyles();
  const [totalResults, setTotalResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState(pSearchTerm);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (totalResults === null && mounted) {
        const results = await fetchTotalQuantity().catch(
          () => 'Error retrieving Resource count.'
        );
        if (mounted) setTotalResults(results.totalResults);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [totalResults]);

  const search = (term) => {
    const encodedTerm = encodeURI(term.trim());

    searchByTerm(term);
    if (location.pathname !== SEARCH + encodedTerm) {
      history.push({
        pathname: SEARCH,
        search: `?term=${encodedTerm}`,
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (!location.pathname === SEARCH && mounted) {
      setSearchTerm('');
      clearSearchSuggestions();
    }

    return () => {
      mounted = false;
    };
  }, [location, setSearchTerm]);

  const onSearchChange = useCallback(
    (e) => {
      if (e && e.target.value && e.target.value.trim().length > 0) {
        fetchSearchSuggestions(e.target.value);
      }

      setSearchTerm(e.target.value);
    },
    [fetchSearchSuggestions, setSearchTerm]
  );
  const formattedSearchSuggestions = useMemo(
    () => formatSearchSuggestions(searchSuggestions),
    [searchSuggestions]
  );

  const onSubmit = (event) => {
    event.preventDefault();
    pSetSearchTerm(searchTerm.trim());
    if (searchTerm && searchTerm.trim().length > 0) search(searchTerm);
    setSearchTerm(searchTerm.trim());
  };
  const handleSelect = (e, values) => {
    if (values !== null) search(values);
  };

  const clearInput = () => {
    setSearchTerm('');
  };

  return (
    <form onSubmit={onSubmit} style={{ border: 'none' }}>
      <Autocomplete
        tour="search-bar"
        freeSolo
        value={searchTerm}
        id="SearchTheBok"
        onChange={handleSelect}
        options={formattedSearchSuggestions.map((option) => option.title)}
        sx={(theme) => ({
          position: 'relative',
          border: `2px solid ${theme.palette.text.primary}`,
          borderRadius: '2rem',

          backgroundColor:
            theme.palette.mode === 'light'
              ? alpha(theme.palette.primary.light, 1)
              : alpha(theme.palette.primary.light, 0.25),
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? alpha(theme.palette.primary.light, 0.9)
                : alpha(theme.palette.primary.light, 0.15),
          },

          minWidth: '280px',
          maxWidth: '400px',
          [theme.breakpoints.down('lg')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            width: '100%',
          },
          padding: 0,
          paddingLeft: '39px',
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            fontFamily: 'Martel inherit',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: 'none',
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: 0,
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
          },

          '&.MuiAutocomplete-root.MuiOutlinedInput-root': {
            flexWrap: 'nowrap',
            padding: 0,
          },
        })}
        renderInput={(params) => (
          <TextField
            onKeyDown={(e) => {
              if (e.key === 13 && e.target.value) {
                setSearchTerm(e.target.value);
                onSubmit(e);
              }
              if (e.key === 27 && e.target.value) {
                setSearchTerm(null);
              }
            }}
            margin="none"
            variant={variant}
            {...params}
            autoFocus={autoFocus}
            ref={searchRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <IconButton aria-label="search" onClick={onSubmit} size="small">
                  <SearchIcon />
                </IconButton>
              ),

              startAdornment: searchTerm && (
                <IconButton
                  aria-label="clear"
                  onClick={clearInput}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              ),
              type: 'search',
            }}
            value={searchTerm}
            onChange={onSearchChange}
            placeholder="SEARCH"
          />
        )}
      />
    </form>
  );
};

SearchBar.propTypes = {
  fullWidth: PropTypes.bool,
  disableOptions: PropTypes.bool,

  color: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.any,
  backgroundColor: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  fetchSearchSuggestions: PropTypes.func.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.array.isRequired,
  searchByTerm: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};
SearchBar.defaultProps = {
  backgroundColor: null,
  disableOptions: true,
  color: 'primary',
  fullWidth: false,
  variant: 'outlined',
  className: null,
  autoFocus: false,
};

const mapStateToProps = (state) => ({
  searchSuggestions: state.searchSuggestions,
  searchTerm: state.otmmState.otmmParams.term,
  isSearching: state.otmmState.loading,
});

export default connect(mapStateToProps, {
  fetchSearchSuggestions,
  setSearchTerm,
  clearSearchSuggestions,
  searchByTerm,
})(SearchBar);
