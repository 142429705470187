import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, List, ListItem, ListItemText, Popover } from '@mui/material';
import { NotificationsActive, NotificationsOff } from '@mui/icons-material';

import Pop from '../pop/Pop';
import { SEARCH_FREQUENCIES_BASE_URL, otmm } from '../../../otmm/apis/otmm';

const NotificationFrequencySelector = ({
  frequency,
  setFrequency,
  id,
  options,
  size,
  ...otherProps
}) => {
  const [currentFrequency, setCurrentFrequency] = useState(frequency);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChangeFrequency = async (
    savedSearchId,
    notificationFrequency
  ) => {
    setCurrentFrequency(notificationFrequency);
    setAnchorEl(null);
    if (setFrequency) {
      setFrequency(notificationFrequency);
    } else if (notificationFrequency) {
      await otmm.patch(
        `${SEARCH_FREQUENCIES_BASE_URL}/${savedSearchId}?frequency=${notificationFrequency}`
      );
    }
  };

  return !options ? null : (
    <>
      <Pop content="Notifications">
        <Button
          {...otherProps}
          startIcon={
            currentFrequency !== 'NEVER' ? (
              <NotificationsActive />
            ) : (
              <NotificationsOff />
            )
          }
          id={id}
          size={size}
          aria-label="Get Notifications new"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {currentFrequency}
        </Button>
      </Pop>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List>
          {options.map((freq) => (
              <ListItem
                key={freq.id}
                id={freq.id}
                onClick={() => {
                  handleChangeFrequency(id, freq.id);
                }}
                selected={currentFrequency === freq.id}
                button
              >
                <ListItemText primary={freq.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

NotificationFrequencySelector.propTypes = {
  id: PropTypes.any.isRequired,
  frequency: PropTypes.string.isRequired,
  options: PropTypes.array,
  size: PropTypes.string,
  setFrequency: PropTypes.func,
};

NotificationFrequencySelector.defaultProps = {
  setFrequency: undefined,
  options: undefined,
  size: 'small',
};

export default NotificationFrequencySelector;
