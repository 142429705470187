import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import { METADATA_TO_RETURN } from '../constants/assetFields';
// import { METADATA_FIELDS_TO_GET } from '../constants/assetFields';
import { cleanString } from './sharedFunctions';
import transformAssets from './transformAssets';

export async function getLightboxId() {
  const res = await otmm.get('/lightboxes');
  return res.data.lightboxes_resource.lightbox[0].id;
}

export default async function fetchOtmmLightbox() {
  const params = new URLSearchParams();
  params.append('load_asset_data', true);
  params.append('level_of_detail', 'slim');
  params.append('load_type', 'metadata');
  params.append('multilingual_language_code', 'en_US');
  params.append('return_search_object', true);
  // params.append(
  //   'preference_id',
  //   'ARTESIA.PREFERENCE.GALLERYVIEW.DISPLAYED_FIELDS'
  // );
  params.append('metadata_to_return', METADATA_TO_RETURN);
  const Id = await getLightboxId();
  let hasError;
  const response = await otmm
    .get(`/lightboxes/${Id}`, { params })
    .catch((e) => {
      hasError = e;
    });

  const assets = hasError
    ? []
    : await transformAssets(response.data.lightbox_resource.asset_list);
  return { assets, hasError };
}

export async function deleteOtmmLightbox(assetId) {
  const Id = await getLightboxId();

  const response = await otmm.patch(
    `/lightboxes/${Id}/assets`,
    `operation=remove&asset_ids=${assetId}`
  );

  return response.data;
}

export async function addOtmmLightbox(assetIds) {
  const res = await otmm.get('/lightboxes');
  const Id = res.data.lightboxes_resource.lightbox[0].id;

  const operation = 'add';
  const selectionContent = JSON.stringify({
    selection_context_param: {
      selection_context: {
        asset_ids: assetIds,
        // asssetContentType: ['ACROBAT'], // <- may not be necessary, but this is misspelled in OTMM as well.
        assetSubContentType: ['none'],
        type: 'com.artesia.asset.selection.AssetIdsSelectionContext',
        include_descendants: 'ALL',
      },
    },
  });

  const response = await otmm.patch(
    `/lightboxes/${Id}/assets`,
    `operation=${operation}&selection_context=${cleanString(selectionContent)}`
  );
  return response.data;
}
