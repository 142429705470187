import { otmm } from '../../otmm/apis/otmm';
import lockAsset from './lockAsset';
import unlockAsset from './unlockAsset';

export default async (editedAsset, asset) => {
  const { assetId } = asset;

  // Lock the asset
  await lockAsset(assetId);

  // Update the asset
  try {
    await otmm.patch(`/assets/${assetId}`, JSON.stringify(editedAsset));
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    await unlockAsset(assetId);
  }

  // Unlock the asset

  await unlockAsset(assetId);
  return {};
};
