import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import CustomDialog from './CustomDialog';
import { SITE_NAME_ABBR } from '../../constants/constants';

function ExternalLinkDialog({ children, href, label, img, ...otherProps }) {
  const handleClose = () => {
    if (href) window.open(href);
    else window.open(children, '_blank');
  };
  return (
    <CustomDialog
      confirmation
      link
      handler={handleClose}
      title="External Link"
      label={label}
      img={img}
      {...otherProps}
    >
      <Typography>
        Access to this resource or link is located outside of {SITE_NAME_ABBR}.
      </Typography>
      <Typography>
        <b>Are you sure you wish to leave {SITE_NAME_ABBR}?</b>
      </Typography>
    </CustomDialog>
  );
}

ExternalLinkDialog.defaultProps = {
  img: false,
  children: undefined,
  href: undefined,
};

ExternalLinkDialog.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  img: PropTypes.bool,
};

export default ExternalLinkDialog;
