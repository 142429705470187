import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ShareIcon from '@mui/icons-material/Share';
import { v4 as uuid } from 'uuid';
import ClipboardCopyIcon from '../../clipboardCopyIcon/ClipboardCopyIcon';
import Pop from '../../pop/Pop';

const ShareList = ({ asset, classes }) => {
  const handleClickNewWindow = () => {
    window.open(
      `http://${window.location.host}/asset/${asset.assetId}`,
      '_blank'
    );
  };

  return (
    <Pop
      type="iconButton"
      label="Share"
      content={
        <>
          <List>
            <ListItem button onClick={handleClickNewWindow}>
              <ListItemIcon>
                <OpenInBrowserIcon />
              </ListItemIcon>
              <ListItemText primary="Open in New Window" />
            </ListItem>

            <ClipboardCopyIcon
              textToCopy={`${window.location.origin}/asset/${asset.assetId}`}
              uniqueId={uuid()}
            />
          </List>
        </>
      }
    >
      <ShareIcon classes={{ root: classes.contrastColor }} />
    </Pop>
  );
};

ShareList.propTypes = {
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ShareList;
