// Create model: take columns from query and convert them to variable names from model
//
// - columns: object where key=COLUMN_NAME and values={name, value, col}
// - model required columns not passed in columns are set to: { name: '', value: '', col: '' }
const createModel = (columns, model) =>
  Object.keys(model).reduce((acc, varName) => {
    const { col, editable, label } = model[varName];
    if (col in columns) {
      acc[varName] = {
        ...columns[col],
        editable,
        name: label || columns[col].name,
      };
    } else {
      acc[varName] = { name: '', value: '', col };
    }

    return acc;
  }, {});

export default createModel;
