import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  Alert,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HOME } from '../../../../constants/paths';
import { registrationApi } from '../../../otmm/apis/otmm';
import LoginForm from '../LoginPage/LoginForm';

const ActivateUser = () => {
  const { encodedUsername } = useParams();

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(true);
  const [dialog, setDialog] = useState({
    title: 'Activating Account',
    message: (
      <>
        Please wait while we activate <b>{window.atob(encodedUsername)}</b>.
      </>
    ),
  });

  const handleClose = () => {
    setOpen(false);
    history.push(HOME);
  };

  useState(async () => {
    const code = new URLSearchParams(history.location.search).get('code') || '';
    registrationApi
      .post(`user/activate/${encodedUsername}?code=${code}`)
      .then((res) => {
        setLoading(false);
        setDialog({
          title: 'Activation Account',
          message: (
            <>
              <Alert severity="success">{res.data.message}</Alert>
            </>
          ),
          handler: () => setOpen(false),
        });
        setUser(res.data.user);
      })
      .catch((err) => {
        setLoading(false);
        setDialog({
          title: 'Activation Failed',
          message: (
            <>
              <Typography gutterBottom>
                An error occurred while trying to activate{' '}
                <b>{window.atob(encodedUsername)}</b>.
              </Typography>
              <Alert severity="error">
                <b>{err.response.data || 'An unknown error has occurred.'}</b>
              </Alert>
            </>
          ),
          handler: handleClose,
        });
      });
  });

  return (
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {user && (
        <Card>
          <CardHeader title="Login" />
          <CardContent>
            <LoginForm {...user} onComplete={() => history.push(HOME)} />
          </CardContent>
        </Card>
      )}
      <Dialog open={open}>
        <DialogTitle>{dialog.title}</DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent>{dialog.message}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={dialog.handler}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ActivateUser.propTypes = {};

export default ActivateUser;
