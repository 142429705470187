const appState = {
  greeting: 'Yo!!!',
};
const intialStateReducer = (state = appState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default intialStateReducer;
