import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Pop from '../../pop/Pop';

const ControlledAccessIcon = ({ controlledAccess, classes }) => (
  <div>
    {controlledAccess.value === 'Yes' && (
      <Pop content="Unclassified Restricted Access">
        <IconButton disableRipple disableFocusRipple size="large">
          <LockIcon classes={{ root: classes.contrastColor }} />
        </IconButton>
      </Pop>
    )}
  </div>
);

ControlledAccessIcon.propTypes = {
  controlledAccess: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ControlledAccessIcon;
