import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, useTheme } from '@mui/material';
import ValidationCheckList from '../ValidationCheckList';

const EmailField = ({
  state,
  setState,
  id,
  label,
  errorText,
  ...otherProps
}) => {
  const regexEmail =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [error, setError] = useState(false);
  let allowedEmails = process.env.REACT_APP_ALLOWED_EMAILS || [];
  if (allowedEmails.length > 0)
    allowedEmails = allowedEmails.toLowerCase().split(',');
  const restrictedEmail = Boolean(process.env.REACT_APP_ALLOWED_EMAILS);
  const regexSaic = allowedEmails[allowedEmails.length - 1];
  const saicreg = new RegExp(regexSaic, 'g');

  const fontColor = error ? 'red' : '#A9A9A9';

  const validateEmail = (email) => {
    if (restrictedEmail) {
      return (
        regexEmail.test(email) &&
        (allowedEmails.includes(email) || saicreg.test(email))
      );
    }
    return regexEmail.test(email);
  };

  const handleChange = (event) => {
    setState({
      value: event.target.value.toLowerCase(),
      valid: validateEmail(event.target.value.toLowerCase()),
    });
  };
  const handleOnBlur = (event) => {
    if (event.target.value.trim() !== '') {
      setError(!validateEmail(event.target.value.toLowerCase()));
    }
  };
  const theme = useTheme();
  const greenShade = theme.palette.mode === 'dark' ? '#99FF99' : 'green';

  return (
    <>
      <TextField
        variant="outlined"
        required
        fullWidth
        placeholder="Email"
        id={id}
        label={label}
        aria-label={label}
        onChange={handleChange}
        onBlur={handleOnBlur}
        error={error}
        helperText={error ? errorText : ''}
        {...otherProps}
      />
      <Grid container spacing={0}>
        <ValidationCheckList color={state.valid ? greenShade : fontColor}>
          Valid email
        </ValidationCheckList>
      </Grid>
    </>
  );
};

EmailField.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
};

EmailField.defaultProps = {
  errorText: undefined,
  id: undefined,
  label: undefined,
};

export default EmailField;
