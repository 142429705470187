import {
  FACET_DATE_INTERVAL_RESPONSE,
  FACET_NUMERIC_RANGE_RESPONSE,
} from '../constants/facetTypes';
import { NO_VALUE_DEFINED_IN_OTMM } from '../constants/constants';

const getFacetValueList = (filter) => {
  if (filter.type === FACET_DATE_INTERVAL_RESPONSE) {
    return filter.value.map((v) => v.meta.dateInterval);
  }
  if (filter.type === FACET_NUMERIC_RANGE_RESPONSE) {
    return filter.value.map((v) => v.meta.numericRange);
  }

  return filter.value.map((v) =>
    v.text !== NO_VALUE_DEFINED_IN_OTMM ? v.text : undefined
  );
};

export default getFacetValueList;
