import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material';
import { connect } from 'react-redux';

import CRWS from '../CRWS/CRWS';
import { SUBSCRIPTIONS } from '../../../../constants/paths';
import NominatedCards from '../Cards/NominatedCards';

const AssetCards = ({ assets, searchTerm, hasData, isSearching }) => {
  const viewingSubscribed =
    useRouteMatch({ path: SUBSCRIPTIONS, exact: true }) !== null;

  const skeletonList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return assets.length > 0 ? (
    assets.map((asset) => {
      const { approvalStatus } = asset;
      switch (approvalStatus.value) {
        case 'Approved':
          return (
            <Grid
              item
              // xs={12} sm={6} md={6} lg={4} xl={3}
              key={asset.assetId}
            >
              <CRWS
                crws={asset}
                viewingSubscribed={viewingSubscribed}
                searchTerm={searchTerm}
              />
            </Grid>
          );
        default:
          return (
            <Grid
              item
              // xs={12} sm={6} md={6} lg={4} xl={3}
              key={asset.assetId}
            >
              <NominatedCards asset={asset} />
            </Grid>
          );
      }
    })
  ) : (
    <>
      {isSearching &&
        skeletonList.map((item) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={item}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: 'transparent' }}
                  title={<Skeleton variant="text" width={200} />}
                  subheader={<Skeleton variant="text" width={200} />}
                  action={
                    <Skeleton variant="rectangular" width={40} height={40} />
                  }
                  avatar={
                    <Skeleton variant="rectangular" width={40} height={40} />
                  }
                />
                <CardContent>
                  <Skeleton variant="rectangular" height={118} />
                </CardContent>
              </Card>
            </Grid>
          ))}
      {!hasData && !isSearching && <Grid item>No search results found...</Grid>}
    </>
  );
};

AssetCards.propTypes = {
  assets: PropTypes.array.isRequired,
  hasData: PropTypes.bool,
  isSearching: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

AssetCards.defaultProps = {
  hasData: false,
};

const mapStateToProps = (state) => ({
    otmmState: state.otmmState,
    isSearching: state.otmmState.loading,
    assets: state.otmmState.assets,
    searchTerm: state.otmmState.otmmParams.term,
  });

export default connect(mapStateToProps)(AssetCards);
