import { otmmUrlEncoded } from '../../otmm/apis/otmm';
import getFacetRestrictionType from './getFacetRestrictionType';
import getFacetValueList from './getFacetValueList';

const createSavedSearch = async (
  // userName,
  name,
  description,
  // notificationFrequency,
  term,
  searchConfigId,
  filters,
  keywords
) => {
  const params = new URLSearchParams();

  const facetRestrictionList = {};
  if (filters.length) {
    const facetFieldRestriction = filters.map((filter) => ({
      type: getFacetRestrictionType(filter),
      facet_generation_behavior: 'EXCLUDE',
      field_id: filter.filter_id,
      value_list: getFacetValueList(filter),
    }));
    facetRestrictionList.facet_restriction_list = {
      facet_field_restriction: facetFieldRestriction,
    };
  }

  function getKeywords() {
    if (keywords.length !== 0) {
      let strKeys = '';

      keywords.forEach((keyword) => {
        strKeys += `and (${keyword}) `;
      });
      return strKeys;
    }
    return '';
  }

  params.append('name', name);
  params.append('description', description);
  params.append('is_public', false);
  params.append('is_favorite', false);
  params.append('keyword_query', `(${term}) ${getKeywords()}`);
  params.append('search_config_id', searchConfigId);
  params.append('facet_restriction_list', JSON.stringify(facetRestrictionList));
  const response = await otmmUrlEncoded.post('/savedsearches/text', params);

  // const payload = {
  //   userId: userName,
  //   searchId: `${response.data.saved_search_resource.saved_search.id}`,
  //   frequency: notificationFrequency,
  // };
  // const notificationFrequencyResponse = await otmm.post(
  //   'custom/searchfrequencies',
  //   JSON.stringify(payload)
  // );

  // response.data.saved_search_resource.saved_search.notificationFrequency =
  //   notificationFrequencyResponse.data.frequency;

  return response;
};

export default createSavedSearch;
