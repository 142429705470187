import moment from 'moment';
import _ from 'lodash';
import { OTMM_DATE_FORMAT } from '../constants/constants';

const convertToOtmmDateTime = (value) => {
  let date = value;
  if (value.includes('T')) return value;
  if (value.includes('-')) {
    // slashes ensure local time zone, dashes are UTC.
    date = _.replace(value, '-', '/');
  }
  return moment(new Date(date)).format(OTMM_DATE_FORMAT);
};

export default convertToOtmmDateTime;
