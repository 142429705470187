import { Grid, Link as MatLink, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BrainImg } from '../../../../constants/images';
import { RELEASE_PAGE } from '../../../../constants/paths';
import {
  CURRENT_VERSION,
  SITE_NAME,
  SITE_NAME_ABBR,
} from '../../constants/constants';
import PageHeader from '../PageHeader/PageHeader';
// commit
import Pop from '../pop/Pop';

const useStyles = makeStyles(() => ({
  subheadings: {
    fontSize: '1.4rem',
    fontWeight: 700,
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '1%',
  },
  colAlign: {
    textAlign: 'center',
  },
}));

function About() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <PageHeader title={`About the ${SITE_NAME_ABBR}`} centered />

      <Grid container spacing={2}>
        <Grid
          item
          md={8}
          width="100%"
          style={{ flexBasis: 'auto', maxWidth: '100%' }}
        >
          <Typography h2 className={classes.subheadings}>
            VISION
          </Typography>
          <Typography paragraph>
            The {SITE_NAME} ({SITE_NAME_ABBR}) is intended to provide
            referential resources for the DoD engineering community for
            implementing Digital Engineering (DE), starting with Systems
            Engineering and expanding to specific discipline engineering domains
            and specialty areas. Data on Digital Engineering is stored and
            created in a controlled manner. Everyone working in this space
            contributes to the community&apos;s collective experiences and is
            able to quickly build digital engineering solutions.
          </Typography>
          <img
            style={{ float: 'right', width: '40%' }}
            src={BrainImg}
            className={classes.logo}
            alt={`${SITE_NAME_ABBR} Logo`}
          />
          <Typography className={classes.subheadings} h2>
            PURPOSE
          </Typography>
          <Typography paragraph>
            The purpose of {SITE_NAME_ABBR} is to capture knowledge through
            documentation to assist with the transition from traditional
            acquisition processes to a digital environment. The {SITE_NAME_ABBR}{' '}
            will allow professionals to engage with one another with DoD
            Services and Agencies to include acquisition professionals,
            analysts, modelers, engineers, and Warfighters. The {SITE_NAME_ABBR}{' '}
            will allow these professionals to bring together all the captured
            knowledge to support Digital Engineering Transformation.
          </Typography>
          <Typography paragraph>
            Current Version of {SITE_NAME_ABBR}:{' '}
            <Pop content="Click here for release notes">
              <MatLink
                tabIndex={0}
                component={Link}
                to={RELEASE_PAGE}
                onClick={() => history.push(RELEASE_PAGE)}
              >
                {CURRENT_VERSION}
              </MatLink>
            </Pop>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default About;
