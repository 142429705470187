import PropTypes from 'prop-types';
import React, {useState} from 'react';

import RegistrationForm from './forms/RegistrationForm';
import VerificationForm from './forms/VerificationForm';
import UserLoginForm from './forms/UserLoginForm';

const RegistrationPageStepsContent = ({activeStep, incrementStep, onComplete}) => {

	const [username, setUserCallback] = useState('');
	const [vCodeMessage, setvCodeMessage] = useState(''); // Sets message given to the user in VerificationForm.js

	switch (activeStep) {
		case 0:
			return (
				<>
					<RegistrationForm 
						incrementStep={incrementStep} 
						onComplete={onComplete} 
						setUserCallback={setUserCallback} 
						setvCodeMessage={setvCodeMessage}
					/>
				</>
			);

	case 1:
		return (
			<>
				<VerificationForm 
					incrementStep={incrementStep} 
					username={username} 
					vCodeMessage={vCodeMessage}
				/>
			</>
	);

	case 2:
		return (
			<>
				<UserLoginForm incrementStep={incrementStep} />
			</>
		);

	default:
		return (
			<h1>ERROR UNKNOWN STEP</h1>
		);
	}
				
};

RegistrationPageStepsContent.propTypes = {
    activeStep: PropTypes.number.isRequired,
    incrementStep: PropTypes.func,
    onComplete: PropTypes.func
}

RegistrationPageStepsContent.defaultProps = {
    incrementStep: undefined,
    onComplete: undefined
}

export default RegistrationPageStepsContent;