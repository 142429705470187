import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import './AssetDetails.css';
import AssetDetails from './AssetDetails';
import './AssetView.css';
import Loading from '../../../../Loading';
import { fetchWithRedux } from '../../functions/fetchAssets';
import {
  setSearchTerm,
  setSearchType,
} from '../../models/otmmModel/functions/updateState';
import { ASSET } from '../../../../constants/paths';
import { SEARCH_ASSET } from '../../../../constants/searches';

const AssetView = ({
  assets,
  isSearching,
  fetchWithRedux,
  setSearchTerm,
  setSearchType,
}) => {
  const routeAssetView = useRouteMatch({
    path: ASSET,
    exact: true,
  });

  useState(() => {
    setSearchType(SEARCH_ASSET);
    setSearchTerm(routeAssetView.params.assetId);
    fetchWithRedux();
  });

  return (
    <>
      
      <Loading active={isSearching} fullscreen>
        Loading asset...
      </Loading>
      {!assets[0] && !isSearching && <> No Data Found</>}
      {assets[0] && !isSearching && <AssetDetails asset={assets[0]} />}
    </>
  );
};

AssetView.propTypes = {
  assets: PropTypes.array,
  isSearching: PropTypes.bool.isRequired,
  fetchWithRedux: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
};

AssetView.defaultProps = {
  assets: null,
};

const mapStateToProps = (state) => ({
    pendingUpdate: state.otmmState.pendingUpdate,
    assets: state.otmmState.assets,
    isSearching: state.otmmState.loading,
  });

export default connect(
  mapStateToProps,
  { fetchWithRedux, setSearchTerm, setSearchType }
)(AssetView);
