import React from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from '../../../../../actions/dialogActions';
import About from '../../About/About';
import ContactForm from '../../ContactPage/ContactForm';
import Faqs from '../../FAQs/FAQs';

const ContactUsMenuItems = () => {
  const dispatch = useDispatch();

  return [
    {
      label: 'Get support help or ask a question',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Support',
            component: <ContactForm fullWidth variant="outlined" />,
          })
        ),
    },
    {
      label: 'Frequently Asked Questions',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Frequently Asked Questions',
            component: <Faqs dialog />,
          })
        ),
    },
    {
      label: 'About',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'About',
            component: <About dialog />,
          })
        ),
    },
    // { Leaving this here for later.
    //   label: 'Show Site Tour',
    //   onClick: () => {
    //     history.push(HOME);
    //     dispatch(setTourSteps(LandingPageTourSteps()));
    //     dispatch(
    //       setTourProps({
    //         preventClose: true,
    //         disableInteraction: true,
    //         closeWithMask: false,
    //       })
    //     );
    //     dispatch(setTourOpen(true));
    //   },
    // },
  ];
};

export default ContactUsMenuItems;
