import React from 'react';
import PropTypes from 'prop-types';
import './ShowMoreOrLessBtn.css';
import { Button } from '@mui/material';

import showMoreOrLess from '../../functions/showMoreOrLess';

const ShowMoreOrLessFacet = ({ onClick, isExpanded }) => {
  const { btnLabel, icon } = showMoreOrLess(isExpanded);
  return (
    <Button onClick={onClick} fullWidth endIcon={icon}>
      {btnLabel}
    </Button>
  );
};

ShowMoreOrLessFacet.propTypes = {
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
};

ShowMoreOrLessFacet.defaultProps = {
  isExpanded: false,
};

export default ShowMoreOrLessFacet;
