import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  CardContent,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import './CRWSDetails.css';
import useCardColor from '../../hooks/useCardColor';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';
import ModalIcons from './components/ModalIcons';
import AssetRating from '../AssetRating/AssetRating';
import AssetDetailsHeader from '../assets/AssetDetailsHeader';
import ReportAProblem from './components/ReportAProblem';
import TabContent from './components/TabContent';

const CRWSDetails = ({
  crws: asset,
  toggleFullscreen,
  toggleHandleClose,
  isFullscreen,
  isModal,
  searchTerm,
}) => {
  const {
    domains,
  } = asset;

  const { Domain: domain } = domains.values;

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};
  const classes = useCardColor(cardDetails.color);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  // const routeAsset = useRouteMatch({
  //   path: ASSET,
  //   exact: true,
  // });

  // useEffect(() => {
  //   let mounted = true;
  //   if (!routeAsset && mounted) {
  //     if (isSmall && !isFullscreen) toggleFullscreen(true);
  //     if (!isSmall && isFullscreen) toggleFullscreen(false);
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSmall]);

  return (
    <>
      <div
        className={classes.gridHeader}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className={classes.gridHeader}
          style={{ textAlign: 'left', paddingLeft: '15px', flexGrow: 1 }}
        >
          <Typography variant="h5">Details</Typography>
        </div>
        <div
          className={classes.gridHeader}
          style={{ textAlign: 'right', flexGrow: 1 }}
        >
          {toggleFullscreen && (
            <ModalIcons
              isModal={isModal}
              isSmall={isSmall}
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
              toggleHandleClose={toggleHandleClose}
            />
          )}
        </div>
      </div>

      <AssetDetailsHeader asset={asset} />

      <div className={classes.content} style={{ padding: '5px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1 }}>
            <ReportAProblem asset={asset} classes={classes} />
          </div>
          <div>
            <AssetRating asset={asset} classes={classes} isDetails />
          </div>
        </div>
      </div>
      <CardContent className={classes.content}>
        <TabContent
          asset={asset}
          cardDetails={cardDetails}
          searchTerm={searchTerm}
        />
      </CardContent>
    </>
  );
};
CRWSDetails.propTypes = {
  crws: PropTypes.object.isRequired,
  toggleFullscreen: PropTypes.func,
  toggleHandleClose: PropTypes.func,
  isFullscreen: PropTypes.bool,
  isModal: PropTypes.bool,
  searchTerm: PropTypes.string,
};

CRWSDetails.defaultProps = {
  isModal: false,
  isFullscreen: false,
  toggleFullscreen: null,
  toggleHandleClose: null,
  searchTerm: undefined,
};

export default CRWSDetails;
