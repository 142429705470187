import React from 'react';
import PropTypes from 'prop-types';
import ControlledAccessIcon from './ControlledAccessIcon/ControlledAccessIcon';
import LightboxButton from './LightboxButton/LightboxButton';
import NotificationIconButton from './NotificationIconButton/NotificationIconButton';
import GraphDialogButton from './GraphDialogButton/GraphDialogButton';
import ShareList from './ShareList/ShareList';
import DownloadButton from './DownloadButton/DownloadButton';
import PdfDialog from '../PdfDialog/PdfDialog';

const QuickSelectIcons = ({
  asset,
  classes,
  cardDetails,
  isSubscribed,
  toggleSubscription,
}) => {
  const { controlledAccess, assetId } = asset;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <ControlledAccessIcon
        controlledAccess={controlledAccess}
        classes={classes}
      />
      <LightboxButton
        title="Favorites"
        aria-label="Add or remove from Favorites"
        assetId={assetId}
        cardColor={classes}
      />
      <NotificationIconButton
        asset={asset}
        classes={classes}
        cardDetails={cardDetails}
        isSubscribed={isSubscribed}
        toggleSubscription={toggleSubscription}
      />
      <DownloadButton asset={asset} classes={classes} />
      <PdfDialog asset={asset} classes={{ root: classes.contrastColor }} />
      <GraphDialogButton
        asset={asset}
        classes={{ root: classes.contrastColor }}
      />
      <ShareList asset={asset} classes={classes} />
    </div>
  );
};

QuickSelectIcons.propTypes = {
  asset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cardDetails: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscription: PropTypes.func.isRequired,
};

export default QuickSelectIcons;
