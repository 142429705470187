import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NOMINATIONS_UNDER_REVIEW } from '../../constants/constants';
import { fetchWithRedux } from '../../functions/fetchAssets';
import {
  setKeywords,
  setOtmmName,
  setSearchTerm,
  setSelectedFilters,
} from '../../models/otmmModel/functions/updateState';
import Assets from '../assets/Assets';

const UnapprovedAssets = ({
  searchTerm,
  setKeywords,
  setSelectedFilters,
  pendingUpdate,
  fetchWithRedux,
  setSearchTerm,
  setOtmmName,
}) => {
  const [initialSearchTerm] = useState(searchTerm);

  useState(() => {
    setSearchTerm('*');
    setKeywords([]);
    setSelectedFilters([]);
    fetchWithRedux(NOMINATIONS_UNDER_REVIEW);
    setSearchTerm(initialSearchTerm);
    setOtmmName(NOMINATIONS_UNDER_REVIEW);
  });

  useEffect(() => {
    let mounted = true;
    const update = async () => {
      setOtmmName(NOMINATIONS_UNDER_REVIEW);
      setSearchTerm('*');

      await fetchWithRedux(NOMINATIONS_UNDER_REVIEW);
    };
    if (pendingUpdate && mounted) update();

    return () => {
      mounted = false;
    };
  }, [pendingUpdate, fetchWithRedux, setOtmmName, setSearchTerm]);

  return <Assets />;
};

UnapprovedAssets.propTypes = {
  pendingUpdate: PropTypes.bool.isRequired,
  fetchWithRedux: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setOtmmName: PropTypes.func.isRequired,
  setKeywords: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  pendingUpdate: state.otmmState.pendingUpdate,
  searchTerm: state.otmmState.otmmParams.term,
});

export default connect(mapStateToProps, {
  fetchWithRedux,
  setSearchTerm,
  setOtmmName,
  setKeywords,
  setSelectedFilters,
})(UnapprovedAssets);
