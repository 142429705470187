import React from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from '../../../../../actions/dialogActions';
import AccessibilityInformation from '../../Accessibility/AccessibilityInformation';
import CopyrightPage from '../../CopyrightPage/CopyrightPage';
import PrivacyPolicy from '../../PrivacyPolicy/PrivacyPolicy';
import Sitemap from '../../Sitemap/Sitemap';
import TermsOfUse from '../../TermsOfUse/TermsOfUse';

const SiteInfoMenuItems = () => {
  const dispatch = useDispatch();
  return [
    {
      label: 'Site Map',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Site Map',
            dialogProps: { maxWidth: 'xs', fullWidth: true },
            component: <Sitemap />,
          })
        ),
    },
    {
      label: 'Privacy Policy',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Privacy Policy',
            dialogProps: { maxWidth: 'xs', fullWidth: true },
            component: <PrivacyPolicy />,
          })
        ),
    },
    {
      label: 'Accessibility Information',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Accessibility Information',
            dialogProps: { maxWidth: 'xs', fullWidth: true },
            component: <AccessibilityInformation />,
          })
        ),
    },
    {
      label: 'Copyright Information',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Copyright Information',
            dialogProps: { maxWidth: 'xs', fullWidth: true },
            component: <CopyrightPage dialog />,
          })
        ),
    },
    {
      label: 'Terms of Use',
      onClick: () =>
        dispatch(
          setDialog({
            open: true,
            title: 'Accessibility Information',
            dialogProps: { maxWidth: 'xs', fullWidth: true },
            component: <TermsOfUse />,
          })
        ),
    },
  ];
};

export default SiteInfoMenuItems;
