import { Check, Clear, Cancel, Help } from '@mui/icons-material';
import { Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, Toolbar, Tooltip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import {
  setTourOpen,
  setTourProps,
  setTourSteps
} from '../../../../actions/tourActions';
import { SEARCH } from '../../../../constants/paths';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import {
  setActivePage,
  setKeywords,
  setPageLimit,
  setSearchType,
  setSelectedFilters
} from '../../models/otmmModel/functions/updateState';
import SaveSearch from '../saveSearch/SaveSearch';
import SearchTourSteps from '../tour/steps/SearchTourSteps';
import './ActionBar.css';
import ActionOptions from './actionOptions/ActionOptions';
import Paginator from './paginator/Paginator';
import ResultsCount from './resultsCount/ResultsCount';

const ActionBar = ({
  keywords,
  totalResults,
  selectedFilters,
  searchTerm,
  activePage,
  pageLimit,
  searchName,
  setSelectedFilters,
  setKeywords,
  setPendingUpdate,
  setActivePage,
  setTourOpen,
  setTourProps,
  setTourSteps,
  pendingUpdate
}) => {

  const [open, setOpen] = useState(false);

  const hasFilters = keywords.length > 0 || selectedFilters.length > 0;
  const searchSteps = SearchTourSteps();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },

    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
      },
    },
    grow: {
      flexGrow: 1,
    },
    sectionMobile: {
      display: 'flex',
      maxHeight: 'auto',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    helpButton: {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.primary.contrastText
          : theme.palette.primary.main,
    },

    sectionDesktopToolbar: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
      },
    },

    paginator: {
      flexGrow: 90,
    },
    settings: {
      flexGrow: 10,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const handleOpenTour = (e) => {
    disableBodyScroll(e);

    setTourSteps(searchSteps);
    setTourProps({
      name: 'Search Tour',
      onAfterOpen: (target) => disableBodyScroll(target),
      onBeforeClose: (target) => enableBodyScroll(target),
      rounded: 5,
      stepInteraction: true,
      disableDotsNavigation: false,
      showButtons: true,
      startAt: 0,
      disableInteraction: false,
      accessibilityOptions: { closeButtonAriaLabel: 'Close' },
    });
    setTourOpen(true);
  };

  const renderMobile = (
    <>
      <div className={classes.sectionMobile}>
        <ResultsCount
          pageLimit={pageLimit}
          activePage={activePage}
          count={totalResults}
        />
      </div>

      <div className={classes.sectionMobile}>
        <div className={classes.paginator}>
          <Paginator
          // activePage={activePage}
          // onPageChange={onPageChange}
          // totalResults={totalResults}
          // pageLimit={pageLimit}
          />
        </div>
        <div className={classes.settings}>
          <ActionOptions />
        </div>
      </div>
      <div className={classes.sectionMobile}>
        <SaveSearch
          keywords={keywords}
          searchTerm={searchTerm}
          selectedFilters={selectedFilters}
          searchName={searchName}
        />
        {hasFilters && (
          <>
            <Button
              sx={{color: isDark ? 'primary.contrastText' : 'primary.main'}}
              onClick={() => {
                setOpen(true);
              }}
            >
              Clear Filters
            </Button>
           
          </>
        )}
      </div>
    </>
  );

  const renderDesktop = (
    <>
      <Toolbar disableGutters className={classes.sectionDesktopToolbar}>
        <SaveSearch
          keywords={keywords}
          searchTerm={searchTerm}
          selectedFilters={selectedFilters}
          searchName={searchName}
        />
        {hasFilters && (
          <>
            <Button
              sx={{color: isDark ? 'primary.contrastText' : 'primary.main'}}
              startIcon={<Clear />}
              onClick={() => {
                setOpen(true);
              }}
              disabled={pendingUpdate}
            >
              Clear Filters
            </Button>
          </>
        )}
        <div className={classes.grow} />
        <div className={classes.sectionDesktop} style={{ minWidth: '450px' }}>
          <Box style={{ margin: 'auto' }}>
            <ResultsCount />
          </Box>
          <Paginator />
        </div>
        <div>
          {matchPath(window.location.pathname, SEARCH) && (
            <Tooltip title="Start Search Tour" aria-label="Start Search Tour">
              <Button
                sx={{color: isDark ? 'primary.contrastText' : 'primary.main'}}
                className={classes.helpButton}
                onClick={handleOpenTour}
                label="Start Search Tour"
              >
                <Help />
              </Button>
            </Tooltip>
          )}
        </div>
        <div className={classes.sectionDesktop} tour="view-button">
          <ActionOptions />
        </div>
      </Toolbar>
    </>
  );

  return (
    <div className={classes.grow}>
      <CssBaseline />

      {renderMobile}
      {renderDesktop}
  <Dialog 
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogContent>
            Are you sure you wish to clear these filters?
          </DialogContent>
          <DialogActions>
          <Button
              sx={{color: isDark ? 'primary.contrastText' : 'primary.main'}}
              onClick={() => {
                setSelectedFilters([]);
                setKeywords([]);
                setActivePage(1);
                setPendingUpdate(true);
                setOpen(false);
              }}
              startIcon={<Check />}
            >
              Yes
            </Button>
            <Button
              sx={{color: isDark ? 'primary.contrastText' : 'primary.main'}}
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<Cancel />}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};

ActionBar.propTypes = {
  totalResults: PropTypes.number.isRequired,
  keywords: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  searchName: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setKeywords: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setTourOpen: PropTypes.func.isRequired,
  setTourProps: PropTypes.func.isRequired,
  setTourSteps: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    totalResults: state.otmmState.pagination.totalResults,
    keywords: state.otmmState.otmmParams.keywords,
    selectedFilters: state.otmmState.filters.selected,
    searchTerm: state.otmmState.otmmParams.term,
    activePage: state.otmmState.pagination.activePage,
    currentPage: state.otmmState.pagination.currentPage,
    pageLimit: state.otmmState.pagination.pageLimit,
    searchName: state.otmmState.otmmParams.name,
    open: state.tour.open,
    tourProps: state.tour.props,
    pendingUpdate: state.otmmState.pendingUpdate
  });

const mapDispatchToProps = {
  setActivePage,
  setSearchType,
  setPageLimit,
  setKeywords,
  setSelectedFilters,
  setPendingUpdate,
  setTourOpen,
  setTourProps,
  setTourSteps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionBar);
