import { CRWS_DEFAULT, SEARCH_OTMM } from '../../../../constants/searches';
import {
  ASSETS,
  DEFAULT_ORDER,
  DEFAULT_RELEVANCE,
  FILTERS,
  LOADING,
  OTMM_PARAMS,
  PAGINATION,
  SORT,
  PENDING_UPDATE,
} from '../../constants/constants';
import { OTMM_ERROR } from './actions/otmmActionTypes';

const paginationModel = (pagination) => ({
    activePage: pagination.activePage || 1,
    currentPage: pagination.currentPage || 1,
    pageLimit: pagination.pageLimit || 50,
    totalResults: pagination.totalResults || 0,
  });
const sortModel = (sort) => ({
    id: sort.id || DEFAULT_RELEVANCE,
    order: sort.order || DEFAULT_ORDER,
  });

const filtersModel = (filters) => ({
    allFilters: filters.allFilters || [],
    selected: filters.selected || [],
  });

const otmmParamsModel = (otmmParams) => ({
    term: otmmParams.term || '',
    name: otmmParams.name || CRWS_DEFAULT,
    type: otmmParams.type || SEARCH_OTMM,
    custom: otmmParams.custom || false,
    savedSearchId: otmmParams.savedSearchId || undefined,
    keywords: otmmParams.keywords || [],
  });

export const createOtmmModel = ({
  filters,
  pagination,
  assets,
  sort,
  otmmParams,
  loading,
  pendingUpdate,
}) => 
  /** Pass an empty {} to create default model */
   ({
    [ASSETS]: assets || [],
    [FILTERS]: filters ? filtersModel(filters) : filtersModel({}),
    [PAGINATION]: pagination
      ? paginationModel(pagination)
      : paginationModel({}),
    [SORT]: sort ? sortModel(sort) : sortModel({}),
    [OTMM_PARAMS]: otmmParams
      ? otmmParamsModel(otmmParams)
      : otmmParamsModel({}),
    [LOADING]: loading || false,
    [PENDING_UPDATE]: pendingUpdate || false,
    [OTMM_ERROR]: undefined,
  })
;

export const updateOtmmModel = (obj, model) => {
  let updatedModel = model;
  // Get all of the keys sent and try to update them accordingly.

  if (obj instanceof Object) {
    Object.entries(obj).forEach(([key, value]) => {
      updatedModel = { ...updatedModel, [key]: value };
    });
    return updatedModel;
  }
  return new Error('Cannot update. Pass Object {key: value} or {key: {}}');
};
