import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// Dialog button display a button that opens a dialog with custom content, title, action buttons, and callback.
// children - The content to display in the dialog window.
// label - The label for the button, default is "Open Dialog",
// title - The title for the dialog.
// dialogProps - custom props to be passed to the dialog.
// callback - function to be called when the user closes the dialog.
// actionButtons - an array of button elements that will be displayed at the bottom of the dialog.
// otherProps - Additional props to the primary "button" component.
const DialogButton = ({
  children,
  component: Component,
  content,
  title,
  dialogProps,
  callback,
  actionButtons,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (callback) callback();
    setOpen(false);
  };
  return (
    <>
      <Component onClick={() => setOpen(true)} {...otherProps}>
        {children}
      </Component>
      <Dialog open={open} onClose={handleClose} {...dialogProps}>
        <DialogTitle sx={{ color: 'primary.light' }}>{title}</DialogTitle>
        <DialogContent style={{ paddingTop: '24px' }}>{content}</DialogContent>
        <DialogActions>
          {actionButtons.map((actionButton) => actionButton)}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
DialogButton.propTypes = {
  children: PropTypes.any.isRequired,
  content: PropTypes.any,
  title: PropTypes.string,
  dialogProps: PropTypes.object,
  callback: PropTypes.func,
  actionButtons: PropTypes.array,
  component: PropTypes.object,
};
DialogButton.defaultProps = {
  content: 'Content',
  title: 'Dialog Title',
  dialogProps: { maxWidth: 'lg', fullWidth: true },
  callback: undefined,
  actionButtons: [],
  component: Button,
};
export default DialogButton;
