import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { indigo } from '@mui/material/colors';

export default function StyledLinearProgress({ color }) {
  const StyledLinear = withStyles(() => ({
    bar: {
      backgroundColor: color[500],
    },
  }))(LinearProgress);

  return <StyledLinear />;
}

StyledLinearProgress.defaultProps = {
  color: indigo,
};

StyledLinearProgress.propTypes = {
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
