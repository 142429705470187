/* eslint-disable */
import { AddComment, Comment } from '@mui/icons-material';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import DialogButton from '../DialogButton/DialogButton';
import PageHeader from '../PageHeader/PageHeader';

function Glossary() {
  const defaultOptions = [
    {
      term: 'abstraction',
      definition:
        '1. The process of selecting the essential aspects of a simuland to be represented in a model or simulation while excluding those aspects that are not relevant to the purpose of the model or simulation. The set of elements produced by this process. 2. A view of an object that focuses on the information relevant to a particular purpose and ignores the remainder of the information.',
      comments: [
        'Mispelling simuland should be simulated.',
        'We should add another definition...',
        'Another comment...',
      ],
    },
    {
      term: 'accreditation',
      definition:
        'The official certification that a mdel or simulation and its associated data are acceptable for use for a specific purpose.',
      comments: ['This needs to be changed because model is mispelled.'],
    },
    {
      term: 'accreditation agent',
      definition:
        'The individual, group, or organization designated to conduct an accreditation assessment for a model, simulation, and associated data.',
      comments: ['some comment'],
    },
    {
      term: 'accreditation authority',
      definition:
        'The organization or individual responsible to approve the use of models, simulations, and their associated data for a particular application.',
    },
    {
      term: 'accreditation criteria',
      definition:
        'A set of standards that a particular model, simulation, or federation must meet to be accredited for a specific purpose.  Also known as acceptability criteria.',
    },
    {
      term: 'accreditation plan',
      definition:
        'The plan of action for certifying a model, simulation, or federation of models and simulations and its associated data as acceptable for specific purposes. The accreditation plan specifies the reviews, testing, and other accreditation assessment processes.',
    },
    {
      term: 'accuracy',
      definition:
        'The plan of action for certifying a model, simulation, or federation of models and simulations and its associated data as acceptable for specific purposes. The accreditation plan specifies the reviews, testing, and other accreditation assessment processes.',
    },
    {
      term: 'activity model',
      definition:
        'A process model that describes the functional activity under examination in terms of inputs, transforms, outputs, and controls.',
    },
    {
      term: 'activity-based simulation',
      definition:
        'A discrete simulation that represents the components of a system as they proceed from activity to activity; for example, a simulation in which a manufactured product moves from station to station in an assembly line.',
    },
    {
      term: 'adaptive system',
      definition:
        'A system that is able to modify its behavior according to changes in its external environment or internal components of the system itself.',
    },
    {
      term: 'agent',
      definition:
        'A computer system capable of autonomous action - of deciding for itself what behaviors are needed to satisfy its design objectives, and capable of interacting with other agents (e.g., a 3D character that exhibits human or human-like behavior).',
    },
    {
      term: 'agent-based simulation',
      definition:
        'A group of entities or a  group of other aggregates considered as a single unit. The substitution of the word "unit" is used to avoid phrases like "aggregate aggregate.',
    },
    {
      term: 'Aggregate Level Simulation Protocol (ALSP)',
      definition:
        'A family of simulation interface protocols and supporting infrastructure software that permit the integration of distinct simulations and war games. Combined, the interface protocols and software enable large-scale, distributed simulations and war games of different domains to interact at the combat object and event level.',
    },
    {
      term: 'aggregation',
      definition:
        'The process of grouping entities while preserving the salient effects of entity behavior and interaction while grouped. Contrast with: Disaggregation.',
    },
    {
      term: 'algorithm',
      definition:
        'A procedure or a set of well-defined steps, rules, or processes used to solve a problem.',
    },
    {
      term: 'aliasing:',
      definition: `Displayed data having jagged edges, as a result of a discrete approach to scan conversion in which each pixel either is replaced with the primitive's color or is left unchanged`,
    },
    {
      term: 'analysis:',
      definition:
        'The systematic, thoughtful, and rigorous employment of the scientific method to examine a problem, scenario, or issue in order to gain insights into relationships between constituent components, understand underlying principles, or answer a specific set of pre-identified questions.',
    },
    {
      term: 'analytical model:',
      definition:
        'A model consisting of a set of mathematical equations or logical connections / representation; e.g., a system of solvable equations or logical links that represent a specific behavior of the real world to be simulated. ',
    },
    {
      term: 'Application Lifecycle Management (ALM) ',
      definition: `Application lifecycle management (ALM) is the product lifecycle management (governance, development, and maintenance) of computer programs. It encompasses requirements management, software architecture, computer programming, software testing, software maintenance, change management, continuous integration, project management, and release management. (Wikipedia.org)`,
    },
  ];

  const [options, setOptions] = useState(defaultOptions);

  const handleChange = (index) => {
    console.log('index', index);
    const tempArray = [...options];
    console.log('tempArray === options?', tempArray === options);
  };

  return (
    <>
      <PageHeader title="Glossary" />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table aria-label="glossary table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: (theme) => theme.accent.blue,
                  textTransform: 'uppercase',
                  fontFamily: 'Martel',
                  fontSize: '1.5rem',
                  fontWeight: '800',
                }}
              >
                Term
              </TableCell>
              <TableCell
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: (theme) => theme.accent.blue,

                  fontFamily: 'Martel',
                  fontSize: '1.5rem',
                  fontWeight: '800',
                }}
              >
                Definition
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  textTransform: 'uppercase',
                  backgroundColor: (theme) => theme.accent.blue,

                  fontFamily: 'Martel',
                  fontSize: '1.5rem',
                  fontWeight: '800',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((option, index) => {
              const { term, definition, comments } = option;
              return (
                <TableRow key={`${index}${term}${definition}`}>
                  <TableCell>{term}</TableCell>
                  <TableCell>{definition}</TableCell>
                  <TableCell align="right">
                    <DialogButton
                      component={IconButton}
                      size="small"
                      title={term}
                      content={
                        <>
                          <TextField
                            fullWidth
                            multiline
                            placeholder="Add a new comment"
                            rows={5}
                          />
                          <div style={{ float: 'right', marginTop: '12px' }}>
                            <Button
                              variant="outlined"
                              onClick={() => handleChange(index)}
                            >
                              Add comment
                            </Button>
                          </div>
                        </>
                      }
                    >
                      <AddComment />
                    </DialogButton>
                    {comments && comments.length > 0 && (
                      <DialogButton
                        component={IconButton}
                        size="small"
                        title={term}
                        content={comments.map((comment) => (
                          <Typography paragraph>{comment}</Typography>
                        ))}
                      >
                        <Comment />
                      </DialogButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Glossary;
