import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Grid, IconButton, TableCell, TableRow } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import Pop from '../pop/Pop';
import getDateIndex from '../../hooks/getDateIndex';
import useCardColor from '../../hooks/useCardColor';
import useSubscription from '../../hooks/useSubscription';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import AssetExpansionDetails from './AssetExpansionDetails';
import AssetRating from '../AssetRating/AssetRating';
import QuickSelectIcons from '../QuickSelectIcons/QuickSelectIcons';
import AssetAvatar from '../AssetAvatar/AssetAvatar';
import AssetTitle from './AssetTitle';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function CrwsRow({ asset }) {
  const [open, setOpen] = React.useState(false);
  const {
    subscribed,
    domains,
    contentMaturityReason,
    contentMaturityColor,
    versions,
  } = asset;

  const { DateEffective: dateEffective } = versions.values;
  const { Domain: domain } = domains.values;

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};

  const cardClasses = useCardColor(cardDetails.color);
  const { toggleSubscription, isSubscribed } = useSubscription(subscribed);

  const dateIndex = getDateIndex(dateEffective);
  const classes = useRowStyles();
  return (
    <>
      <TableRow className={classes.root} style={{ cursor: 'pointer' }}>
        <TableCell onClick={() => setOpen(!open)}>
          <Pop content="Expand">
            <IconButton
              size="small"
              aria-label="expand row"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Pop>
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <AssetAvatar
            domain={domain}
            classes={cardClasses}
            cardDetails={cardDetails}
            elevation={3}
            style={{ width: '28px' }}
          />
        </TableCell>
        <TableCell
          size="small"
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        >
          {/* <PdfDialog asset={asset}> */}
          <AssetTitle asset={asset} cardDetails={cardDetails} />
          {/* </PdfDialog> */}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} size="small">
          {dateEffective.values[dateIndex]}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <VerifiedIcon
            size={50}
            tabIndex={0}
            reason={contentMaturityReason.value}
            color={contentMaturityColor.value}
            aria-hidden="false"
          />
        </TableCell>

        <TableCell colSpan={6} style={{ padding: 0 }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <AssetRating asset={asset} classes={classes} />
            </Grid>

            <Grid item>
              <QuickSelectIcons
                asset={asset}
                classes={classes}
                cardDetails={cardDetails}
                isSubscribed={isSubscribed}
                toggleSubscription={toggleSubscription}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AssetExpansionDetails asset={asset} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CrwsRow.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default CrwsRow;
