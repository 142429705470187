import { otmmUrlEncoded as otmm } from '../../otmm/apis/otmm';
import createRendition from './createRendition';
import createOtmmAsset from './createOtmmAsset';

const getManifest = (file) => {
  const manifest = JSON.stringify({
    upload_manifest: {
      master_files: [
        {
          file: {
            file_name: file.name,
            file_type: file.type,
          },
        },
      ],
    },
  });

  return manifest;
};

const createAsset = async ({
  jobId,
  modelId,
  values,
  file,
  securityPolicyIds,
}) => {
  await createRendition({ jobId, file });

  const data = new FormData();
  data.append('import_job_id', jobId);
  data.append(
    'manifest',
    new Blob([getManifest(file)], {
      type: 'application/json; charset=utf-8',
    })
  );
  data.append(
    'asset_representation',
    new Blob([await createOtmmAsset({ modelId, values, securityPolicyIds })], {
      type: 'application/json; charset=utf-8',
    })
  );
  data.append('no_content', true);
  data.append('job_name', jobId);
  data.append('file_name', file.name);

  otmm.put(`/jobs/imports/${jobId}`, data);
};

export default createAsset;
