import _ from 'lodash';

const isSuccessfulResponse = (id, response) =>
  _.get(
    response,
    'data.bulk_asset_result_representation.bulk_asset_result.successful_object_list',
    []
  ).includes(id);

export default isSuccessfulResponse;
