import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';
import AssetDetailsPane from '../../assets/AssetDetailsPane';
import AssetReferencesPane from '../../assets/AssetReferencesPane';
import ReferencesAndRelationships from '../../../pages/ReferencesAndRelationships/ReferencesAndRelationships';
import DetailsContent from '../../detailsContent/DetailsContent';
import PdfViewer from '../../PdfViewer/PdfViewer';

function TabContent({ asset, cardDetails, searchTerm }) {
  const { copyright, assetId } = asset;
  return (
    <div>
      <DetailsContent
        asset={asset}
        searchTerm={searchTerm}
        color={cardDetails.color}
      >
        <div>
          {copyright.value && copyright.value !== 'Public Domain' && (
            <Alert severity="warning">
              <AlertTitle>Copyright</AlertTitle>
              Please be advised before printing or downloading this resource.
              This document is protected by {copyright.value}.
            </Alert>
          )}
          <PdfViewer asset={asset} />
        </div>

        <div style={{ minHeight: '400px' }}>
          <AssetDetailsPane asset={asset} />
        </div>
        <div>
          <>
            <ReferencesAndRelationships
              assetId={assetId}
              cardColor={cardDetails.color}
            />
          </>
        </div>
        <div>
          <AssetReferencesPane asset={asset} />
        </div>
      </DetailsContent>
    </div>
  );
}

TabContent.propTypes = {
  asset: PropTypes.object.isRequired,
  cardDetails: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
};

TabContent.defaultProps = {
  searchTerm: undefined,
};

export default TabContent;
