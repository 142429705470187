import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import useUserInfo from '../../features/auth/hooks/useUserInfo';
import Unauthorized from '../../Unauthorized';

function SecureRoute({ accessRoles, userGroups, children, ...otherProps }) {
  const { getUserRole, isUserGuest, isUserAdmin, getUserGroups } =
    useUserInfo();
  const { userGroupList } = getUserGroups();

  const hasAccess =
    (isUserAdmin() ||
      accessRoles.includes(getUserRole()) ||
      userGroups.some((group) => userGroupList.includes(group))) &&
    !isUserGuest();

  return (
    <Route {...otherProps}>{!hasAccess ? <Unauthorized /> : children}</Route>
  );
}

SecureRoute.propTypes = {
  children: PropTypes.any.isRequired,
  accessRoles: PropTypes.array,
  userGroups: PropTypes.array,
};

SecureRoute.defaultProps = {
  accessRoles: [],
  userGroups: [],
};

export default SecureRoute;
