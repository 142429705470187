import {
  TYPE_OPEN_TOUR,
  TYPE_SET_TOUR_PROPS,
  TYPE_UPDATE_TOUR_STEPS,
} from '../actions/tourActions';

export const tourDefaultState = {
  steps: undefined,
  open: false,
  props: { name: 'Global Tour', ref: { current: {} } },
};

export default (state = tourDefaultState, { type, payload }) => {
  switch (type) {
    case TYPE_UPDATE_TOUR_STEPS: {
      return { ...state, steps: [...payload] };
    }
    case TYPE_OPEN_TOUR: {
      return { ...state, open: payload };
    }
    case TYPE_SET_TOUR_PROPS: {
      return { ...state, props: { ...tourDefaultState.props, ...payload } };
    }

    default:
      return state;
  }
};
