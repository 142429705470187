import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import { Assignment } from '@mui/icons-material';
import useCardColor from '../../hooks/useCardColor';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';

import AssetAvatar from '../AssetAvatar/AssetAvatar';
import AssetTitle from './AssetTitle';
import PdfDialog from '../PdfDialog/PdfDialog';
import DownloadButton from '../QuickSelectIcons/DownloadButton/DownloadButton';
import ControlledAccessIcon from '../QuickSelectIcons/ControlledAccessIcon/ControlledAccessIcon';
import ReviewBoardPage from '../ReviewBoard/ReviewBoardPage';
import ApprovalIcon from '../ApprovalIcon/ApprovalIcon';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const CrwsUnapprovedRow = ({ asset }) => {
  const [open, setOpen] = React.useState(false);
  const { justification, domains, controlledAccess } = asset;
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    dispatch(setPendingUpdate(true));
    setOpen(false);
  };

  const { Domain: domain } = domains.values;

  const cardDetails = domain && domain.values ? GetDomainTheme(domain) : {};

  const cardClasses = useCardColor(cardDetails.color);

  const classes = useRowStyles();
  return (
    <>
      <TableRow className={classes.root} style={{ cursor: 'pointer' }}>
        {/* <TableCell onClick={() => setOpen(!open)}>
          <Pop content="Expand">
            <IconButton
              size="small"
              aria-label="expand row"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Pop>
        </TableCell> */}
        <TableCell onClick={() => setOpen(!open)}>
          <ApprovalIcon reviewBoard={asset.reviewBoard} />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <AssetAvatar
            domain={domain}
            classes={cardClasses}
            cardDetails={cardDetails}
            elevation={3}
            style={{ width: '28px' }}
          >
            <Assignment />
          </AssetAvatar>
        </TableCell>

        <TableCell
          size="small"
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        >
          <AssetTitle asset={asset} cardDetails={cardDetails} />
        </TableCell>

        <TableCell onClick={() => setOpen(!open)} size="small">
          {justification.value}
        </TableCell>

        <TableCell colSpan={6} style={{ padding: 0 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ControlledAccessIcon
              controlledAccess={controlledAccess}
              classes={classes}
            />

            <DownloadButton asset={asset} classes={classes} />
            <PdfDialog asset={asset} />
          </div>
        </TableCell>
      </TableRow>

      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AssetExpansionDetails asset={asset} />
          </Collapse>
        </TableCell>
      </TableRow> */}
      <ReviewBoardPage
        asset={asset}
        open={open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      />
      {/* <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleWithClose onClose={handleCloseDialog}>
          {asset.title.value || 'Unnamed Resource'}
        </DialogTitleWithClose>
        <DialogContent>
          <ReviewBoardPage asset={asset} />
        </DialogContent>
      </Dialog> */}
    </>
  );
};

CrwsUnapprovedRow.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default CrwsUnapprovedRow;
