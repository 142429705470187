import React from 'react';
import { Typography, useTheme } from '@mui/material';

const SearchTourSteps = () => {
  const theme = useTheme();
  return [
    {
      content: (
        <>
          <Typography>
            Use the search bar to begin your keyword search.
          </Typography>
        </>
      ),
      selector: '[tour="search-bar"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography>
            Use the keyword filter to narrow your search and then further refine
            with filters.
          </Typography>
        </>
      ),
      selector: '[tour="filters-sidemenu"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled, checking for element first
        if (node) node.focus();
      },
    },
    {
      content: (
        <>
          <Typography>
            Refine even further by deselecting related keywords and topics.
          </Typography>
        </>
      ),
      selector: '[tour="filters-bar"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography>
            Toggle how your data is listed and sort by various options. List and
            Card are the two view options.
          </Typography>
        </>
      ),
      selector: '[tour="view-button"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
  ];
};

export default SearchTourSteps;
