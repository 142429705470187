import { TYPE_UPDATE_DIALOG } from '../actions/dialogActions';

export const defaultDialogState = {
  component: undefined,
  open: false,
  title: undefined,
  actions: undefined,
  help: undefined,
  hasCloseButton: true,
  dialogProps: {},
};

export default (state = defaultDialogState, { type, payload }) => {
  switch (type) {
    case TYPE_UPDATE_DIALOG:
      return { ...state, ...payload };

    default:
      return state;
  }
};
