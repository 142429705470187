import React from 'react';
import { AppBar, Alert, Slide } from '@mui/material';
import { SITE_NAME_ABBR } from '../../constants/constants';

const CookieDisclaimer = () => {
  const KEY = 'isAccepted';
  const [cookieStatus, setCookieStatus] = React.useState(
    localStorage.getItem(KEY)
  );
  const [open, setOpen] = React.useState(Boolean(!cookieStatus));

  function setCookieFlag(flag) {
    localStorage.setItem(KEY, flag);
    setCookieStatus(flag);
  }

  const handleOnAccept = () => {
    setOpen(!open);
    setCookieFlag(true);
  };

  function getValidateFlag() {
    if (cookieStatus == null || undefined) {
      return false;
    }
    return true;
  }

  return (
    <>
      {!getValidateFlag() && (
        <Slide in={open} direction="up" timeout={1500}>
          <AppBar
            position="fixed"
            color="transparent"
            style={{
              top: 'auto',
              padding: '5px',
              bottom: 0,
              zIndex: 1800,
            }}
          >
            <Alert severity="info" onClose={handleOnAccept}>
              This website uses session cookies and similar technologies, which
              are small files or pieces of text that download to a device when a
              visitor accesses a website or app. This information might be about
              you, your preferences, or your device and is mostly used to make
              the site work as you expect it to. The information does not
              usually directly identify you, but it can give you a more
              personalized web experience. We use these statistics to learn
              about site traffic and activity in order to improve the{' '}
              {SITE_NAME_ABBR} and its value to you as a user. For example, user
              data is aggregated to provide information regarding which browsers
              and versions are being used to access {SITE_NAME_ABBR}, which in
              turn helps inform program decisions for outdated or deprecated
              browsers (e.g., older versions of IE) and additional functional
              testing areas.&emsp;
            </Alert>
          </AppBar>
        </Slide>
      )}
    </>
  );
};

export default CookieDisclaimer;
