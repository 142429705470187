/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, info) {
    // Used to log errors when the application fails.
    // TODO - Make service to log these errors, temporarily logging to console.
    // eslint-disable-next-line
  }

  render() {
    const { hasError, error } = this.state;
    const { children, message, component: Component } = this.props;
    if (hasError) {
      // Temporarily displaying the error message in a paper. This would be better to display as dialog. Typical usage is to render <>Something went wrong</> in place of the component that failed to load.

      return (
        <>
          <Component style={{ padding: '15px' }}>
            {message} - {error.message}
          </Component>
        </>
      );
    }
    return <>{children}</>;
  }
}
ErrorBoundary.defaultProps = {
  message: 'An error occurred...',
  component: Paper,
};
ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  message: PropTypes.any,
  component: PropTypes.any,
};

export default ErrorBoundary;
