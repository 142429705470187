function clearValues(values) {
  const tempState = { ...values };
  Object.keys(tempState).forEach((key) => {
    const field = tempState[key];
    if (field.value) {
      if (typeof field.value === 'object') {
        field.value = [];
      } else field.value = '';
    }
  });

  return tempState;
}

export default clearValues;
