import authStorage from './authStorage';

import isAuthResponseValid from './isAuthResponseValid';

const checkSession = () => {
  const authResponse = authStorage.get();
  if (isAuthResponseValid(authResponse)) {
    return authResponse;
  }

  return null;
};

export default checkSession;
