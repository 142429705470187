import CssBaseline from '@mui/material/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store';

import ScrollToTop from './features/Home/components/scrollToTop/ScrollToTop';
import AuthProvider from './features/auth/components/AuthProvider';
import ThemeWrapper from './ThemeWrapper';
import MaintenancePage from './features/Home/components/MaintenancePage/MaintenancePage';
import UserAuthorizationCheck from './UserAuthorizationCheck';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <AuthProvider navigate={history.push}>
        <ThemeWrapper>
          <CssBaseline />
          <MaintenancePage>
            <UserAuthorizationCheck />
          </MaintenancePage>
        </ThemeWrapper>
      </AuthProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
