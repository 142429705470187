import React from 'react';
import PropTypes from 'prop-types';
import { Link as MatLink, Typography } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import {
  ABOUT,
  CONTACT,
  COPYRIGHT,
  FAQS,
  FAVORITES,
  HOME,
  NOMINATE,
  NOTIFICATIONS,
  PASSWORD_REST,
  PROFILE,
  RELEASE_PAGE,
  SAVED_SEARCHES_TABLE,
} from '../../../../constants/paths';

function Sitemap({ onClose }) {
  const history = useHistory();

  const handleClose = (path) => {
    history.push(path);
    if (onClose) onClose();
  };

  return (
    <>
      <Typography variant="h6">Main Pages</Typography>

      <ul>
        <li>
          <MatLink component={Link} to={HOME} onClick={() => handleClose(HOME)}>
            Home
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">User Settings and Resources</Typography>
      <ul>
        <li>
          <MatLink
            component={Link}
            to={PROFILE}
            onClick={() => handleClose(PROFILE)}
          >
            Profile
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={NOTIFICATIONS}
            onClick={() => handleClose(NOTIFICATIONS)}
          >
            Notifications
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={FAVORITES}
            onClick={() => handleClose(FAVORITES)}
          >
            Favorites
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={SAVED_SEARCHES_TABLE}
            onClick={() => handleClose(SAVED_SEARCHES_TABLE)}
          >
            Saved Searches
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={PASSWORD_REST}
            onClick={() => handleClose(PASSWORD_REST)}
          >
            Password Reset
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">Contact Us</Typography>
      <ul>
        <li>
          <MatLink
            component={Link}
            to={NOMINATE}
            onClick={() => handleClose(NOMINATE)}
          >
            Nominate a Resource
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={CONTACT}
            onClick={() => handleClose(CONTACT)}
          >
            Contact Us
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">Site Information</Typography>
      <ul>
        <li>
          <MatLink
            component={Link}
            to={ABOUT}
            onClick={() => handleClose(ABOUT)}
          >
            About
          </MatLink>
        </li>
        <li>
          <MatLink component={Link} to={FAQS} onClick={() => handleClose(FAQS)}>
            FAQs
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={COPYRIGHT}
            onClick={() => handleClose(COPYRIGHT)}
          >
            Copyright
          </MatLink>
        </li>
        <li>
          <MatLink
            component={Link}
            to={RELEASE_PAGE}
            onClick={() => handleClose(RELEASE_PAGE)}
          >
            Release Notes
          </MatLink>
        </li>
      </ul>
    </>
  );
}
Sitemap.defaultProps = {
  onClose: undefined,
};
Sitemap.propTypes = {
  onClose: PropTypes.func,
};

export default Sitemap;
