// Function returns the appropriate content maturity badge to be used for labeling
import React from 'react';
import PropTypes from 'prop-types';
import SimpleVerifiedIcon from '../components/VerifiedIcon/SimpleVerifiedIcon';

const getContentMaturityBadge = (text, count) => {
  const contentMatRange = text;

  if(contentMatRange === '1 - 2' || contentMatRange === '2 - 3'){
    // Return a bronze colored badge
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}> 
        {contentMatRange} <SimpleVerifiedIcon size={30} color='#c77b30'/> { count ? <>({count})</> : null }
      </div> 
    ); 
  }

  // Return a silver colored badge
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}> 
      {contentMatRange} <SimpleVerifiedIcon size={30} color='#919090'/> { count ? <>({count})</> : null }
    </div> 
  ); 
};

getContentMaturityBadge.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number
};

getContentMaturityBadge.defaultProps = {
  count: undefined
};

export default getContentMaturityBadge;