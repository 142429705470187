import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ASSET } from '../../../../constants/paths';
import {
  REFERENCES_ID_NUMBER,
  REFERENCES_REF_UOI_ID,
  REFERENCES_TYPE,
} from '../../constants/assetFields';
import { TITLE } from '../../constants/constants';
import getTableDataFromTabularField from '../../hooks/getTableDataFromTabularField';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import Pop from '../pop/Pop';
import VerticalTabsTable from '../VerticalTabsTable/VerticalTabsTable';

const AssetReferencesPane = ({ asset }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [dialogSettings, setDialogSettings] = useState({
    open: false,
    onConfirm: null,
    title: '',
  });

  const tableData = getTableDataFromTabularField({
    fieldValues: asset.references.values,
    flex: 1,
    exclude: REFERENCES_ID_NUMBER,
  });
  const { columns, rows } = tableData;
  const referencedBy = rows.filter(
    (row) => row[REFERENCES_TYPE] === 'Referenced By'
  );
  const referenceFrom = rows.filter(
    (row) => row[REFERENCES_TYPE] === 'References'
  );

  const externalRows = rows.filter(
    (row) => row[REFERENCES_TYPE] === 'External'
  );

  const otherRows = rows.filter((row) => row[REFERENCES_TYPE] === 'Other');
  const hiddenColumns = [REFERENCES_TYPE, REFERENCES_REF_UOI_ID];
  const cols = columns.filter((col) => !hiddenColumns.includes(col.field));

  cols.sort((a, b) => {
    const acol = a.field.toLowerCase();
    const bcol = b.field.toLowerCase();

    if (acol < bcol) return -1;

    if (acol > bcol) return 1;

    return 0;
  });

  for (let i = 0; i < cols.length; i += 1) {
    if (cols[i].field === TITLE) {
      cols.splice(i, 1);
      cols.splice(0, 0, {
        field: { TITLE },
        headerName: 'Reference Title',
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          const assetId = row[REFERENCES_REF_UOI_ID];
          let link = assetId;
          let external = true;

          if (link && (link.includes('www') && !link.includes('http')))
            link = `http://${assetId}`;

          if (link && (!link.includes('www') || !link.includes('http'))) {
            link = ASSET.replace(':assetId', assetId);
            external = false;
          }
          return (
            <>
              {params.row[TITLE] && params.row[REFERENCES_REF_UOI_ID] ? (
                <Pop
                  content={<Typography>{params.row[TITLE]}</Typography>}
                  style={{ cursor: 'pointer' }}
                >
                  <Link
                    color={isDark ? 'secondary' : 'primary'}
                    style={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      padding: 0,
                    }}
                    href={link}
                    rel="noopener noreferrer"
                    target={external ? '_blank' : '_self'}
                  >
                    <Typography noWrap>
                      {params.row[TITLE] ? params.row[TITLE] : ''}
                    </Typography>
                  </Link>
                </Pop>
              ) : (
                <Pop content={<Typography>{params.row[TITLE]}</Typography>}>
                  <Typography noWrap>
                    {params.row[TITLE] ? params.row[TITLE] : ''}
                  </Typography>
                </Pop>
              )}
            </>
          );
        },
      });
    }
  }

  const tables = [];
  if (referenceFrom.length > 0)
    tables.push({
      name: 'This references...',
      columns: cols,
      rows: referenceFrom,
    });
  if (referencedBy.length > 0)
    tables.push({
      name: 'This is referenced by...',
      columns: cols,
      rows: referencedBy,
    });

  if (externalRows.length > 0)
    tables.push({ name: 'External', columns: cols, rows: externalRows });
  if (otherRows.length > 0)
    tables.push({ name: 'Other', columns: cols, rows: otherRows });

  return tables.length === 0 ? (
    <Typography>No references found...</Typography>
  ) : (
    <div>
      <VerticalTabsTable tables={tables} />
      <Dialog
        open={dialogSettings.open}
        onClose={() => setDialogSettings({ ...dialogSettings, open: false })}
      >
        <DialogTitleWithClose
          onClose={() => setDialogSettings({ ...dialogSettings, open: false })}
        >
          {dialogSettings.title}
        </DialogTitleWithClose>
        <DialogContent>
          Are you sure you wish to view this resource?
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogSettings.onConfirm}>Yes</Button>
          <Button
            onClick={() =>
              setDialogSettings({ ...dialogSettings, open: false })
            }
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AssetReferencesPane.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetReferencesPane;
