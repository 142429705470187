import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Slide,
} from '@mui/material';
import { connect } from 'react-redux';

import './Filters.css';
import KeywordSearch from '../../../search/components/KeywordSearch';
import FilterTree from './FilterTree';
import FilterSelect from './FilterSelect';
import {
  setKeywords,
  setSelectedFilters,
} from '../../models/otmmModel/functions/updateState';

const Filters = ({ filters }) => {
  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid container spacing={1} tour="filters-sidemenu">
      {isBig && (
        <a href="#endOfFilters" className="skip-filter">
          Skip Filters and Keywords
        </a>
      )}

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="body1" gutterBottom>
                <b>Refine your search...</b>
              </Typography>
            }
          />
          <CardContent>
            <KeywordSearch />
          </CardContent>
        </Card>
      </Grid>
      <Slide direction="right" in={filters.length > 0}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography variant="body1" gutterBottom>
                  <b>Refine using Filters...</b>
                </Typography>
              }
            />
            <CardContent>
              <FilterSelect />
              <CardContent>
                <FilterTree />
              </CardContent>
            </CardContent>
          </Card>
        </Grid>
      </Slide>

      <div id="endOfFilters" />
    </Grid>
  );
};

Filters.propTypes = {
  filters: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    otmmState: state.otmmState,
    isSearching: state.otmmState.loading,
    filters: state.otmmState.filters.allFilters,
    selectedFilters: state.otmmState.filters.selected,
    keywords: state.otmmState.otmmParams.keywords,
  });

export default connect(
  mapStateToProps,
  { setSelectedFilters, setKeywords }
)(Filters);
