import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { SITE_NAME, SITE_NAME_ABBR } from '../../../constants/constants';

const LandingPageTourSteps = () => {
  const theme = useTheme();

  const GuestSteps = [
    {
      content: (
        <>
          <Typography variant="h6">
            {SITE_NAME} ({SITE_NAME_ABBR})
          </Typography>
          <Typography>
            Please complete this quick tour to help you get started.
          </Typography>
        </>
      ),

      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Help</Typography>
          <Typography>
            If you find yourself stuck, click the Help button to find out what
            to do on the current page.
          </Typography>
          <br />
          <Typography display="inline">
            <b>Tip:</b> Some pages include a{' '}
          </Typography>
          <Typography variant="button" display="inline">
            <b>Show me how</b>{' '}
          </Typography>
          <Typography display="inline">
            button that will display interactive help.
          </Typography>
        </>
      ),
      selector: '[tour="stuck"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Search</Typography>
          <Typography>
            This is the main search bar that can be used from any page to start
            a new search.
          </Typography>
        </>
      ),
      selector: '[tour="search-bar"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Guided Search</Typography>
          <Typography>
            The guided search is a predefined search tool to help you find
            resources.
          </Typography>
        </>
      ),
      selector: '[tour="nav-guided-search"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">{SITE_NAME_ABBR} News</Typography>
          <Typography>{SITE_NAME_ABBR} News is located here.</Typography>
        </>
      ),
      selector: '[tour="nav-crws-news"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">{SITE_NAME_ABBR} Tips</Typography>
          <Typography>Tips on how to use {SITE_NAME_ABBR} are located here.</Typography>
        </>
      ),
      selector: '[tour="nav-crws-tips"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Help</Typography>
          <Typography>
            Send an email to the curator team, find answers to frequently asked
            questions, or find out more about {SITE_NAME_ABBR}.
          </Typography>
        </>
      ),
      selector: '[tour="nav-help-footer"]',
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">End of Tour</Typography>
          <Typography>
            The Tour has ended. Please press the &quot;X&quot; in the upper
            right-hand corner to close this dialog.
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
    },
  ];

  return GuestSteps;
};

export default LandingPageTourSteps;
