import {
  FACET_DATE_INTERVAL_RESPONSE,
  FACET_DATE_INTERVAL_RESTRICTION,
  FACET_NUMERIC_RANGE_RESPONSE,
  FACET_NUMERIC_RANGE_RESTRICTION,
  FACET_SIMPLE_RESPONSE,
} from '../constants/facetTypes';

const getFacetResponseType = (filter) => {
  if (filter.type === FACET_DATE_INTERVAL_RESTRICTION) {
    return FACET_DATE_INTERVAL_RESPONSE;
  }
  if (filter.type === FACET_NUMERIC_RANGE_RESTRICTION) {
    return FACET_NUMERIC_RANGE_RESPONSE;
  }

  return FACET_SIMPLE_RESPONSE;
};

export default getFacetResponseType;
