import { otmm } from '../../otmm/apis/otmm';
import { METADATA_TO_RETURN } from '../constants/assetFields';
import transformAssets from './transformAssets';

const getAsset = async (id) => {
  try {
    let response = await otmm.get(`assets/${id}`, {
      params: {
        load_type: 'metadata',
        level_of_detail: 'slim',
        metadata_to_return: METADATA_TO_RETURN.toString(),
      },
    });
    response = await transformAssets([response.data.asset_resource.asset]);
    return response[0];
  } catch (e) {
    return null;
  }
};

export default getAsset;
