/* eslint-disable import/prefer-default-export */
export const SAIC_DISCLOSURE =
  'SAIC PROPRIETARY INFORMATION. The information contained in these documents is proprietary to SAIC. It may not be used by, reproduced for, or disclosed to third parties, including subcontractors and learning partners, without signed non-disclosure and teaming agreements. U.S. export laws and regulations and SAIC policy require the prior written approval of Corporate Export Administration before access to certain PIE information may be provided to any non-U.S. person.';

export const OTMM_DATE_FORMAT = 'YYYY-MM-DDT00:00:00';
export const OTMM_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const NO_VALUE_DEFINED_IN_OTMM = '(blank)';

export const XML_FILE_TYPE = 'text/xml';
export const PDF_FILE_TYPE = 'application/pdf';

/* DEFAULT NAMES FOR OTMM MODEL */
export const DEFAULT_RELEVANCE = 'DEFAULT.RELEVANCE';
export const DEFAULT_ORDER = 'desc_';

export const FILTERS = 'filters';
export const PAGINATION = 'pagination';
export const OTMM_PARAMS = 'otmmParams';
export const SORT = 'sort';
export const ASSETS = 'assets';
export const LOADING = 'loading';
export const PENDING_UPDATE = 'pendingUpdate';
export const CURRENT_VERSION = 'prototype';
export const SITE_NAME_ABBR = process.env.REACT_APP_SITE_NAME_ABBR;
export const SITE_NAME = process.env.REACT_APP_SITE_NAME;
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

// access Roles
export const REVIEWER = 'Reviewer';
export const USER = 'User';

// usergroups
export const EVERYONE = 'Everyone';
export const REVIEWERS = 'Reviewers';

export const USERS = 'Users';
export const TESTERS = 'Testers';

// Advanced searches
export const NOMINATIONS_UNDER_REVIEW = 'Nominations (Currently Under Review)';
export const RESOURCES = 'Resources';
export const GLOSSARY = 'Glossary';
export const FAVORITES = 'Favorites';
export const REVIEW_BOARD = 'Review Board';

export const TITLE = 'CRWS.TAB.REFERENCES.TITLE';
