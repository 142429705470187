const createLegend = (data, graphSettings) => {
  // Get the unique IDS from the nodes
  const { nodes } = data;
  const types = [...new Set(nodes.map((node) => node.type))];
  const origin = nodes.find((node) => node.symbolType === 'star');
  let legend = {
    Origin: {
      color: 'green',
      checked: true,
      disabled: true,
      count: 1,
      children: {
        [origin.name]: { ...origin, checked: true, disabled: true, count: 1 },
      },
    },
  };
  types.forEach((type) => {
    [...nodes].forEach((node) => {
      if (node.type === type) {
        const { name } = node;
        if (legend[type]) {
          legend = {
            ...legend,
            [type]: {
              ...legend[type],
              children: {
                ...legend[type].children,
                [name]: { ...node, checked: true },
              },
              color: graphSettings.nodes[type],
              checked: true,
            },
          };
        } else
          legend = {
            ...legend,
            [type]: {
              children: {
                [name]: { ...node, checked: true },
              },

              color: graphSettings.nodes[type],
              checked: true,
            },
          };
      }
    });
  });
  return legend;
};

export const updateLegend = (previousLegend, data) => {
  const updatedLegend = { ...previousLegend };
  let isLastChild = false;
  const { id, type } = data;
  if (!type) {
    const parent = updatedLegend[id];
    const { children } = parent;
    parent.checked = !parent.checked;

    Object.keys(children).forEach((child) => {
      children[child].checked = parent.checked;
    });
  } else {
    const parent = updatedLegend[type];
    const { children } = parent;
    const child = children[id];
    // If the parent is disabled, we need to re-check the parent as well.
    child.checked = !child.checked;
    if (child.checked && !parent.checked) parent.checked = child.checked;

    // Check if this was the last checkbox to be checked...
    isLastChild =
      Object.keys(children).filter((c) => children[c].checked === true)
        .length === 0;
    if (isLastChild) parent.checked = child.checked;
  }
  return updatedLegend;
};
export default createLegend;
