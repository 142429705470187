import _ from 'lodash';

import {
  SAVED_SEARCH_OTMM,
  SEARCH_ASSET,
  SEARCH_OTMM,
} from '../../../constants/searches';

import {
  setLoading,
  setPendingUpdate,
  setOtmmError,
} from '../models/otmmModel/actions/otmmActions';
import {
  setAllFilters,
  setAssets,
  setKeywords,
  setOtmmName,
  setSavedSearchId,
  setSearchTerm,
  setSelectedFilters,
  setTotalResults,
  setActivePage,
} from '../models/otmmModel/functions/updateState';
import createFilters from './createFilters';
import fetchOtmmLightbox from './fetchOtmmLightbox';
import fetchOtmmSubscriptions from './fetchOtmmSubscriptions';
import getAsset from './getAsset';
import getFacetMetaProperty from './getFacetMetaProperty';
import getFacetResponseType from './getFacetResponseType';
import getFacetText from './getFacetText';
import getSavedSearch from './getSavedSearch';
import runSavedSearch from './runSavedSearch';
import searchOtmm from './searchOtmm';
import { separateTermAndKeys } from './sharedFunctions';

export const fetchSavedSearchRedux = (savedSearchId) => async (
  dispatch,
  getState
) => {
  const state = getState().otmmState;
  dispatch(setAssets([]));
  dispatch(setActivePage(1));
  dispatch(setSavedSearchId(savedSearchId));
  dispatch(setLoading(true));
  const { pagination, sort } = state;
  const { pageLimit } = pagination;

  const { id: sortId, order: sortOrd } = sort;

  const savedSearch = await getSavedSearch(savedSearchId);

  if (savedSearch.error) {
    dispatch(setOtmmName('Save Search Not Found'));
    dispatch(setLoading(false));
    dispatch(setTotalResults(0));
    dispatch(setSearchTerm(''));
    dispatch(setKeywords([]));
    dispatch(setAllFilters([]));
  } else {
    dispatch(setOtmmName(savedSearch.name));
    let term = '';
    if (savedSearch.fulltext_condition_list.length > 0) {
      term = _.get(savedSearch.fulltext_condition_list[0], 'keyword', '');
    }
    if (savedSearchId !== undefined) {
      const {
        assets,
        selectedFacets,
        facets,
        totalResults: newTotalResults,
      } = await runSavedSearch(savedSearchId, pageLimit, sortId, sortOrd);

      const transformedSelectedFacets = selectedFacets.map((selectedFacet) => ({
        type: getFacetResponseType(selectedFacet),
        filter_id: selectedFacet.field_id,
        value: selectedFacet.value_list.map((v) => ({
            text: getFacetText(v, selectedFacet.field_id),
            meta: getFacetMetaProperty(selectedFacet.type, v),
          })),
        include: true,
      }));
      const result = separateTermAndKeys(term);
      const filters = await createFilters(facets);

      dispatch(setAssets(assets));
      dispatch(setAllFilters(filters));
      dispatch(setSelectedFilters(transformedSelectedFacets));
      dispatch(setTotalResults(newTotalResults));
      dispatch(setSearchTerm(result.searchTerm));
      dispatch(setKeywords(result.keywords));
    }
    dispatch(setLoading(false));
  }
};

export const fetchSubscriptions = () => async (dispatch, getState) => {
  const state = getState();
  // Store original search term so it doesn't get overwritten by * during this search.
  const searchTerm = state.otmmState.otmmParams.term;
  dispatch(setSearchTerm('*'));
  dispatch(setLoading(true));
  dispatch(setAssets([]));
  const { assets = [], hasError } = await fetchOtmmSubscriptions();

  if (hasError)
    dispatch(setOtmmError({ error: hasError, message: hasError.message }));
  dispatch(setAssets(assets));
  dispatch(setAllFilters([]));
  dispatch(setSelectedFilters([]));
  dispatch(setKeywords([]));
  dispatch(setTotalResults(assets.length));
  dispatch(setLoading(false));
  dispatch(setPendingUpdate(false));
  dispatch(setSearchTerm(searchTerm));
  dispatch(setOtmmName('Subscriptions'));
};

export const fetchFavoritesId = async () => {
  const { assets } = await fetchOtmmLightbox();
  const Ids = assets.map((asset) => asset.assetId);
  return Ids;
};

export const fetchLightbox = () => async (dispatch, getState) => {
  const state = getState();

  const searchTerm = state.otmmState.otmmParams.term;

  dispatch(setSearchTerm('*'));
  dispatch(setLoading(true));
  dispatch(setAssets([]));
  const { assets = [], hasError } = await fetchOtmmLightbox();

  if (hasError)
    dispatch(setOtmmError({ error: hasError, message: hasError.message }));
  dispatch(setAssets(assets));
  dispatch(setAllFilters([]));
  dispatch(setSelectedFilters([]));
  dispatch(setKeywords([]));
  dispatch(setTotalResults(assets.length));
  dispatch(setPendingUpdate(false));
  dispatch(setOtmmName('Favorites'));
  dispatch(setSearchTerm(searchTerm));
  dispatch(setLoading(false));
};

export const fetchWithRedux = (advancedSearchName) => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  dispatch(setAssets([]));
  //
  const searchName = advancedSearchName || 'Resources';
  // dispatch(setOtmmName(searchName));
  const { otmmState } = getState();
  const { filters, pagination, otmmParams, sort } = otmmState;
  const { type, term, keywords, savedSearchId } = otmmParams;
  const { activePage, pageLimit } = pagination;
  const { selected: selectedFilters } = filters;
  const { id: sortId, order: sortOrd } = sort;
  const validSearch = [SEARCH_ASSET, SEARCH_OTMM, SAVED_SEARCH_OTMM].includes(
    type
  );

  if (!validSearch) {
    throw new Error('Invalid search.');
  }
  switch (type) {
    case SEARCH_ASSET:
      dispatch(setAssets([await getAsset(term)]));
      dispatch(setSearchTerm('*'));
      dispatch(setAllFilters([]));
      dispatch(setTotalResults(1));
      dispatch(setLoading(false));
      dispatch(setPendingUpdate(false));
      break;
    case SAVED_SEARCH_OTMM:
      dispatch(fetchSavedSearchRedux(savedSearchId));
      break;
    default: {
      const {
        assets: newAssets,
        facets,
        totalResults: newTotalResults,
      } = await searchOtmm(
        term,
        searchName,
        selectedFilters,
        activePage,
        pageLimit,
        sortId,
        sortOrd,
        keywords
      );
      dispatch(setAllFilters(await createFilters(facets)));
      dispatch(setAssets(newAssets));
      dispatch(setTotalResults(newTotalResults));

      dispatch(setLoading(false));
      dispatch(setPendingUpdate(false));
    }
  }
};
