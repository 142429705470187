import { useState } from 'react';
import { CRWS_MODEL_ID } from '../constants/assetFields';

import editAsset from '../functions/editAsset';

const useUserRating = (pTotal, pCount) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [Total, setTotal] = useState(pTotal.value || 0);
  const [Count, setCount] = useState(pCount.value || 0);

  function getAverage() {
    return Count > 0 ? parseInt(Total, 10) / parseInt(Count, 10) : Total;
  }

  const [Average, setAverage] = useState(getAverage());

  const toggleUserRating = async (asset, value) => {
    const sum = parseInt(Total, 10) + parseInt(value, 10);
    const quantity = parseInt(Count, 10) + 1;
    const average = Count > 0 ? sum / quantity : value;

    const newValues = {
      'CRWS.FIELD.USER_RATING': {
        type: 'com.artesia.metadata.MetadataTableField',
        value: sum,
        dataType: 'CHAR',
        error: false,
      },
      'CRWS.FIELD.TOTAL_USER_RATINGS': {
        type: 'com.artesia.metadata.MetadataTableField',
        value: quantity,
        dataType: 'CHAR',
        error: false,
      },
    };

    editAsset({
      assetId: asset.assetId,
      modelId: CRWS_MODEL_ID,
      values: newValues,
    });

    setAverage(average);
    setTotal(sum);
    setCount(quantity);
    setIsDisabled(true);
  };

  return {
    toggleUserRating,
    Average,
    isDisabled,
    Total,
    Count,
  };
};

export default useUserRating;
