export const UPDATE_OTMM = 'UPDATE_OTMM';
export const SET_LOADING = 'SET_LOADING';
export const SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES';
export const SET_GUIDED_SEARCH_OPTIONS = 'SET_GUIDED_SEARCH_OPTIONS';
export const SET_PENDING_UPDATE = 'SET_PENDING_UPDATE';

export const UPDATE_SYSTEM_SETTINGS = 'Update-Otmm-Settings';
export const ADD_DATA_FOR_GRAPH = 'ADD_DATA_FOR_GRAPH';

export const SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW';
export const OTMM_ERROR = 'error';
export const SET_ERROR = 'SET_OTMM_ERROR';
