import { IconButton, Slide, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pop from '../pop/Pop';

const DrawerContainer = ({ children, menuItems, name, current }) => {
  const currentStep = current.state ? current.state.current : undefined;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(!isMobile);

  useEffect(() => {
    let mounted = true;
    if (mounted && isMobile) {
      setOpen(false);
    } else setOpen(true);
    return () => {
      mounted = false;
    };
  }, [isMobile]);

  useEffect(() => {
    let mounted = true;
    if (mounted && name === 'Search Tour') {
      setOpen(true);
    }
    return () => {
      mounted = false;
    };
  }, [name, currentStep]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div>
        <Pop content={open ? 'Hide' : 'Show'}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <ArrowBackIos fontSize="small" />
            ) : (
              <ArrowForwardIos fontSize="small" />
            )}
          </IconButton>
        </Pop>
      </div>
      <div>
        <div style={open ? { marginRight: 15 } : null}>
          <Slide unmountOnExit direction="right" in={open}>
            <div style={{ width: 300, maxWidth: 300 }}>{menuItems}</div>
          </Slide>
        </div>
      </div>

      {/* Drawer should push children when opened unless in mobile mode */}
      <div style={{ width: '100%' }}>{children}</div>
    </div>
  );
};

DrawerContainer.propTypes = {
  children: PropTypes.any.isRequired,
  menuItems: PropTypes.object.isRequired,
  name: PropTypes.string,
  current: PropTypes.object,
};

DrawerContainer.defaultProps = {
  name: undefined,
  current: undefined,
};

const mapStateToProps = (state) => ({
  name: state.tour.props.name,
  current: state.tour.props.ref.current,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);
