export const TYPE_UPDATE_TOUR_STEPS = 'UPDATE_TOUR_STEPS';
export const TYPE_OPEN_TOUR = 'UPDATE_OPEN_TOUR';
export const TYPE_SET_TOUR_PROPS = 'SET_PROPS';
export const updateTourSteps = (payload) => ({
  type: TYPE_UPDATE_TOUR_STEPS,
  payload,
});

export const updateTourProps = (payload) => ({
  type: TYPE_SET_TOUR_PROPS,
  payload,
});

export const updateTourOpen = (payload) => ({
  type: TYPE_OPEN_TOUR,
  payload,
});

export const setTourSteps = (payload) => async (dispatch) => {
  dispatch(updateTourSteps(payload));
};

export const setTourOpen = (payload) => async (dispatch) => {
  dispatch(updateTourOpen(payload));
};

export const setTourProps = (payload) => async (dispatch) => {
  dispatch(updateTourProps(payload));
};
