export const TYPE_UPDATE_DIALOG = 'UPDATE_DIALOG';

export const updateDialog = (payload) => ({
  type: TYPE_UPDATE_DIALOG,
  payload,
});

export const setDialog = (payload) => async (dispatch) => {
  dispatch(updateDialog(payload));
};
