import { DeleteForever } from '@mui/icons-material';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinkButton } from '../../../../../components/StyledComponents';
import { SAVED_SEARCH } from '../../../../../constants/paths';
import Loading from '../../../../../Loading';
import deleteSavedSearch from '../../../functions/deleteSavedSearch';
import getNotificationFrequencies from '../../../functions/getNotificationFrequencies';
import getSavedSearches from '../../../functions/getSavedSearches';
import transformSaveSearchToRows from '../../../functions/transformSaveSearchToRows';
import { setOtmmError } from '../../../models/otmmModel/actions/otmmActions';
import CustomDialog from '../../CustomDialog/CustomDialog';
import NotificationFrequencySelector from '../../notificationFrequencySelector/NotificationFrequencySelector';
import Pop from '../../pop/Pop';

const SaveSearchTable = ({ setOtmmError }) => {
  const [savedSearchesPrivate, setSavedSearchesPrivate] = useState(undefined);
  const [options, setOptions] = useState(undefined);

  const [isLoading, setLoading] = useState({
    saveSearch: true,
  });

  const history = useHistory();

  const fetchPrivateSavedSearches = useCallback(async () => {
    const rows = await getSavedSearches('private');
    if (rows.error) {
      setOtmmError(rows);
      return;
    }

    setSavedSearchesPrivate(transformSaveSearchToRows(rows));
    setLoading({ ...isLoading, saveSearch: false });
  }, [isLoading, setOtmmError]);

  useState(async () => {
    const rows = await getSavedSearches('private');
    if (rows.error) {
      setOtmmError(rows);
      return;
    }

    setSavedSearchesPrivate(transformSaveSearchToRows(rows));
    setOptions(await getNotificationFrequencies());
    setLoading({ ...isLoading, saveSearch: false });
  });

  const theme = useTheme();
  const isMed = useMediaQuery(theme.breakpoints.down('xl'));

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: isMed ? 1 : 3,
      renderCell: (params) => (
        <Typography>
          <Pop content="Run search">
            <LinkButton
              onClick={() => {
                history.push(SAVED_SEARCH.replace(':id', params.row.id));
              }}
            >
              {params.row.name}
            </LinkButton>
          </Pop>
        </Typography>
      ),
    },
    { field: 'description', headerName: 'Description', flex: isMed ? 1 : 3 },
    {
      field: 'notifications',
      headerName: 'Notifications',
      flex: 1,
      renderCell: (params) => (
        <>
          <NotificationFrequencySelector
            color="inherit"
            frequency={params.row.frequency}
            options={options}
            id={params.row.id}
          />
        </>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 1,
      renderCell: (params) => (
        <>
          <CustomDialog
            variant="text"
            size="small"
            button
            confirmation
            label="Delete"
            color="inherit"
            startIcon={<DeleteForever />}
            title="Delete Confirmation"
            handler={() => {
              deleteSavedSearch(params.row.id)
                .then(() => {
                  fetchPrivateSavedSearches();
                })
                .catch(() => {
                  fetchPrivateSavedSearches();
                });
            }}
            aria-label="delete"
          >
            <span>
              Are you sure you want to permanently delete{' '}
              <b>{params.row.name}</b>?
            </span>
          </CustomDialog>
        </>
      ),
    },
  ];

  return isLoading.saveSearch ? (
    <Loading color="primary">Loading saved searches...</Loading>
  ) : (
    <div style={{ height: '500px' }}>
      <DataGrid
        disableSelectionOnClick
        // autoPageSize
        columns={columns}
        rows={savedSearchesPrivate}
      />
    </div>
  );
};
SaveSearchTable.propTypes = {
  setOtmmError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { otmmState: state.otmmState };
}

export default connect(mapStateToProps, { setOtmmError })(SaveSearchTable);
