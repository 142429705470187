import _ from 'lodash';
import authStorage from './authStorage';
import { X_REQUESTED_BY } from '../constants/headers';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["headers"] }] */

const axiosRequestTransformer = (data, headers) => {
  const authResponse = authStorage.get();
  if (authResponse) {
    const { token, ticket, session_resource: sessionResource } = authResponse;
    headers.Authorization = `basic ${token}`;
    headers.OTDSTicket = ticket;
    headers[X_REQUESTED_BY] = _.get(sessionResource, 'session.id');
  }
  return data;
};

export default axiosRequestTransformer;
