import _ from 'lodash';
import { NO_VALUE_DEFINED_IN_OTMM } from '../constants/constants';
import isCurrencyFilter from './isCurrencyFilter';
import formatCurrency from './formatCurrency';

const getFacetText = (facetValue, fieldId) => {
  if (facetValue) {
    if (facetValue.interval_label) {
      return facetValue.interval_label;
    }

    if (!_.isNil(facetValue.end_value) || !_.isNil(facetValue.start_value)) {
      if (isCurrencyFilter(fieldId)) {
        if (_.isNil(facetValue.end_value)) {
          return `>= ${formatCurrency(facetValue.start_value)}`;
        }

        if (_.isNil(facetValue.start_value)) {
          return `<= ${formatCurrency(facetValue.end_value)}`;
        }

        return `${formatCurrency(facetValue.start_value)} - ${formatCurrency(
          facetValue.end_value
        )}`;
      }

      return `${facetValue.start_value} - ${facetValue.end_value}`;
    }

    return facetValue;
  }

  return NO_VALUE_DEFINED_IN_OTMM;
};
export default getFacetText;
