import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  useTheme,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';

import styles from '../RegistrationPage.module.css';

import {
  PasswordField,
  EmailField,
  UserField,
} from '../../CustomFields/CustomFields';

import PrivacyAgreement from '../PrivacyAgreement';
import { LinkButton } from '../../../../../components/StyledComponents';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { registrationApi } from '../../../../otmm/apis/otmm';
import { SITE_NAME_ABBR } from '../../../constants/constants';

const RegistrationForm = ({
  incrementStep,
  onComplete,
  setUserCallback,
  setvCodeMessage,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState({ value: '', valid: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', valid: false});
  const [email, setEmail] = useState({ value: '', valid: false });
  const [username, setUsername] = useState({ value: '', valid: false });
  const [isUsernameTaken, setUsernameTaken] = useState(false);

  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const [message, setMessage] = useState({
    severity: '',
    message: '',
    open: false,
  });
  const [open, setOpen] = useState(false);

  const [dialog, setDialog] = useState({
    title: 'Loading...',
    message: '...',
    handler: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (username.value.length >= 6){
      setUserCallback(username.value);
    }
  }, [username, setUserCallback])

  const handleOnClick = async (e) => {
    e.preventDefault();

    let registrationSuccessful = false;

    await registrationApi
    .post(
      `/`,
      JSON.stringify({
        first: firstName,
        last: lastName,
        username: username.value.toLowerCase(),
        email: email.value,
        password: password.value,
      })
    )
    .then((res) => {
      setvCodeMessage(res.data); // Callback to RegistrationPageStepsContent
      registrationSuccessful = true;
      setDialog({
        title: 'Complete your registration',
        message: (
          <>
            <Alert severity="warning" style={{ marginBottom: 15 }}>
              `We have emailed a verification code to {email.value}.
            </Alert>{' '}
            <Typography>{res.data}</Typography>
          </>
        ),
        handler: () => {
          setOpen(false);
          if (onComplete) onComplete();
        },
      });
      setOpen(true);
      setMessage({
        severity: 'success',
        open: false,
      });
    })
    .catch((err) => {
      setMessage({
        severity: 'error',
        open: true,
        message: err.response.data
          ? `${err.response.data}`
          : `${err.message} - no response from the server. If problem persists contact administrator.`,
      });
    });

    if(registrationSuccessful){
      incrementStep(); // Callback function (to RegistrationPage.js)
    }
  };

  function validateForm() {
    return (
      !email.valid ||
      !password.valid ||
      !username.valid ||
      !confirmPassword.valid ||
      isUsernameTaken
    );
  }
  const isSuccess = message.open && message.severity === 'success';


  return (
    <div className={styles.RegistrationPage}>
      <div className={styles.container}>
        <>
          <form onSubmit={!validateForm() ? handleOnClick : null}>
            <TextField
              id="fName"
              label="First Name"
              placeholder="First Name"
              variant="outlined"
              fullWidth
              value={firstName}
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              id="lName"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              aria-label="Last Name"
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onChange={(e) => setLastName(e.target.value)}
            />

            <UserField
              required
              id="username"
              label="Username"
              requiredLength={5}
              state={username}
              setState={setUsername}
              isUsernameTaken={isUsernameTaken}
              onBlur={async () => {
                if(username.value.length >= 6){
                  const usernameToLower = username.value.toLowerCase();

                  // We can call /users/${usernameToLower} endpoint to see if a username has been taken or not
                  await registrationApi
                  .get(
                    `/users/${usernameToLower}`,
                    JSON.stringify({
                      username: usernameToLower
                    })
                  )
                  .then(() => {
                    setUsernameTaken(false);
                  })
                  .catch(() => {
                    // Don't need to set the err as the message since we will be displaying that the username is taken underneath the user field.
                    setUsernameTaken(true);
                  });
                }}
              }
              style={{ marginTop: '10px', marginBottom: '10px' }}
            />

            <EmailField
              required
              id="email"
              label="Email"
              aria-describedby="Email field"
              state={email}
              setState={setEmail}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            />

            <PasswordField
              id="Password"
              label="Password"
              aria-label="Password"
              variant="outlined"
              type="password"
              fullWidth
              required
              state={password}
              setState={setPassword}
            />

            <PasswordField
              id="ConfirmPassword"
              label="Confirm Password"
              aria-label="Confirm Password"
              aria-describedby="Confirming Password"
              variant="outlined"
              fullWidth
              required
              errorText="Does not match password."
              confirmationField={password.value}
              state={confirmPassword}
              setState={setConfirmPassword}
            />

            <Typography display="inline">
              By registering to {SITE_NAME_ABBR} you are agreeing to this{' '}
            </Typography>
            <LinkButton color={isDark ? 'secondary' : 'primary'}>
                <CustomDialog label="Privacy Agreement" title="Privacy Agreement">
                  <PrivacyAgreement/>
              </CustomDialog>
            </LinkButton>
            {/* <Typography display="inline">.</Typography> */}
            {message.open && (
              <Alert
                onClose={() => setMessage({ ...message, open: false })}
                severity={message.severity}
              >
                {message.message}
              </Alert>
            )}
            {!isSuccess && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                style={{ marginTop: '10px', marginBottom: '10px' }}
                onClick={handleOnClick}
                type="submit"
                disabled={validateForm()}
              >
                Register
              </Button>
            )}
          </form>
          <Dialog open={open} fullWidth maxWidth="xs">
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>{dialog.message}</DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={dialog.handler}
                sx={{ color: 'text.secondary' }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </div>
    </div>
  );
};

RegistrationForm.propTypes = {
  incrementStep: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  setUserCallback: PropTypes.func.isRequired,
  setvCodeMessage: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  onComplete: undefined,
};

export default RegistrationForm
