import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { SEARCHVAR } from '../../../../../../constants/paths';

import { fetchWithRedux } from '../../../../functions/fetchAssets';
import {
  setActivePage,
  setKeywords,
  setOtmmName,
  setSearchTerm,
  setSelectedFilters,
} from '../../../../models/otmmModel/functions/updateState';
import Assets from '../../../assets/Assets';
import { setPendingUpdate } from '../../../../models/otmmModel/actions/otmmActions';

const RunGuidedSearch = ({
  guidedSearch,
  setOtmmName,
  setSelectedFilters,
  setKeywords,
  setSearchTerm,
  pendingUpdate,
  fetchWithRedux,
  setActivePage,
  setPendingUpdate,
}) => {
  const { matches } = guidedSearch;
  const routeGuidedSearch = useRouteMatch({
    path: SEARCHVAR,
    exact: true,
  });

  const { searchText } = routeGuidedSearch.params;

  const search = useCallback(
    (keywords, filters, title) => {
      setActivePage(1);
      setSearchTerm('*');
      setKeywords([...keywords]);
      setSelectedFilters([...filters]);
      setOtmmName(title);
      setPendingUpdate(true);
    },
    [
      setSearchTerm,
      setKeywords,
      setSelectedFilters,
      setOtmmName,
      setActivePage,
      setPendingUpdate,
    ]
  );
  const clearSearch = useCallback(() => {
    setKeywords([]);
    setSelectedFilters([]);
    setOtmmName('No results found');
  }, [setKeywords, setSelectedFilters, setOtmmName]);

  const searchGlossary = useCallback(
    (term) => {
      const match = matches.find((match) => match.title.toLowerCase() === term);

      if (!match) {
        clearSearch();
        return;
      }
      const { keywords, title, transformedFilters } = match;
      search(keywords, transformedFilters, title);
    },
    [matches, search, clearSearch]
  );

  const searchGuided = useCallback(
    (areaToProtect, technicalProcess) => {
      const match = matches.find(
        (match) =>
          match.areaToProtect.toLowerCase() === areaToProtect &&
          match.technicalProcess.toLowerCase() === technicalProcess
      );

      if (!match) {
        clearSearch();
        return;
      }
      const { keywords, title, transformedFilters } = match;
      search(keywords, transformedFilters, title);
    },
    [matches, search, clearSearch]
  );
  useEffect(() => {
    let mounted = true;
    if (matches && mounted) {
      const searchTerms = searchText.split('+');
      if (searchTerms.length === 1) searchGlossary(searchTerms[0]);
      if (searchTerms.length === 2)
        searchGuided(searchTerms[0], searchTerms[1]);
    }
    return () => {
      mounted = false;
    };
  }, [matches, searchGuided, searchText, searchGlossary]);

  useEffect(() => {
    let mounted = true;
    if (mounted && pendingUpdate) {
      fetchWithRedux();
    }
    return () => {
      mounted = false;
    };
  }, [pendingUpdate, fetchWithRedux]);

  return <Assets />;
};

RunGuidedSearch.defaultProps = {};
RunGuidedSearch.propTypes = {
  guidedSearch: PropTypes.object.isRequired,
  setOtmmName: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setKeywords: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  fetchWithRedux: PropTypes.func.isRequired,
  pendingUpdate: PropTypes.bool.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  guidedSearch: state.otmmSettings.guidedSearch,
  pendingUpdate: state.otmmState.pendingUpdate,
});
const mapDispatchToProps = {
  setSelectedFilters,
  setActivePage,
  setOtmmName,
  setKeywords,
  fetchWithRedux,
  setSearchTerm,
  setPendingUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunGuidedSearch);
