import React from 'react';
import PropTypes from 'prop-types';
// import pluralize from 'pluralize';
import './ResultsCount.css';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';

const ResultsCount = ({ totalResults, pageLimit, activePage }) => {
  const lowerEnd = (activePage - 1) * pageLimit + 1;
  const upperEnd = Math.min(activePage * pageLimit, totalResults);
  return (
    <Typography display="inline" variant="body2">
      {`Displaying results: ${lowerEnd}-${upperEnd} of ${totalResults}`}
    </Typography>
  );
};

const mapStateToProps = (state) => ({
    totalResults: state.otmmState.pagination.totalResults,
    activePage: state.otmmState.pagination.activePage,
    pageLimit: state.otmmState.pagination.pageLimit,
  });

ResultsCount.propTypes = {
  totalResults: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ResultsCount);
