const isAuthResponseValid = (authResponse) => {
  const now = new Date().getTime();
  if (authResponse) {
    if (authResponse.expiresAt > now) {
      return true;
    }
  }

  return false;
};

export default isAuthResponseValid;
