import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import { connect } from 'react-redux';
import {
  setActivePage,
  setSearchType,
} from '../../../models/otmmModel/functions/updateState';
import { setPendingUpdate } from '../../../models/otmmModel/actions/otmmActions';
import { SEARCH_OTMM } from '../../../../../constants/searches';

const Paginator = ({
  activePage,
  totalResults,
  pageLimit,
  size,
  setActivePage,
  setSearchType,
  setPendingUpdate,
}) => {
  // const [inputPage, setInputPage] = useState(activePage);
  const totalPages = Math.ceil(totalResults / pageLimit);

  // const parsePageInput = useCallback(
  //   (value) => Math.min(Math.max(parseInt(value, 10), 1), totalPages),
  //   [totalPages]
  // );

  const onInputChange = (e, data) => {
    // setInputPage(parsePageInput(data));
    setActivePage(data);
    setSearchType(SEARCH_OTMM);

    // Force rerender of assets.
    setPendingUpdate(true);
  };

  return (
    <>
      <Pagination
        siblingCount={0}
        size={size}
        count={totalPages}
        page={activePage}
        shape="rounded"
        onChange={onInputChange}
        showFirstButton={totalPages > 10}
        showLastButton={totalPages > 10}
      />
    </>
  );
};

Paginator.propTypes = {
  size: PropTypes.string,
  totalResults: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
};
Paginator.defaultProps = {
  size: 'medium',
};

const mapStateToProps = (state) => ({
    totalResults: state.otmmState.pagination.totalResults,
    keywords: state.otmmState.otmmParams.keywords,
    selectedFilters: state.otmmState.filters.selected,
    searchTerm: state.otmmState.otmmParams.term,
    activePage: state.otmmState.pagination.activePage,
    currentPage: state.otmmState.pagination.currentPage,
    pageLimit: state.otmmState.pagination.pageLimit,
    searchName: state.otmmState.otmmParams.name,
  });

export default connect(
  mapStateToProps,
  {
    setActivePage,
    setSearchType,
    setPendingUpdate,
  }
)(Paginator);
